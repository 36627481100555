/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Nav, Navbar,
    Container, Image,
} from 'react-bootstrap'
import '../home/css/eye.css'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import { navigate } from "@reach/router"


/**
 */
class Hdr extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Navbar sticky="top" expand="lg" className="navbarHdr">
                <Navbar.Brand href='/'>
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        <Image className="navIcon" src={require('../home/images/i24Web.png')}/>
                        {/* <div style={{fontWeight:650, color: '#fff', fontSize: 30}}>HiStreet</div> */}
                    </div>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-dark"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav>
                            {/*<Nav.Link href='about' style={{color:this.props.about ? "#0c4aac": "#333",fontWeight:"600", marginRight: 50}}>ABOUT</Nav.Link>*/}
                            <Nav.Link href='team' style={{color:this.props.team ? "#347CF6": "#fff",fontWeight:"600", marginRight: 50}}>Team</Nav.Link>
                            <Nav.Link href='download' style={{color:this.props.download ? "#347CF6": "#fff",fontWeight:"600", marginRight: 50}}>Download</Nav.Link>
                            {/*<Nav.Link href='#' onSelect={() => navigate('search/category')}>Search</Nav.Link>*/}
                            <Nav.Link href='contact' style={{color:this.props.contact ? "#347CF6": "#fff",fontWeight:"600", marginRight: 50}}>Contact</Nav.Link>
                            {/*<Nav.Link href='blog' style={{color:this.props.blog ? "#0c4aac": "#333",fontWeight:"600", marginRight: 50}}>BLOG</Nav.Link>*/}
                        </Nav>
                    </Nav>

                    {/*<Nav>*/}
                    {/*    <Nav.Link href='profile'>Business</Nav.Link>*/}
                    {/*</Nav>*/}
                </Navbar.Collapse>
            </Navbar>
            )
    }
}   // class Hdr

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Hdr )


