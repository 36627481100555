/**
 *  @copyright   Elmelo Ltd
 */
export const Rdx_Checkout_SetSubtotal = ( sub_total ) =>
{
    return {
            type: "checkout:set-subtotal",
            payload: sub_total,
        }
}
/**
 */
export const Rdx_Checkout_Set_Payment = ( payment_obj ) =>
{
    return {
        type: "checkout:set-payment",
        payload: payment_obj,
    }
}
/**
 */
export const Rdx_Checkout_Set_User_Info = ( usr_obj ) =>
{
    return {
        type: "checkout:set-user-info",
        payload: usr_obj,
    }
}
/**
 */
export const Rdx_Apply_Charge = (ch_obj) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            let charges = gs().__checkout.charges;

            if( charges[ch_obj.type] )
                charges[ch_obj.type] = { ...charges[ch_obj.type], ...ch_obj };
            else
                charges[ch_obj.type] = ch_obj;

            dispatch( Rdx_Checkout_Set_Charges( charges ) )

        }
        catch( err )
        {
            console.warn( 'actions/checkout: Rdx_Apply_Charge: err: ', err )

            return { err }
        }
    }   // ...
}   // Rdx_Apply_Charge
/**
 */
export const Rdx_Apply_Disc = (disc_obj) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            let discounts = gs().__checkout.discounts;

            if( false === disc_obj.b_accept_others )
            {
                discounts.generic = [];
                discounts.combo = [];
                discounts.influencer = [];
                discounts.loyalty = [];
                discounts.coupon = [];
            }

            if(disc_obj.type === 'loyalty')
                discounts[disc_obj.type] = disc_obj ;
            else
                discounts[disc_obj.type].push( {...disc_obj, _idx: discounts[disc_obj.type].length, disc_type: disc_obj.type} );

            dispatch( Rdx_Checkout_Set_Discounts( discounts ) )


        }
        catch( err )
        {
            console.warn( 'actions/checkout: Rdx_Apply_Disc: err: ', err )

            return { err }
        }
    }   // ...
}   // Rdx_Apply_Disc
/**
 */
export const ListCharges = () =>
{
    return ( dispatch, gs ) =>
    {
        try
        {
            let ch_arr = Object.keys( gs().__checkout.charges ).map( k => gs().__checkout.charges[k] );

            return ch_arr.sort( (a, b) => a._idx - b._idx );
        }
        catch( err )
        {
            console.warn( 'actions/checkout: ListCharges: err: ', err )

            return { err }
        }
    }   // ...
}   // ListCharges
/**
 */
export const ListDiscounts = () =>
{
    return ( dispatch, gs ) =>
    {
        try
        {
            const {generic, combo, influencer, loyalty, coupon} = gs().__checkout.discounts;

            return [...generic, ...combo, ...influencer, ...loyalty, ...coupon];
        }
        catch( err )
        {
            console.warn( 'actions/checkout: ListDiscounts: err: ', err )

            return { err }
        }
    }   // ...
}   // ListDiscounts
/**
 */
export const Rdx_Get_Total = () =>
{
    return ( dispatch ) =>
    {
        try
        {
            const total = dispatch(SubTotal()) - dispatch(Discount());

            return total;

        }
        catch( err )
        {
            console.warn( 'actions/checkout: Rdx_Get_Total: err: ', err )

            return { err }
        }
    }
}
/**
 */
const SubTotal = () =>
{
    return ( dispatch) =>
    {
        try
        {
            return dispatch(ListCharges()).reduce( (acc, cur) => {
                return acc + cur.amount;
            }, 0 );

        }
        catch( err )
        {
            console.warn( 'actions/checkout: SubTotal: err: ', err )

            return { err }
        }
    }

}
/**
 */
const Discount = () =>
{
    return ( dispatch ) =>
    {
        try
        {
            return dispatch(ListDiscounts()).reduce( (acc, cur) => {
                return acc + cur.amount;
            }, 0 );

        }
        catch( err )
        {
            console.warn( 'actions/checkout: Discount: err: ', err )

            return { err }
        }
    }
}
/**
 */

/**
 */
const ClearCharges = () =>
{
    return ( dispatch , gs ) =>
    {
        try
        {
            let charges = gs().__checkout.charges

            Object.keys(charges).forEach( k => {

                if(charges[k].type !=='subtotal' )
                    charges[k].amount = 0.0
            });

            charges.elmelo.amount = 0.5;

            dispatch(Rdx_Checkout_Set_Charges(charges))
        }
        catch( err )
        {
            console.warn( 'actions/checkout: ClearCharges: err: ', err )

            return { err }
        }
    }
}

/**
 */
const ClearDiscounts = () =>
{
    return ( dispatch , gs ) =>
    {
        try
        {
            let discounts = gs().__checkout.discounts
                discounts.generic = [];
                discounts.combo = [];
                discounts.influencer = [];
                discounts.loyalty = [];
                discounts.coupon = [];

            dispatch(Rdx_Checkout_Set_Discounts(discounts))


        }
        catch( err )
        {
            console.warn( 'actions/checkout: ClearDiscounts: err: ', err )

            return { err }
        }
    }

}

/**
 */
export const Rdx_Checkout_Clear = () =>
{
    return ( dispatch , gs ) =>
    {
        try
        {
            let{delivery ,payment} = gs().__checkout.delivery

            delivery = {
                deliveryType: '',
                deliveryAddress: ''
            };

            payment = {
                paymentType: '',
                creditInfo: '',
            };

            dispatch(ClearCharges());
            dispatch(ClearDiscounts());
            dispatch(Rdx_Checkout_Payment(payment));
            dispatch(Rdx_Checkout_info({
                title: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            }));
        }
        catch( err )
        {
            console.warn( 'actions/checkout: ClearCharges: err: ', err )

            return { err }
        }
    }

}

/**
 */
const Rdx_Checkout_Set_Charges = ( charges ) =>
{
    return {
        type: "checkout:set-charges",
        payload: charges,
    }
}
/**
 */
const Rdx_Checkout_Set_Discounts = ( discounts ) =>
{
    return {
        type: "checkout:set-discounts",
        payload: discounts,
    }
}

/**
 */
export const Rdx_Checkout_Payment = payload => {
    return {
        type: "checkout:set-payment",
        payload: payload
    }
};

/**
 */
export const Rdx_Checkout_info = payload => {
    return {
        type: "checkout:update-user-info",
        payload: payload
    }
};

export const Rdx_Checkout_Set_Histreet_Charge = ( val ) =>
{
    return {
        type: "checkout:set-histreet-charge",
        payload: val,
    }
}
