/**
 * @copyright Elmelo Ltd.
 */
import React from 'react'
import {
    Container,
    Button,
    Form, Alert, Modal,
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import NavHdr from '../_common/nav_hdr'
// import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlusSquare,
    faTrashAlt,
    faCheck,
    faCircle,
    faAlignJustify,
    faTimes,
    faTruckMoving,
    faShoppingBag,
    faMoneyBill,
    faArrowDown,
    faCreditCard,
    faPlus,
    faMinus,
    faTicketAlt, faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import './css/checkout.css'
import {TextInput} from '../_common/component'
import {Core} from "../../api/AWS";
import {confirmAlert} from "react-confirm-alert"

import {navigate} from "@reach/router";
import ContentLoader from "react-content-loader";
import {PickerEx} from "../_common/picker";

// import elml_cfg from '../../_config/elml_cfg'
const titleList = [
    {label: "Mr", val: "Mr", key: "Mr"},
    {label: "Ms", val: "Ms", key: "Ms"},
    {label: "Mrs", val: "Mrs", key: "Mrs"}
]
/**
 */
class Checkout_Personal extends React.PureComponent
{
    constructor(props)
    {
        super(props)

        this.state = {

            err_msg: '',
            title: null,
            first: null,
            last: null,
            email: null,
            bInit: true,
            bSave: false,

        }

    }

    /**
     */
    componentDidMount()
    {
        this.loaduserInfo()
            .then()
            .catch()
    }

    loaduserInfo = async ()=>
    {

        try {

            this.setState({bInit: true})

            const aws_core = new Core( {} )

            const user = await aws_core.Name()

            if(user)
            {

                this.setState({title: user.title,first: user.first, last: user.last})
                this.props.Rdx_Checkout_info({title: user.title,firstName: user.first, lastName: user.last})
            }

            const email = await aws_core.Email()

            // console.log("email", email)

            if(email)
            {
                this.setState({email: email.addr})
                this.props.Rdx_Checkout_info({email: email.addr})
            }

            const user_phone = await aws_core.Phone()

            if(user_phone)
                this.props.Rdx_Checkout_info({phone: user_phone.number})

            this.setState({bInit: false})


        }
        catch (err)
        {

        }
    }


    render()
    {
        console.log(" personal props", this.props)

        const {info} = this.props.__checkout

        return(
            <div style={{marginTop:48}}>

                <div className="orderProcessBody">
                    {
                        this.state.err_msg ?
                            <Alert variant="danger">
                                <div>
                                    {this.state.err_msg}
                                </div>
                                <div>
                                    <Button onClick={() => this.setState({err_msg: ''}) } variant="success">Ok</Button>
                                </div>
                            </Alert>
                            : null

                    }
                    {
                        this.state.bInit ?

                            <div className="overlayContainer">
                                <ContentLoader
                                    height={160}
                                    width={200}
                                    speed={1}
                                    primaryColor="#f3f3f3"
                                    secondaryColor="#ecebeb"
                                >
                                    <rect x="0" y="13" rx="4" ry="4" width="400" height="9"/>
                                    <rect x="0" y="29" rx="4" ry="4" width="100" height="8"/>
                                    <rect x="0" y="50" rx="4" ry="4" width="400" height="10"/>
                                    <rect x="0" y="65" rx="4" ry="4" width="400" height="10"/>
                                    <rect x="0" y="79" rx="4" ry="4" width="100" height="10"/>
                                    <rect x="0" y="99" rx="5" ry="5" width="400" height="200"/>
                                </ContentLoader>
                            </div>
                            :
                            <div>
                                <div className="processTabSelect">
                                    <FontAwesomeIcon style={{marginTop:5}} icon={faShoppingBag} size="lg" color="#C94B4B"/>
                                    <div className="processText" style={{color: "#C94B4B"}}>Customer Details</div>
                                </div>
                                <Form.Text className="infoSubTitle">
                                    Your Name
                                </Form.Text>
                                <div>
                                    {
                                        info.title?
                                            <Form.Group
                                                disabled
                                                className="processInputR">
                                                <TextInput
                                                    placeholder="title"
                                                    type="text"
                                                    val={this.props.__checkout.info.title}
                                                    onChange={(val) => this.props.Rdx_Checkout_info({title: val.target.value})}
                                                    className=""
                                                    disabled={true}
                                                />
                                            </Form.Group>
                                            :
                                            <PickerEx
                                                items={titleList}
                                                prompt={"Title"}
                                                val={this.state.title}
                                                bSearch={true}
                                                onChange={(val)=> this.setState({title: val})  }
                                                pickerTitle={true}
                                            />
                                    }

                                    <Form.Group className="processInputR">
                                        <TextInput
                                            placeholder="First Name"
                                            type="text"
                                            val={this.state.first}
                                            onChange={(val)=> this.setState({first: val.target.value}) }
                                            className=""
                                            disabled={info.firstName ? true : false}
                                        />
                                    </Form.Group>
                                    <Form.Group className="processInputR">
                                        <TextInput
                                            placeholder="Last Name"
                                            type="text"
                                            val={this.state.last}
                                            onChange={(val)=> this.setState({last: val.target.value})  }
                                            className=""
                                            disabled={info.lastName ? true : false}

                                        />
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Text className="infoSubTitle">
                                        Email Address
                                    </Form.Text>
                                    <div style={{flexDirection: "row", display: "flex"}}>
                                        <Form.Group className="processInputU">
                                            <TextInput
                                                placeholder="Email Address"
                                                type="email"
                                                val={this.state.email}
                                                onChange={(val)=> this.setState({email: val.target.value})  }
                                                className=""
                                                disabled={info.email ? true : false}

                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="processButtons">
                                    {
                                        this.state.bSave
                                            ?
                                            <Button className="disableButton">
                                                Saving...
                                            </Button>
                                            :
                                            <Button
                                                className="confirmButton"
                                                onClick={this.goToNext} >
                                                {
                                                    !this.state.email ||
                                                    !this.state.first ||
                                                    !this.state.last
                                                        ?
                                                        "Save"
                                                        :
                                                        "Next"
                                                }
                                            </Button>
                                    }

                                </div>
                            </div>
                    }


                </div>
            </div>
        )
    }

    goToNext = async ()=>
    {
        try
        {

            const {info} = this.props.__checkout

            console.log("name_obj", info)
            console.log("email_obj", info.email)


            if(info.email && info.firstName && info.lastName)
            {
                this.props.showCustomer(false)
                return
            }


            if(!this.state.email && ( !this.state.title || !this.state.first || !this.state.last ) )
            {
                this.setState({err_msg:  "Please enter email address and name"  });
                return
            }
            if(!this.state.email)
            {
                this.setState({err_msg:  "Please enter email address"  });
                return
            }
            if(!this.state.title || !this.state.first || !this.state.last)
            {
                // this.props.hideModal()
                this.setState({err_msg:  "Please enter name"  });
                return
            }

            this.setState({bSave: true})

            const aws_core = new Core( {} )


            const new_name = { title: this.state.title, first: this.state.first, last: this.state.last }

            if(!info.title || !info.firstName || !info.lastName)
            {
                const resp_n = await aws_core.SetName( new_name )
                if (resp_n && resp_n.err)
                {
                    this.setState({err_msg: resp_n.err.message, bSave: false})
                    return
                }
                this.props.Rdx_Checkout_info({ title: this.state.title, firstName: this.state.first, lastName: this.state.last })

            }

            if(!info.email)
            {
                const resp = await aws_core.SetEmail(this.state.email)

                if (resp && resp.err)
                {
                    this.setState({err_msg: resp.err.message, bSave: false})
                    return
                }
                this.props.Rdx_Checkout_info({email: this.state.email})


            }

            this.setState({bSave: false})

            this.props.showCustomer(false)

        }
        catch (err)
        {

            console.log("personal: gonext: err:", err)
        }
    }

    ShowAlert = (title) =>
    {

        confirmAlert({
            title: title,
            message: 'From Profile',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {}
                }
            ]
        });

    };
}   // class Checkout_Personal

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Checkout_Personal )


