/**
 *  @copyright   Elmelo Ltd
 */

export const LOCAL_MENU_MAX = 100
export const LOCAL_CART_MAX = 100

/**
 */
export const BizId = ( state ) =>
{
    return state.__biz.biz_id
}

/**
 */
export const Key = ( key, state ) =>
{
    // const biz_id = gs().__core.bizInfo.biz_id ? gs().__core.bizInfo.biz_id : gs().__core.bizInfo.biz_id
    const biz_id = BizId( state )

    return [biz_id, key].join(':')
}   // Key


