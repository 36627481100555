/**
 *	@copyright	Elmelo Ltd
 */

import {combineReducers} from 'redux'

import Cfg from './Cfg'
import Core from './Core'
import Biz from './Biz'
import Menu from './Menu'
import Cart from './Cart'
import Checkout from './Checkout'
import Order from './Order'

export default combineReducers( {
        __cfg: Cfg,
        __core: Core,
        __biz: Biz,
        __menu: Menu,
        __cart: Cart,
        __checkout: Checkout,
        __order: Order,
    } )


