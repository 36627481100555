/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

// import {connect} from 'react-redux'
// import * as actions from '../../rdx/actions'
// import Hdr from  '../_common/hdr'

import {
    // InputGroup,Form,Row,
    Col,
    Image,
    //Container,
    Modal
} from  'react-bootstrap'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlusSquare,faCheck,
    // faTimes,faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'

// import DatePicker from "react-datepicker";

import color from '../_common/colors.json'

// import './css/order.css'
// import '../_common/css/common.css'
import "react-datepicker/dist/react-datepicker.css"

/**
 */
class MenuPage_Box extends React.PureComponent
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        const items = this.props.title.split(':')[1];
        const main = this.props.title.split(':')[0];
        // // console.log("aaaaa",a);
        // // console.log("bbbbb",b);

        return (
            <div
                className={this.props.className}
                style={this.props.style}
                key={this.props.key}
                onClick={this.props.onPress}
                id={this.props.id}
            >
                <>
                {
                    this.props.id
                ?   <div>
                        <div className="categorySmall">{main}</div>
                        <div className="categoryLarge">{items}</div>
                    </div>

                :   <div className="categoryModal">{items}</div>
                }
                </>

                <div>{this.props.item}</div>
            </div>
        )
    }
}   // MenuPage_Box

/**
 */
class Item_Box extends React.PureComponent
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        return (
            <div className="singleItem"
                 style={{borderColor: color.colors.borderLight,backgroundColor:this.props.count >0 ? "#ecdfdf" : "inherit"}}
                 onClick={this.props.onPress}
            >
                <div className="common">
                    <div style={{flex: 1}}>
                        <div className="common">
                            <div className="itemName">{this.props.itemName}</div>
                            {
                                this.props.count
                                    ? <div className="orderCount">
                                        {this.props.count}
                                    </div>

                                    : null
                            }
                        </div>
                        <div className="itemDescription">
                            {this.props.description}
                        </div>
                    </div>

                    <>
                        {
                            this.props.modItem
                                ? <div>
                                    {this.props.modItem}
                                </div>

                                : null
                        }
                    </>


                    <>
                        {
                            this.props.itemPrice
                                ? <div className="itemPrice">£{this.props.itemPrice}
                                </div>

                                : null
                        }
                    </>

                    <div className="addtoCart"
                        // onClick={this.props.onPress}
                    >
                        <FontAwesomeIcon icon={faPlusSquare} size="lg"
                                         color={"#C94B4B"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}   // Item_Box

/**
 */
class Item_div extends React.PureComponent
{
    constructor(props)
    {
        super (props)
        this.state={
                showMod:false,
            }
    }

    showMod=(val)=>{
        this.setState({showMod:val});
        console.log(this.state.showMod);
    }
    render(){
        console.log(this.state.showMod);

        return(
            <Col xl="4" lg="6" md="6" sm="6" xs="12">
                <div className="itemSection">
                    <div className="itemBox">
                        <div className="commonFlex">
                            <div className="itemName">
                                {this.props.itemName}
                            </div>
                            {
                                this.props.mod ?
                                    <div onClick={()=>this.showMod(true)} className="addBtn">
                                        <div className="addText">^</div>
                                    </div> :
                                    <div className="itemPrice">
                                        {this.props.itemPrice}
                                    </div>
                            }

                        </div>
                        {this.props.desc ?
                            <div className="itemDescription">
                                {this.props.desc}
                            </div> :
                            null
                        }
                        <div className="commonFlex">
                            <div>
                                <div className="tags">
                                    <div className="tagsText">{this.props.tags}</div>
                                </div>
                                <div className="allergies">
                                    <div className="allergyText">{this.props.allergies}</div>
                                </div>
                            </div>
                            {this.props.addBtn ?
                                <div className="addBtn">
                                    <div className="addText">ADD</div>
                                </div> :

                                <div className="commonFlex" style={{marginLeft:"auto"}}>
                                    <div className="addBtn">
                                        <div className="addText">+</div>
                                    </div>
                                    <div className="addNumber">
                                        <div className="itemName">
                                            {this.props.count}
                                        </div>
                                    </div>
                                    <div className="addBtn">
                                        <div className="addText">-</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.showMod ?
                        <Modal
                            size="lg"
                            show={true}
                            onHide={() => this.showMod(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Large Modal
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Radio_items/>
                                <Checkbox_items/>
                            </Modal.Body>
                        </Modal>:
                        null
                }
            </Col>
        )
    }
}   // Item_div

/**
 */
class Radio_items extends React.PureComponent{
    constructor(props){
        super (props)
        this.state={
            showMod:false,
        }
    }
    render(){
        return(
            <Item_div
                itemName="Mutton Curry"
                itemPrice=" $ 12.59"
            />

        )
    }
}

/**
 */
class Checkbox_items extends React.PureComponent{
    constructor(props){
        super (props)
        this.state={
            showMod:false,
        }
    }
    render(){
        return(
            <Item_div
                itemName="Mutton Curry"
                itemPrice=" $ 12.59"
            />
        )
    }
}

/**
 */
export{Item_div, Radio_items, Checkbox_items, MenuPage_Box, Item_Box,}
