/**
 *	@copyright	Elmelo Ltd.
 */

import elml_cfg from '../../_config/elml_cfg'

/**
 */
export const RdxCfg_Load = () =>
{
    return {
            type: 'cfg:load'
        ,   payload: elml_cfg
        }
}   // RdxCfg_Load


