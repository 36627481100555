/**
 *	@copyright	Elmelo Ltd
 */

///
const aws_cfg = {
    'aws_cognito_region': 'eu-west-1',
    'aws_cognito_identity_pool_id': 'eu-west-1:60b793f2-2c64-40b2-b4c8-1c5d8d67f068',
    'aws_user_pools_id': 'eu-west-1_J9SM4S3oo',
    'aws_user_pools_web_client_id': '6cvc2td9ejou8f2693lv4b6huc',
}

///
export default aws_cfg


