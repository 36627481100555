/**
 *	@copyright	Elmelo Ltd.
 */

// import elml_cfg from '../../_config/elml_cfg'

import {DDB,} from '../../api/AWS'

import {AStorage, Utils} from '../../api/Utils'

/**
 */
export const Rdx_Biz_Init = (biz_id) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            // // console.log( 'reducers/biz: Rdx_Biz_Init: gs(): ', gs() )

            if( 'inprogress' === gs().__biz.initStatus )
                return {msg: 'OK'}

            dispatch( Rdx_Biz_InitStatus('inprogress') )

            const biz_stored = await AStorage.Get( 'biz' )


            if( biz_stored )
            {
                dispatch( Rdx_Biz_InitStatus('stale') )
                dispatch( Biz_Set(biz_stored) )

            }

            const biz_retr = await RetrBiz( dispatch, gs ,biz_id)

            console.log( 'reducers/biz: RetrBiz: resp_get: ', biz_retr )

            dispatch( Biz_Set({...biz_retr}) )

            await AStorage.Set( 'biz', biz_retr );

            dispatch( Rdx_Biz_InitStatus('stale') )

        }
        catch( err )
        {
            dispatch( Rdx_Biz_InitStatus('stale') )

            console.error( 'reducers/biz: Rdx_Biz_Init: err: ', err )

            return {err}
        }
    }   // return ...
}   // Rdx_Biz_Init

/**
 */
const Rdx_Biz_InitStatus = ( init_status ) =>
{
    return {
        type: 'biz:init-status'
        ,   payload: init_status
    }
}
/**
 */
const Biz_Set = ( biz_obj ) =>
{
    return {
        type: 'set:biz',
        payload: biz_obj
    }
}

/**
 */
const RetrBiz = async ( dispatch, gs ,biz_id) =>
{
    try
    {
        const aws_ddb = new DDB( {} )

        const p_get = {
            TableName: gs().__cfg.db( 'biz' ),
            Key: { biz_id: biz_id},
        }

        // // console.log( 'reducers/biz: RetrBiz: p_get: ', p_get )

        const resp_get = await aws_ddb.Get( p_get )

        // console.log( 'reducers/biz: RetrBiz: resp_get: ', resp_get )

        return resp_get.Item
    }
    catch( err )
    {
        console.error( 'reducers/biz: RetrBiz: err: ', err )

        return Promise.reject( err )
    }
}   // RetrBiz

