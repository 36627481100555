/**
 * @copyright   Elmelo Ltd.
 */

/**
 */
class Currency
{
    static Sign( code )
    {
        // // console.log( 'Currency: Sign: code: ', code )

        if( !code )
            return '£'

        switch( code.toLowerCase() )
        {
            case 'gbp': return '£'
            case 'bdt': return '৳'
            case 'usd':
            case 'aud':
            case 'cad':
                return '$'
            default: return '£'
        }   // switch
    }   //
}   // class Currency

/**
 */
export {Currency}




