/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
export const RdxCore_AuthStatus = ( auth_status ) =>
{
    return {
            type: 'core:auth_status'
        ,   payload: auth_status
        }
}   // RdxCore_AuthStatus

/**
 */
export const RdxCore_bNetwork = ( b_network ) =>
{
    return {
            type: 'core:b_network'
        ,   payload: b_network
        }
}   // RdxCore_bNetwork

/**
 */
export const RdxCore_bInternet = ( b_internet ) =>
{
    return {
            type: 'core:b_internet'
        ,   payload: b_internet
        }
}   // RdxCore_bInternet

