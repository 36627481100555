/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
export default ( state = {}, action ) =>
{
    switch( action.type )
    {
    case 'cfg:load':
        return {...state, ...action.payload}

    default:
        return state
    }   // switch ...
}




