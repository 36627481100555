/**
 *  @copyright   Elmelo Ltd
 */

import {AStorage} from '../../api/Utils'

import ONT from '../../components/order/ont'

// import * as Utils from './_utils'
// import {Checkout_SetSubtotal, Checkout_SetItemCount,} from './checkout'

import * as Biz from './biz'
import * as Menu from './menu'
import * as Cart from './cart'
import * as Checkout from './checkout'

/**
 */
export const RdxOrder_AddToCart = ( item, sect, cnt_change ) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            dispatch( Cart.RdxCart_Update( item, sect, cnt_change ) )

            //
            const total_obj = ONT.GetTotal(gs().__cart.sects, gs().__order.type)

            //
            dispatch( Cart.RdxCart_Total( total_obj.item_cnt, total_obj.total ) )

            dispatch( Checkout.Rdx_Checkout_SetSubtotal( total_obj.total ) )


            // dispatch( checkout.Rdx_Checkout_SetItemCount( total_obj.item_cnt ) )

            // //
            // await AStorage.Set( 'cart', gs().__order.cart_sects )

            //
            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'actions/order: Rdx_Core_CfgReq: err: ', err )

            return {err}
        }
    }   // ...
}   // RdxOrder_AddToCart

/**
 */
export const RdxOrder_Init = ( biz_id ) =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            // console.log( 'actions/_utils: RdxOrder_Init: gs(): ', gs() )

            if('inprogress' === gs().__order.initStatus
                &&  biz_id === gs().__biz.bizId
                )
            {
                return {msg: 'OK'}
            }

            await dispatch( Biz.Rdx_Biz_Init( biz_id ) )
            dispatch( Order_InitStatus('inprogress') )

            await dispatch( Menu.RdxMenu_Init() )
            await dispatch( Cart.RdxCart_Init() )

            dispatch( Order_InitStatus('stale') )

            //
            return { msg: 'OK' }
        }
        catch( err )
        {
            console.warn( 'actions/order: RdxOrder_Init: err: ', err )

            dispatch( Order_InitStatus('stale') )

            return { err }
        }
    }
}   // RdxOrder_Init

/**
 */
function Order_InitStatus( init_status )
{
    return {
            type: 'order:init-status',
            payload: init_status,
        }
}

/**
 */
export const Rdx_Order_SetType = ( val ) =>
{
    return {
            type: 'order:set-type',
            payload: val,
        }
}

/**
 */
export const Rdx_Instore_Table = ( val ) =>
{
    return {
            type: 'order:set-table',
            payload: val,
        }
}

/**
 */
// function RdxOrder_BizId( biz_id )
// {
//     return {
//             type: 'order:biz_id'
//         ,   payload: biz_id
//         }
// }



