/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Container,
    Button,
    Form,
    Image, Alert
} from 'react-bootstrap'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ContentLoader from 'react-content-loader'

import {
    faPlusSquare,
    faTrashAlt,
    faCheck,
    faCircle,
    faAlignJustify,
    faTimes,
    faTruckMoving,
    faShoppingBag,
    faMoneyBill,
    faArrowDown,
    faCreditCard,
    faPlus,
    faMinus,
    faTicketAlt,
    faDotCircle, faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import {Lambda,Utils} from "../../api";
// import elml_cfg from '../../_config/elml_cfg'

import color from '../_common/colors.json'
import './css/checkout.css'
import AddNewCardModal from '../profile/addNewCard';
import {Profile} from "../profile/profile";

/**
 */
class Checkout_Payment extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)

        this.state = {
            bAddNewCard: false,
            cardList: [],
            loading: false,
            errMsg: "",
        }
    }

    /**
     */
    componentDidMount ()
    {
        // this.LoadCardList()
        //     .then( data => {} )
        //     .catch( err => {} )
    }

    /**
     */
    LoadCardList = async () =>
    {
        // // console.log('Checkout_Payment: LoadCardList: here ...');

        try
        {
            const cfg = this.props.__cfg

            this.setState({bloading: true})

            const profile = new Profile( {} )

            const card_list = await profile.GetCardList(cfg)

            this.setState( {loading: false, cardList: card_list , bloading: false} )

            return {}
        }
        catch( err )
        {
            console.warn( 'cardList: err: ', err );
            this.setState( {bloading: false, loading: false} )
            return Promise.reject( err );
        }
    }   // LoadCardList

    /**
     */
    showInvoice = () =>
    {
        if(!this.props.__checkout.payment.paymentType)
        {
            this.setState({errMsg: "Please select payment type"})
            return
        }

        if(this.props.__checkout.payment.paymentType === "card" && !this.props.__checkout.payment.creditInfo )
        {
            this.setState({errMsg: "Please select a card and press next"})
            return
        }

        this.props.showPayment(false)
    }

    /**
     */
    render()
    {
        // // console.log( "Checkout_Payment : render : props : ", this.props );

        const PaymentTypediv = ['Cash', 'Card',]

        const {__checkout} = this.props

        return(
            <div>
                <div className="orderProcessBody">
                    {/*<div className="processTabComp">*/}
                    {/*    <FontAwesomeIcon style={{marginTop:5}} icon={faMoneyBill} size="lg" color="green"/>*/}
                    {/*    <div className="processSelect">Payment Details</div>*/}
                    {/*</div>*/}
                    <div>
                        {
                            this.state.errMsg
                                ?   <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                    {this.state.errMsg}
                                </Alert>
                                :   null
                        }
                    </div>

                    <div>
                        {
                            this.state.bAddNewCard ?
                                <AddNewCardModal {...this.props}
                                                 hideModal={()=>this.setState( { bAddNewCard: false } )}
                                                 LoadCardList = {this.LoadCardList}
                                />

                                :   null
                        }
                    </div>

                    <div className="processTabSelect">
                        <FontAwesomeIcon style={{marginTop:5}} icon={faMoneyBill} size="lg" color="#C94B4B"/>
                        <div className="processText" style={{color: "#C94B4B"}}>Payment Details</div>
                    </div>
                    <div style={{flexDirection: 'row',paddingTop:20,display:"flex"}}>
                        {
                            this.props.__biz.biz_settings.payment.cash
                                ?
                                <div style={{flex:1}} key={"cash"} >
                                    <div
                                        onClick={() => this.props.Rdx_Checkout_Payment({paymentType: "cash" }) }
                                    >
                                        <div
                                            className="selectionContainer"
                                            style={{background: __checkout.payment.paymentType === "cash" ? 'linear-gradient(to right, #C94B4B, #466099)' : '#ffffff'}}>
                                            <div style={{marginBottom: 5}}>
                                                <FontAwesomeIcon icon={faMoneyBill} size="2x" color={__checkout.payment.paymentType === "cash" ? "#fff" :color.colors.light}/>
                                            </div>
                                            <div>
                                                <div style={{color: __checkout.payment.paymentType === "cash" ? '#ffffff' : "#C94B4B"}}>Pay Later</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            /*this.props.__biz.biz_settings.payment.card
                                ?
                                <div style={{flex:1}} key={"card"} >
                                    <div onClick={() => this.props.Rdx_Checkout_Payment({paymentType: "card" }) } >
                                        <div
                                            className="selectionContainer"
                                            style={{background: __checkout.payment.paymentType === "card" ? 'linear-gradient(to right, #C94B4B, #466099)' : '#ffffff'}}>
                                            <div style={{marginBottom: 5}}>
                                                <FontAwesomeIcon icon={faCreditCard} size="2x" color={__checkout.payment.paymentType === "card" ? "#fff" :color.colors.light}/>
                                            </div>
                                            <div>
                                                <div style={{color: __checkout.payment.paymentType === "card" ? '#ffffff' : "#C94B4B"}}>Card</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null*/
                        }
                        {
                            // PaymentTypediv.map((x, arraykey) => {
                            //     return (
                            //         <div style={{flex:1}} key={x} >
                            //             <div onClick={() => this.props.Rdx_Checkout_Payment({paymentType: x.toLowerCase() }) } >
                            //                 <div
                            //                     className="selectionContainer"
                            //                     style={{backgroundColor: __checkout.payment.paymentType === x.toLowerCase() ? color.colors.primary : '#ffffff'}}>
                            //                     <div style={{marginBottom: 5}}>
                            //                     {   'Card' === x
                            //                     ?   <FontAwesomeIcon icon={faCreditCard} size="2x" color={__checkout.payment.paymentType === x.toLowerCase()? "#fff" :color.colors.light}/>

                            //                     :   <FontAwesomeIcon icon={faMoneyBill} size="2x" color={__checkout.payment.paymentType === x.toLowerCase()? "#fff" :color.colors.light}/>
                            //                     }
                            //                     </div>
                            //                     <div>
                            //                         <div className="infoSubTitle" style={{color: __checkout.payment.paymentType === x.toLowerCase() ? '#ffffff' : color.colors.primary}}>{x}</div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // })
                        }
                    </div>
                    {
                        __checkout.payment.paymentType === "card"
                            ?   <div>
                                <div className="processDetailsDiv">
                                    <div className="processDetailText">
                                        Select Card
                                    </div>
                                    <div className="processDetailsButton"
                                         onClick={ () => this.setState( { bAddNewCard: true } ) }
                                    >
                                        Add New Card
                                    </div>
                                </div>
                                <div>
                                    { this.state.cardList && this.state.cardList.length > 0 ?
                                        null :
                                        <Alert variant='danger'>
                                            <Alert.Link href="#"> No Saved Card Found . Add a new Card </Alert.Link>
                                        </Alert>
                                    }
                                </div>
                                {
                                    this.state.bloading
                                        ?   <div style={{
                                            padding: 32,
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            height: 300,
                                            width: 600
                                        }}>
                                            <ContentLoader
                                                height={80}
                                                width={200}
                                                speed={1}
                                                primaryColor="#f3f3f3"
                                                secondaryColor="#ecebeb"
                                            >
                                                <rect x="0" y="13" rx="4" ry="4" width="400" height="9"/>
                                                <rect x="0" y="29" rx="4" ry="4" width="100" height="8"/>
                                                <rect x="0" y="50" rx="4" ry="4" width="400" height="10"/>
                                                <rect x="0" y="65" rx="4" ry="4" width="400" height="10"/>
                                                <rect x="0" y="79" rx="4" ry="4" width="100" height="10"/>
                                                <rect x="0" y="99" rx="5" ry="5" width="400" height="200"/>
                                            </ContentLoader>
                                        </div>

                                        :   null
                                }
                                {
                                    this.state.cardList.map((x, idx) =>
                                    {
                                        const cr_info = __checkout.payment.creditInfo

                                        return (
                                            <div key={idx} >
                                                <div onClick={() => this.props.Rdx_Checkout_Payment({creditInfo: x }) }>
                                                    {
                                                        <div className="pickerItem" style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                            {
                                                                cr_info && cr_info.card
                                                                &&  cr_info.card.last4 === x.card.last4
                                                                &&  cr_info.card.exp_month === x.card.exp_month
                                                                &&  cr_info.card.exp_year === x.card.exp_year
                                                                &&  cr_info.card.brand === x.card.brand
                                                                    ?   <FontAwesomeIcon style={{marginRight: 8}} size="lg" icon={faCheck} color="green" />

                                                                    :   <FontAwesomeIcon style={{marginRight: 8}} size="lg" icon={faCircle} color={"#C4C4C4"} />
                                                            }
                                                            {/* <Image
                                                        src="https://storage.histreet-app.com/flags/uk-01.png"
                                                        height="30" width="30"
                                                    />*/}

                                                            <div style={{color: color.colors.light,marginLeft:8}}>
                                                                {x.card.brand} - Ends with {x.card.last4}
                                                            </div>
                                                            <div style={{marginLeft: "auto",}}>
                                                                {x.card.exp_month}/{x.card.exp_year}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </div>

                            :   null
                    }
                    <div className="processButtons">
                        <Button
                            className="confirmButton"
                            onClick={()=>this.showInvoice()}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        )
    }   // render
}   // class Checkout_Payment

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Checkout_Payment )


