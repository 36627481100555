/**
 *    @copyright    Elmelo Ltd
 */
import {Currency} from '../../api'
import ONT from '../order/ont'
import {ItemPrice} from '../order/ItemPrice';

/**
 */
async function Invoice_Save( props )
{
    try
    {

        const {__cart, __order , __checkout} = props;
        const cfg = props.__cfg;

        // Calculate VAT
        const _vat = (__cart.totalPrice * 0.1667).toFixed(2);

        let invoice = {

            invoice_data: __cart.sects,
            order_amount_info: {
                Total: parseFloat(props.Rdx_Get_Total()).toFixed(2),//this.getTotal(),
                SubTotal: parseFloat(__cart.totalPrice).toFixed(2),
                vat: _vat,
                charge: props.ListCharges().filter(x => ('subtotal' != x.type && x.amount)),
                discount: props.ListDiscounts().filter(x => x.amount),
            },

            delivery_option: __order.type,
            user_info: {

                username: { title: __checkout.info.title , first: __checkout.info.firstName , last: __checkout.info.lastName },
                mobile: __checkout.info.phone,
                email: __checkout.info.email
            },
            // pub_id:  await Social_Core.PubId( cfg ),
            stage:   cfg.stage,
        }

        if (invoice.delivery_option.toLowerCase() === "delivery")
        {
            invoice.user_info.Address = __checkout.delivery.deliveryAddress
        }

        invoice.save_status = true

        return invoice
    }
    catch( err )
    {
        console.warn( 'checkout_invoice/Invoice_Save: err: ', err )
        return Promise.reject( err )
    }
}   // Invoice_Save

/**
 */
async function Invoice_Process( order_obj, props )
{
    try
    {

        const transid = order_obj.order_id.split('_')[3]
        const pr_bi = props.__core.bizInfo
        const c_sign = Currency.Sign( pr_bi.biz_currency )

        const invoice_data = order_obj.items

        console.log("invoice_data",invoice_data)

        let date = new Date();

        let month = date.getMonth();
        let day = date.getDate();
        let year = date.getFullYear();
        let dayname = date.getDay();

        let monthNames = [ "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December" ];

        let week=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';

        // console.log("process_invoice", invoice);

        let html =
            '<body class="clean-body" style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #FFFFFF;">'+
            '<table bgcolor="#FFFFFF" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"'+
            '       style="table-layout: fixed; vertical-align: top; min-width: 320px; Margin: 0 auto; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF; width: 100%;"'+
            '       valign="top" width="100%">'+
            '    <tbody>'+
            '    <tr style="vertical-align: top;" valign="top">'+
            '        <td style="word-break: break-word; vertical-align: top;" valign="top">'+
            '            <div>'+
            '                <div class="block-grid no-stack"'+
            '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
            '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
            '                        <div class="col num12"'+
            '                             style="min-width: 320px; max-width: 700px; display: table-cell; vertical-align: top; width: 700px;">'+
            '                            <div style="width:100% !important;">'+
            '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:60px; padding-bottom:0px; padding-right: 0px; padding-left: 0px;">'+
            '                                    <div align="center" class="img-container center fixedwidth"'+
            '                                         style="padding-right: 0px;padding-left: 0px;"><img align="center" alt="Image" border="0" class="center fixedwidth"'+
            '                                                         src="https://storage.histreet-app.com/email/logohs.svg"'+
            '                                                         style="text-decoration: none; -ms-interpolation-mode: bicubic; border: 0; height: auto; width: 100%; max-width: 140px; display: block;"'+
            '                                                         title="Image" width="80"/>'+
            '                                        </div>'+
            '                                    <div style="color:#5beda6;font-family:\'Chivo\', Arial, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:35px;padding-left:10px;">'+
            '                                        <div style="line-height: 1.2; font-family: \'Chivo\', Arial, Helvetica, sans-serif; font-size: 12px; color: #4b134f; mso-line-height-alt: 14px;">'+
            '                                            <p style="line-height: 1.2; text-align: center; font-size: 12px; mso-line-height-alt: 14px; margin: 0;">'+
            '                                                <span style="font-size: 12px;"><span style="font-size: 38px;">'+
            '                                                    <span style="font-size: 45px;"><strong>HISTREET</strong></span></span> </span>'+
            '                                            </p>'+
            '                                        </div>'+
            '                                    </div>'+
            '                                </div>'+
            '                            </div>'+
            '                        </div>'+
            '                    </div>'+
            '                </div>'+
            '            </div>'+
            '            <div style="background-image: url(\'https://storage.histreet-app.com/email/sayagata-400px.png\');background-position:top left;background-repeat:repeat;background-color:#F4F4F4;">'+
            '                <div class="block-grid mixed-two-up no-stack"'+
            '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
            '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
            '                        <div class="col num4"'+
            '                             style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 232px; width: 233px;">'+
            '                            <div style="width:100% !important;">'+
            '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:30px; padding-right: 0px; padding-left: 0px;">'+
            '                                    <div align="center" class="img-container center fixedwidth"'+
            '                                         style="padding-right: 40px;padding-left: 40px;">'+
            '                                        <div style="font-size:1px;line-height:40px"> </div>'+
            '                                        <img align="center" alt="Image" border="0" class="center fixedwidth"'+
            '                                             src="https://storage.histreet-app.com/email/illo_hero_alt_transparent_v1.png"'+
            '                                             style="text-decoration: none; -ms-interpolation-mode: bicubic; border: 0; height: auto; width: 100%; max-width: 116px; display: block;"'+
            '                                             title="Image" width="116"/>'+
            '                                        <div style="font-size:1px;line-height:40px"> </div>'+
            '                                    </div>'+
            '                                </div>'+
            '                            </div>'+
            '                        </div>'+
            '                        <div class="col num8"'+
            '                             style="display: table-cell; vertical-align: top; min-width: 320px; max-width: 464px; width: 466px;">'+
            '                            <div style="width:100% !important;">'+
            '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:45px; padding-bottom:30px; padding-right: 0px; padding-left: 0px;">'+
            '                                    <div style="color:#5beda6;font-family:\'Chivo\', Arial, Helvetica, sans-serif;line-height:1.2;padding-top:20px;padding-right:40px;padding-bottom:0px;padding-left:10px;">'+
            '                                        <div style="font-size: 12px; line-height: 1.2; font-family: \'Chivo\', Arial, Helvetica, sans-serif; color: #4b134f; mso-line-height-alt: 14px;">'+
            '                                            <p style="font-size: 22px; line-height: 1.2; mso-line-height-alt: 26px; margin: 0;">'+
            '                                                <span style="font-size: 22px;"><strong> Order <br/></strong></span>'+
            '                                            </p>'+
            '                                        </div>'+
            '                                    </div>'+
            '                                    <div style="color:#555555;font-family:\'Lato\', Tahoma, Verdana, Segoe, sans-serif;line-height:1.5;padding-top:0px;padding-right:40px;padding-bottom:0px;padding-left:10px;">'+
            '                                        <div style="font-size: 12px; line-height: 1.5; font-family: \'Lato\', Tahoma, Verdana, Segoe, sans-serif; color: #555555; mso-line-height-alt: 18px;">'+
            '                                            <p style="font-size: 16px; line-height: 1.5; text-align: left; mso-line-height-alt: 24px; margin: 0;">'+
            '                                                <span style="font-size: 16px;"> You\'ve placed an order at ' + pr_bi.biz_title + '</span>'+
            '                                            </p>'+
            '                                        </div>'+
            '                                    </div>'+
            '                                    <div align="left" class="button-container"'+
            '                                         style="padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">'+
            '                                    </div>'+
            '                                </div>'+
            '                            </div>'+
            '                        </div>'+
            '                    </div>'+
            '                </div>'+
            '            </div>'+
            '            <div style="background-color:transparent;">'+
            '                <div class="block-grid mixed-two-up no-stack"'+
            '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
            '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
            ''+
            ''+
            '                        <table border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\'>'+
            '                            <tr>'+
            '                                <td align=\'center\'>'+
            '                                    <table align=\'center\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\' class=\'main-table\''+
            '                                           bgcolor=\'#044767;\'>'+
            '                                        <tr>'+
            '                                            <td align=\'center\' valign=\'top\' class=\'table-header\'>'+
            '                                                <div class=\'first-section\'>'+
            '                                                    <table align=\'left\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\'>'+
            '                                                        <tr>'+
            '                                                            <td align=\'left\' valign=\'top\' class=\'mobile-center\'>'+
            '                                                                <h1 class=\'system-name\'>' + pr_bi.biz_title + '</h1></td>'+
            '                                                        </tr>'+
            '                                                    </table>'+
            '                                                </div>'+
            '                                                <div class=\'first-section-left mobile-hide\'>'+
            '                                                    <table align=\'left\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\'>'+
            '                                                        <tr>'+
            '                                                            <td align=\'right\' valign=\'top\'>'+
            '                                                                <table cellspacing=\'0\' cellpadding=\'0\' border=\'0\' align=\'right\'>'+
            '                                                                    <tr>'+
            '                                                                        <td class=\'first-section-left-details\'><p class=\'date-time\'><a'+
            '                                                                                class=\'date-time-link\'> ' + day + ' ' + monthNames[month] + ',' + year + '</a></p>'+
            '                                                                            <p class=\'date-time\'><a class=\'date-time-link\'>' + hours + '.' + minutes + ampm + ' ' + week[dayname] + '</a>'+
            '                                                                            </p></td>'+
            '                                                                    </tr>'+
            '                                                                </table>'+
            '                                                            </td>'+
            '                                                        </tr>'+
            '                                                    </table>'+
            '                                                </div>'+
            '                                            </td>'+
            '                                        </tr>'+
            '                                        <tr>'+
            '                                            <td align=\'center\' height=\'100%\' valign=\'top\' width=\'100%\' class=\'second-section\' bgcolor=\'#ffffff\'>'+
            '                                                <table align=\'center\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\''+
            '                                                       class=\'second-section-table\'>'+
            '                                                    <tr>'+
            '                                                        <td align=\'center\' valign=\'top\' class=\'order-td\'>'+
            '                                                            <div class=\'second-section-text\'>'+
            '                                                                <table align=\'left\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\''+
            '                                                                       class=\'table-width\'>'+
            '                                                                    <tr>'+
            '                                                                        <td class=\'second-table-td\' align=\'left\' valign=\'top\'>'+
            '                                                                                <p class=\'title-heading\'>User Information</p>'+
            '                                                                            <p><span>' + order_obj.user_info.username + '</span>'+
            '                                                                                <br/><span>' + props.__order.email + '</span></p></td>'+
            '                                                                    </tr>'+
            '                                                                </table>'+
            '                                                            </div>'+
            '                                                            <div class=\'second-section-text\'>'+
            '                                                                <table align=\'right\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\''+
            '                                                                       class=\'table-width\'>'+
            '                                                                    <tr>'+
            '                                                                        <td class=\'second-table-td\' align=\'right\' valign=\'top\'>'+
            '                                                                                <p class=\'title-heading\'>Delivery Type</p>';
        if( order_obj.delivery_option === "delivery" )
        {
            const line2 = order_obj.user_info.Address.line2 ? order_obj.user_info.Address.line2 + "," : "";

            html += '                                                                 <p><span>Home Delivery</span><br />'+
                '                                                                           <span>' + order_obj.user_info.Address.line1 + ', ' + line2 + '</span><br />'+
                '                                                                           <span>' + order_obj.user_info.Address.city + ', ' + order_obj.user_info.Address.countY + ', ' + order_obj.user_info.Address.postal_code + '</span>'+
                '                                                                     </p>';
        }
        else
        {
            html += '                                                                <p>Collection</p>';
        }

        html += '                                                                        </td>'+
            '                                                                    </tr>'+
            '                                                                </table>'+
            '                                                            </div>'+
            '                                                        </td>'+
            '                                                    </tr>'+
            '                                                </table>'+
            '                                            </td>'+
            '                                        </tr>'+
            '                                        <tr>'+
            '                                            <td class=\'third-section\' align=\'center\' bgcolor=\'#ffffff\'>'+
            '                                                <table align=\'center\' border=\'0\' cellpadding=\'0\' cellspacing=\'0\' width=\'100%\''+
            '                                                       class=\'third-section-width\'>'+
            '                                                    <tr>'+
            '                                                        <td align=\'center\' clsss=\'third-section-text\'><h2 class=\'third-section-thank\'> Thank You'+
            '                                                            For Your Order! </h2></td>'+
            '                                                    </tr>'+
            '                                                    <tr>'+
            '                                                        <td align=\'left\' clsss=\'data-table\'>'+
            '                                                            <table cellspacing=\'0\' cellpadding=\'0\' border=\'0\' width=\'100%\'>'+
            '                                                                <tr>'+
            '                                                                    <td width=\'100%\' colspan=\'3\' align=\'left\' bgcolor=\'#eeeeee\''+
            '                                                                        class=\'data-table-header\'>' + transid +
            '                                                                    </td>'+
            '                                                                </tr>'+

            invoice_data.map( sect_data=>
            {
                sect_data.data.map( item=>{
                    if(item._type === "setmeal")
                    {
                        let setmeal_html = "";

                        Object.keys(item._setm).map( (setm_key, idx) => {
                            const opt_obj = item._setm[setm_key];
                            opt_obj.items.map((_item, index) => (

                                setmeal_html += "&emsp;" + _item.quantity + " × " + _item.name  + "<br>"
                            ))
                        })

                        html += "<tr>" +
                            "                                            <td width='50%' align='left' class='data-row'>" +
                            item.name +"<br>" + setmeal_html +
                            "                                            </td>" +
                            "                                            <td width='25%' align='left' class='data-row'>" +
                            item._cnt +
                            "                                            </td>" +
                            "                                            <td width='25%' align='right' class='data-row'>" +
                            c_sign+ (item._cnt * item.price.def).toFixed(2)  +
                            "                                            </td>";
                    }

                    if(item._type === "mod")
                    {
                        item._mods.opts.map( (x, idx) => (
                            x._cnt
                                ?
                                html += "                                        <tr>" +
                                    "                                            <td width='50%' align='left' class='data-row'>" +
                                    item.name + " " + x.name +
                                    "                                            </td>" +
                                    "                                            <td width='25%' align='left' class='data-row'>" +
                                    x._cnt +
                                    "                                            </td>" +
                                    "                                            <td width='25%' align='right' class='data-row'>"
                                    + c_sign+ ( x._cnt * ( ItemPrice(item) + ItemPrice(x) ) ).toFixed(2)  +
                                    "                                            </td>"
                                : 	null
                        ) )
                    }

                    if (item._type === "mod_ex")
                    {
                        let _html = ""
                        Object.keys(item._opts).map((opt_key, idx) => {
                            const opt_obj = item._opts[opt_key];

                            if (opt_obj._cnt)
                            {
                                if (opt_obj.selSecMods.length)
                                {
                                    opt_obj.selSecMods.map((s_m, idx) => (
                                        s_m._cnt ? _html += "&emsp;" + " - " + s_m.obj.name + "<br>" : null

                                    ))
                                }

                                if (opt_obj.selMod.length)
                                {
                                    opt_obj.selMod.map((s_m, idx) => (
                                        s_m._cnt ? _html += "&emsp;" + " - " + s_m.obj.name + "<br>" : null
                                    ))
                                }

                                if (opt_obj.selAddons.length)
                                {
                                    opt_obj.selAddons.map((s_m, idx) => (
                                        s_m._cnt ? _html += "&emsp;" + " - " + s_m.obj.name + "<br>" : null
                                    ))
                                }
                            }

                            html += "<tr>" +
                                "                                            <td width='50%' align='left' class='data-row'>" +
                                item.name +"<br>" + _html +
                                "                                            </td>" +
                                "                                            <td width='25%' align='left' class='data-row'>" +
                                item._cnt +
                                "                                            </td>" +
                                "                                            <td width='25%' align='right' class='data-row'>"
                                + c_sign+  ONT.GetTotal_ModEx( item, opt_obj ).toFixed(2)   +
                                "</td>";
                        })
                    }

                    if(item._type === "basic")
                    {
                        html += "<tr>" +
                            "                                            <td width='50%' align='left' class='data-row'>" +
                            item.name +
                            "                                            </td>" +
                            "                                            <td width='25%' align='left' class='data-row'>" +
                            item._cnt +
                            "                                            </td>" +
                            "                                            <td width='25%' align='right' class='data-row'>" +
                            c_sign+( item._cnt * item.price.def).toFixed(2)  +
                            "                                            </td>";
                    }
                })

            })

        html += "                                        </tr>" +
            "                                    </table>" +
            "                                </td>" +
            "                            </tr>" +
            "                            <tr>" +
            "                                <td align='left' class='table-gap'>" +
            "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
            "                                        <tr>" +
            "                                            <td width='75%' align='left' class='data-table-bold'>" +
            "                                                Subtotal" +
            "                                            </td>" +
            "                                            <td width='25%' align='right' class='data-table-bold'>" +
            c_sign+ parseFloat(order_obj.invoice.SubTotal).toFixed(2) +
            "                                            </td>" +
            "                                        </tr>" +
            "                                    </table>" +
            "                                </td>" +
            "                            </tr>" ;

        if(props.__checkout.ListCharges().length)
        {
            console.log( "listCharges() : ", props.__checkout.ListCharges() );

            props.__checkout.ListCharges().map( ch =>  {

                // console.log("ch",ch);
                if(ch.amount && ch.type !=='subtotal' ) {
                    html +=
                        "                            <tr>" +
                        "                                <td align='left'>" +
                        "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
                        "                                        <tr>" +
                        "                                            <td width='75%' align='left' class='data-row'>" +  ch.name +
                        "                                            </td>" +
                        "                                            <td width='25%' align='right' class='data-row'>" + c_sign + ch.amount +
                        "                                            </td>" +
                        "                                        </tr>" +
                        "                                    </table>" +
                        "                                </td>" +
                        "                            </tr>"


                }


            })
        }
        if(props.__checkout.ListDiscounts().length)
        {
            console.log( "ListDiscounts() : ", props.__checkout.ListDiscounts() );

            props.__checkout.ListDiscounts().map((disc) => !disc.amount ? null : (

                html +=
                    "<tr>" +
                    "    <td align='left'>" +
                    "         <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
                    "              <tr>" +
                    "                  <td width='75%' align='left' class='data-row'>" +
                    "                       Discount ("+  disc.type+ ")"+
                    "                  </td>" +
                    "                  <td width='25%' align='right' class='data-row'>" +
                    "                       (-)"+c_sign+parseFloat(disc.amount).toFixed(2)+
                    "                  </td>" +
                    "              </tr>" +
                    "        </table>" +
                    "     </td>" +
                    "</tr>"



            ))
        }

        html +=
            "<tr>" +
            "                                <td align='left' style='padding-top: 10px;'>" +
            "                                    <table cellspacing='0' cellpadding='0' border='0' width='100%'>" +
            "                                        <tr>" +
            "                                            <td width='75%' align='left' class='data-table-bold'>" +
            "                                                TOTAL" +
            "                                            </td>" +
            "                                            <td width='25%' align='right' class='data-table-bold'>" +
            ""+c_sign+parseFloat( order_obj.invoice.Total ).toFixed(2) +
            "                                            </td>" +
            "                                        </tr>" +
            "                                    </table>" +
            "                                </td>" +
            "                            </tr>" +
            "                        </table>" +
            "                    </td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td align='center' height='100%' valign='top' width='100%' class='fourth-section'  bgcolor='#ffffff'>" +
            "                        <table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width:660px;'>" +
            "                            <tr>" +
            "                                    <td align='left' valign='top' width='600'>" +
            "                                    <div class='fourth-section-div-collection'>" +
            "                                        <table align='right' border='0' cellpadding='0' cellspacing='0' width='100%' class='fourth-section-width'>";

        if("card" == props.__checkout.payment.type)
        {
            html += "                                            <tr>" +
                "                                                <td align='right' valign='top' class='second-table-td'>" +
                "                                                    <p class='title-heading'> Payment Type </p>" +
                "                                                    <p><span>Card</span></p>" +
                "                                                </td>" +
                "                                            </tr>"+
                "                                            <tr>" +
                "                                                <td align='right' valign='top' class='second-table-td'>" +
                "                                                    <p class='title-heading'> Card No </p>" +
                "                                                    <p>**** **** **** <span>" +  props.__checkout.payment.card.card.last4 + "</span></p>" +
                "                                                </td>" +
                "                                            </tr>";
        }
        else
        {
            html += "                                            <tr>" +
                "                                                <td align='right' valign='top' class='second-table-td'>" +
                "                                                    <p class='title-heading'> Payment Type </p>" +
                "                                                    <p><span>Cash</span></p>" +
                "                                                </td>" +
                "                                            </tr>";
        }

        html+= "</table>" +
            "</div>" +
            "</td>" +
            "</tr>" +
            "/table>" +
            "</td>" +
            "</tr>" +
            "</table>"+
            "</td>"+
            "</tr>"+
            '</body>'

        // html+= "                                        </table>" +
        //     "                                    </div>" +
        //     "                                </td>" +
        //     "                            </tr>" +
        //     "                        </table>" +
        //     "                    </td>" +
        //     "                </tr>" +
        //     "</table>"+
        //     "</td>"+
        //     "</tr>"+
        //     "</table>"+
        //     ''+
        //     ''+
        //     '                    </div>'+
        //     '                </div>'+
        //     '            </div>'+
        //     '            <div style="background-color:#f3f5f7;">'+
        //     '                <div class="block-grid"'+
        //     '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
        //     '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
        //     '                        <div class="col num12"'+
        //     '                             style="min-width: 320px; max-width: 700px; display: table-cell; vertical-align: top; width: 700px;">'+
        //     '                            <div style="width:100% !important;">'+
        //     '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:50px; padding-bottom:60px; padding-right: 0px; padding-left: 0px;">'+
        //     '                                    <div align="center" class="img-container center fixedwidth"'+
        //     '                                         style="padding-right: 0px;padding-left: 0px;">'+
        //     '                                        <img align="center" alt="Image" border="0" class="center fixedwidth"'+
        //     '                                                         src="https://storage.histreet-app.com/email/illo_hero_alt_transparent_v1.png"'+
        //     '                                                         style="text-decoration: none; -ms-interpolation-mode: bicubic; border: 0; height: auto; width: 100%; max-width: 455px; display: block;"'+
        //     '                                                         title="Image" width="455"/>'+
        //     '                                        <div style="font-size:1px;line-height:30px"> </div>'+
        //     '                                    </div>'+
        //     '                                </div>'+
        //     '                            </div>'+
        //     '                        </div>'+
        //     '                    </div>'+
        //     '                </div>'+
        //     '            </div>'+
        //     '            <div style="background-color:#f3f5f7;">'+
        //     '                <div class="block-grid two-up no-stack"'+
        //     '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
        //     '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
        //     '                        <div class="col num6"'+
        //     '                             style="min-width: 320px; max-width: 350px; display: table-cell; vertical-align: top; width: 350px;">'+
        //     '                            <div style="width:100% !important;">'+
        //     '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:45px; padding-right: 0px; padding-left: 0px;">'+
        //     '                                    <!--<![endif]-->'+
        //     '                                    <div align="right" class="img-container right fixedwidth"'+
        //     '                                         style="padding-right: 20px;padding-left: 0px;">'+
        //     '                                        <a href="https://play.google.com/store/apps/details?id=com.histreet.app">'+
        //     '                                            <img align="right" alt="Image" border="0" class="right fixedwidth"'+
        //     '                                                         src="https://storage.histreet-app.com/email/gplay.gif"'+
        //     '                                                         style="text-decoration: none; -ms-interpolation-mode: bicubic; border: 0; height: auto; width: 100%; max-width: 122px; float: none; display: block;"'+
        //     '                                                         title="Image" width="122"/>'+
        //     '                                        </a>'+
        //     '                                    </div>'+
        //     '                                </div>'+
        //     '                            </div>'+
        //     '                        </div>'+
        //     '                        <div class="col num6"'+
        //     '                             style="min-width: 320px; max-width: 350px; display: table-cell; vertical-align: top; width: 350px;">'+
        //     '                            <div style="width:100% !important;">'+
        //     '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">'+
        //     '                                    <div align="left" class="img-container left fixedwidth"'+
        //     '                                         style="padding-right: 0px;padding-left: 20px;">'+
        //     '                                         <a href="https://apps.apple.com/bh/app/histreetapp/id1477813443">'+
        //     '                                            <img alt="Image" border="0" class="left fixedwidth"'+
        //     '                                                         src="https://storage.histreet-app.com/email/appstore.png"'+
        //     '                                                         style="text-decoration: none; -ms-interpolation-mode: bicubic; border: 0; height: auto; width: 100%; max-width: 122px; display: block;"'+
        //     '                                                         title="Image" width="122"/>'+
        //     '                                        </a>'+
        //     '                                    </div>'+
        //     '                                </div>'+
        //     '                            </div>'+
        //     '                        </div>'+
        //     '                    </div>'+
        //     '                </div>'+
        //     '            </div>'+
        //     '            <div style="background-color:#FFFFFF;">'+
        //     '                <div class="block-grid"'+
        //     '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
        //     '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
        //     '                        <div class="col num12"'+
        //     '                             style="min-width: 320px; max-width: 700px; display: table-cell; vertical-align: top; width: 700px;">'+
        //     '                            <div style="width:100% !important;">'+
        //     '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:40px; padding-bottom:40px; padding-right: 0px; padding-left: 0px;">'+
        //     '                                    <div align="center" class="img-container center autowidth"'+
        //     '                                         style="padding-right: 0px;padding-left: 0px;">'+
        //     '                                        <img align="center" alt="Image" border="0" class="center autowidth"'+
        //     '                                                         src="https://storage.histreet-app.com/email/logohs.svg"'+
        //     '                                                         style="text-decoration: none; -ms-interpolation-mode: bicubic; border: 0; height: auto; width: 100%; max-width: 80px; display: block;"'+
        //     '                                                         title="Image" width="80"/>'+
        //     '                                        <div style="font-size:1px;line-height:15px"> </div>'+
        //     '                                    </div>'+
        //     '                                    <div style="color:#5beda6;font-family:\'Lato\', Tahoma, Verdana, Segoe, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">'+
        //     '                                        <div style="font-size: 12px; line-height: 1.2; font-family: \'Lato\', Tahoma, Verdana, Segoe, sans-serif; color: #5beda6; mso-line-height-alt: 14px;">'+
        //     '                                            <p style="font-size: 16px; line-height: 1.2; text-align: center; mso-line-height-alt: 19px; margin: 0;">'+
        //     '                                                <span style="font-size: 22px;color:#4e1d54"><strong>Histreet</strong></span>'+
        //     '                                            </p>'+
        //     '                                            <p style="font-size: 16px; line-height: 1.2; text-align: center; mso-line-height-alt: 19px; margin: 0;">'+
        //     '                                                <span style="color: #333333; font-size: 16px;">John Eccles House, Robert Robinson Avenue, Oxford Science Park, Oxford, OX4 4GP, Oxford, UK</span>'+
        //     '                                            </p>'+
        //     '                                            <p style="font-size: 16px; line-height: 1.2; text-align: center; mso-line-height-alt: 19px; margin: 0;">'+
        //     '                                                <span style="color: #333333; font-size: 16px;">+44 (0) 1865 922 172 </span>'+
        //     '                                            </p>'+
        //     '                                        </div>'+
        //     '                                    </div>'+
        //     '                                    <table cellpadding="0" cellspacing="0" class="social_icons" role="presentation"'+
        //     '                                           style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;"'+
        //     '                                           valign="top" width="100%">'+
        //     '                                        <tbody>'+
        //     '                                        <tr style="vertical-align: top;" valign="top">'+
        //     '                                            <td style="word-break: break-word; vertical-align: top; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;"'+
        //     '                                                valign="top">'+
        //     '                                                <table activate="activate" align="center" alignment="alignment"'+
        //     '                                                       cellpadding="0" cellspacing="0" class="social_table"'+
        //     '                                                       role="presentation"'+
        //     '                                                       style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: undefined; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;"'+
        //     '                                                       to="to" valign="top">'+
        //     '                                                    <tbody>'+
        //     '                                                    <tr align="center"'+
        //     '                                                        style="vertical-align: top; display: inline-block; text-align: center;"'+
        //     '                                                        valign="top">'+
        //     '                                                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 3px; padding-left: 3px;"'+
        //     '                                                            valign="top"><a href="https://twitter.com/" target="_blank"><img'+
        //     '                                                                alt="Twitter" height="32" src="https://storage.histreet-app.com/email/twitter@2x.png"'+
        //     '                                                                style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: none; display: block;"'+
        //     '                                                                title="Twitter" width="32"/></a></td>'+
        //     '                                                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 3px; padding-left: 3px;"'+
        //     '                                                            valign="top"><a href="https://plus.google.com/"'+
        //     '                                                                            target="_blank"><img alt="Google+"'+
        //     '                                                                                                 height="32"'+
        //     '                                                                                                 src="https://storage.histreet-app.com/email/googleplus@2x.png"'+
        //     '                                                                                                 style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: none; display: block;"'+
        //     '                                                                                                 title="Google+"'+
        //     '                                                                                                 width="32"/></a></td>'+
        //     '                                                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 3px; padding-left: 3px;"'+
        //     '                                                            valign="top"><a href="https://www.youtube.com/"'+
        //     '                                                                            target="_blank"><img alt="YouTube"'+
        //     '                                                                                                 height="32"'+
        //     '                                                                                                 src="https://storage.histreet-app.com/email/youtube@2x.png"'+
        //     '                                                                                                 style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: none; display: block;"'+
        //     '                                                                                                 title="YouTube"'+
        //     '                                                                                                 width="32"/></a></td>'+
        //     '                                                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 3px; padding-left: 3px;"'+
        //     '                                                            valign="top"><a href="https://www.facebook.com/"'+
        //     '                                                                            target="_blank"><img alt="Facebook"'+
        //     '                                                                                                 height="32"'+
        //     '                                                                                                 src="https://storage.histreet-app.com/email/facebook@2x.png"'+
        //     '                                                                                                 style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: none; display: block;"'+
        //     '                                                                                                 title="Facebook"'+
        //     '                                                                                                 width="32"/></a></td>'+
        //     '                                                    </tr>'+
        //     '                                                    </tbody>'+
        //     '                                                </table>'+
        //     '                                            </td>'+
        //     '                                        </tr>'+
        //     '                                        </tbody>'+
        //     '                                    </table>'+
        //     '                                </div>'+
        //     '                            </div>'+
        //     '                        </div>'+
        //     '                    </div>'+
        //     '                </div>'+
        //     '            </div>'+
        //     '            <div style="background-color:#F4F4F4;">'+
        //     '                <div class="block-grid"'+
        //     '                     style="Margin: 0 auto; min-width: 320px; max-width: 700px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;">'+
        //     '                    <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">'+
        //     '                        <div class="col num12"'+
        //     '                             style="min-width: 320px; max-width: 700px; display: table-cell; vertical-align: top; width: 700px;">'+
        //     '                            <div style="width:100% !important;">'+
        //     '                                <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:25px; padding-bottom:25px; padding-right: 0px; padding-left: 0px;">'+
        //     '                                    <div style="color:#555555;font-family:\'Lato\', Tahoma, Verdana, Segoe, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">'+
        //     '                                        <div style="font-size: 12px; line-height: 1.2; font-family: \'Lato\', Tahoma, Verdana, Segoe, sans-serif; color: #555555; mso-line-height-alt: 14px;">'+
        //     '                                            <p style="font-size: 12px; line-height: 1.2; text-align: center; mso-line-height-alt: 14px; margin: 0;">'+
        //     '                                                All rights reserved © 2019 /  Histreet</p>'+
        //     '                                        </div>'+
        //     '                                    </div>'+
        //     '                                </div>'+
        //     '                            </div>'+
        //     '                        </div>'+
        //     '                    </div>'+
        //     '                </div>'+
        //     '            </div>'+
        //     '        </td>'+
        //     '    </tr>'+
        //     '    </tbody>'+
        //     '</table>'+
        //     '</body>'

        // console.log( "html : ", html);

        let __html =
            '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN"'+
            '        "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">'+
            ''+
            '<html>'+
            '<head>'+
            '    <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>'+
            '    <meta content="width=device-width" name="viewport"/>'+
            '    <meta content="IE=edge" http-equiv="X-UA-Compatible"/>'+
            '    <title>Hs Email</title>'+
            '    <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet" type="text/css"/>'+
            '<style>        '+
            '    .body {'+
            '        margin: 0 !important;'+
            '        padding: 0 !important;'+
            '        background-color: #f5f5f5;'+
            '        font-family: Open Sans, Helvetica, Arial, sans-serif;'+
            '    }'+
            ''+
            '    .table-shadow {'+
            '        -webkit-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.75);'+
            '        -moz-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.75);'+
            '        box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.75);'+
            '    }'+
            ''+
            '    .main-table {'+
            '        max-width: 600px;'+
            '        background-color: #34495e;'+
            '    }'+
            ''+
            '    .table-header {'+
            '        padding: 35px 20px;'+
            '        font-size: 0;'+
            '    }'+
            ''+
            '    .first-section {'+
            '        display: inline-block;'+
            '        max-width: 50%;'+
            '        min-width: 100px;'+
            '        vertical-align: top;'+
            '        width: 100%;'+
            '    }'+
            ''+
            '    .first-section > table {'+
            '        max-width: 300px;'+
            '    }'+
            ''+
            '    .first-section > table > tr > td {'+
            '        font-size: 36px;'+
            '        font-weight: 800;'+
            '    }'+
            ''+
            '    .system-name {'+
            '        font-size: 30px;'+
            '        font-weight: 800;'+
            '        margin: 0;'+
            '        color: #ffffff;'+
            '    }'+
            ''+
            '    .first-section-left {'+
            '        display: inline-block;'+
            '        max-width: 50%;'+
            '        min-width: 100px;'+
            '        vertical-align: top;'+
            '        width: 100%;'+
            '    }'+
            ''+
            '    .first-section-left > table {'+
            '        max-width: 300px;'+
            '    }'+
            ''+
            '    .first-section-left-details {'+
            '        font-size: 16px;'+
            '        font-weight: 400;'+
            '        line-height: 30px;'+
            '    }'+
            ''+
            '    .date-time {'+
            '        font-weight: 400;'+
            '        margin: 0;'+
            '        color: #ffffff;'+
            '    }'+
            ''+
            '    .date-time-link {'+
            '        color: #ffffff;'+
            '        text-decoration: none;'+
            '    }'+
            ''+
            '    .second-section {'+
            '        padding: 5px 20px 5px 20px;'+
            '        background-color: #ffffff;'+
            '    }'+
            ''+
            '    .second-section-table {'+
            '        max-width: 660px;'+
            '    }'+
            ''+
            '    .order-td {'+
            '        font-size: 0;'+
            '    }'+
            ''+
            '    .second-section-text {'+
            '        display: inline-block;'+
            '        max-width: 50%;'+
            '        width: 100%;'+
            '        vertical-align: top;'+
            '    }'+
            ''+
            '    .table-width {'+
            '        max-width: 300px;'+
            '    }'+
            ''+
            '    .second-table-td {'+
            '        font-size: 16px;'+
            '        font-weight: 400;'+
            '    }'+
            ''+
            '    .title-heading {'+
            '        font-weight: 800;'+
            '    }'+
            ''+
            '    .third-section {'+
            '        padding: 5px 20px 5px 20px;'+
            '        background-color: #ffffff;'+
            '    }'+
            ''+
            '    .third-section-width {'+
            '        max-width: 600px;'+
            '    }'+
            ''+
            '    .third-section-thank {'+
            '        font-size: 30px;'+
            '        font-weight: 800;'+
            '        line-height: 36px;'+
            '        color: #333333;'+
            '        margin: 0;'+
            '        margin-bottom: 20px;'+
            '    }'+
            ''+
            '    .third-section-text {'+
            '        font-size: 16px;'+
            '        font-weight: 400;'+
            '    }'+
            ''+
            '    .data-table {'+
            '        padding-top: 20px;'+
            '    }'+
            ''+
            '    .data-table-header {'+
            '        font-size: 16px;'+
            '        font-weight: 800;'+
            '        padding: 10px;'+
            '    }'+
            ''+
            '    .data-row {'+
            '        font-size: 16px;'+
            '        font-weight: 400;'+
            '        padding: 15px 10px 5px 10px;'+
            '    }'+
            ''+
            '    .table-gap {'+
            '        padding-top: 10px;'+
            '    }'+
            ''+
            '    .data-table-bold {'+
            '        font-size: 16px;'+
            '        font-weight: 800;'+
            '        padding: 10px;'+
            '        border-top: 3px solid #eeeeee;'+
            '        border-bottom: 3px solid #eeeeee;'+
            '    }'+
            ''+
            '    .fourth-section {'+
            '        padding: 5px 20px 5px 20px;'+
            '        background-color: #ffffff;'+
            '    }'+
            ''+
            '    .fourth-section-width {'+
            '        max-width: 300px;'+
            '    }'+
            ''+
            '    .fourth-section-div {'+
            '        display: inline-block;'+
            '        max-width: 50%;'+
            '        vertical-align: top;'+
            '        width: 100%;'+
            '    }'+
            ''+
            '    .fourth-section-div-collection {'+
            '        display: inline-block;'+
            '        vertical-align: top;'+
            '        width: 100%;'+
            '    }'+
            ''+
            '    .last-section {'+
            '        padding: 5px;'+
            '        background-color: #1b9ba3;'+
            '    }'+
            ''+
            '    .last-text {'+
            '        font-weight: 800;'+
            '        color: #ffffff !important;'+
            '    }'+
            ''+
            '    .lastest-section {'+
            '        padding: 5px;'+
            '        background-color: #34495e;'+
            '    }'+
            '    '+
            '    </style>'+
            '    <style type="text/css">'+
            '        body {'+
            '            margin: 0;'+
            '            padding: 0;'+
            '        }'+
            ''+
            '        table,'+
            '        td,'+
            '        tr {'+
            '            vertical-align: top;'+
            '            border-collapse: collapse;'+
            '        }'+
            ''+
            '        * {'+
            '            line-height: inherit;'+
            '        }'+
            ''+
            '        a[x-apple-data-detectors=true] {'+
            '            color: inherit !important;'+
            '            text-decoration: none !important;'+
            '        }'+
            '    </style>'+
            '    <style id="media-query" type="text/css">'+
            '        @media (max-width: 720px) {'+
            ''+
            '            .block-grid,'+
            '            .col {'+
            '                min-width: 320px !important;'+
            '                max-width: 100% !important;'+
            '                display: block !important;'+
            '            }'+
            ''+
            '            .block-grid {'+
            '                width: 100% !important;'+
            '            }'+
            ''+
            '            .col {'+
            '                width: 100% !important;'+
            '            }'+
            ''+
            '            .col > div {'+
            '                margin: 0 auto;'+
            '            }'+
            ''+
            '            img.fullwidth,'+
            '            img.fullwidthOnMobile {'+
            '                max-width: 100% !important;'+
            '            }'+
            ''+
            '            .no-stack .col {'+
            '                min-width: 0 !important;'+
            '                display: table-cell !important;'+
            '            }'+
            ''+
            '            .no-stack.two-up .col {'+
            '                width: 50% !important;'+
            '            }'+
            ''+
            '            .no-stack .col.num4 {'+
            '                width: 33% !important;'+
            '            }'+
            ''+
            '            .no-stack .col.num8 {'+
            '                width: 66% !important;'+
            '            }'+
            ''+
            '            .no-stack .col.num4 {'+
            '                width: 33% !important;'+
            '            }'+
            ''+
            '            .no-stack .col.num3 {'+
            '                width: 25% !important;'+
            '            }'+
            ''+
            '            .no-stack .col.num6 {'+
            '                width: 50% !important;'+
            '            }'+
            ''+
            '            .no-stack .col.num9 {'+
            '                width: 75% !important;'+
            '            }'+
            ''+
            '            .video-block {'+
            '                max-width: none !important;'+
            '            }'+
            ''+
            '            .mobile_hide {'+
            '                min-height: 0px;'+
            '                max-height: 0px;'+
            '                max-width: 0px;'+
            '                display: none;'+
            '                overflow: hidden;'+
            '                font-size: 0px;'+
            '            }'+
            ''+
            '            .desktop_hide {'+
            '                display: block !important;'+
            '                max-height: none !important;'+
            '            }'+
            '        }'+
            '    </style>'+
            '</head>';
        __html += html;
        __html += "</html>";

        // console.log("__html : ", __html);

        return __html;
    }
    catch( err )
    {
        console.warn( 'checkout_invoice/Invoice_Process: err: ', err )
        return Promise.reject( err )
    }
}   // Invoice_Process

export { Invoice_Save, Invoice_Process }
