/**
 *	@copyright	Elmelo Ltd.
 */


export default ( state = {
    initStatus: 'stale',
    biz_id: '',
}, action ) =>
{
    switch( action.type )
    {
        case 'biz:init-status':
            return {...state, initStatus: action.payload}

        case 'set:biz':
            console.log("action.payload",action.payload)
            return {...state,...action.payload}

        default: return state
    }   // switch
}

