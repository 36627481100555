/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import './team.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {Row, Col, Image, Container, Table} from 'react-bootstrap'
import {navigate} from "@reach/router"

/**
 */
class Privacy_Home extends React.PureComponent {
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render() {
        return (
            <div>
                <Hdr/>
                <div style={{marginTop: 64}} className="headerText">Privacy Policy</div>
                <Container>
                    <p className="paragraph">
                        This privacy policy applies between you, the User of this Website and Elmelo Ltd, the owner and
                        provider of this Website. Elmelo Ltd takes the privacy of your information very seriously. This
                        privacy policy applies to our use of any and all Data collected by us or provided by you in
                        relation to your use of the Website. This privacy policy should be read alongside, and in
                        addition to, our Terms and Conditions, which can be found at: <span
                        className="pText">www.i24app.com/terms&conditions</span>. Please read this privacy policy
                        carefully
                    </p>
                    <div className="pText">Definitions and interpretations</div>
                    <p className="paragraph">
                        1. In this privacy policy, the following definitions are used
                    </p>
                    <Table striped bordered hover>
                        <tbody>
                        <tr>
                            <td className="pText">Data</td>
                            <td className="paragraph">
                                collectively all information that you submit to Elmelo Ltd via the Website. This
                                definition incorporates, where applicable, the definitions provided in the Data
                                Protection Laws;
                            </td>
                        </tr>
                        <tr>
                            <td className="pText">Data Protection Laws</td>
                            <td className="paragraph">
                                any applicable law relating to the processing of personal Data, including but not
                                limited to the GDPR, and any national implementing and supplementary laws, regulations
                                and secondary legislation
                            </td>
                        </tr>
                        <tr>
                            <td className="pText">GDPR</td>
                            <td className="paragraph">
                                the UK General Data Protection Regulation
                            </td>
                        </tr>
                        <tr>
                            <td className="pText">Elmelo Ltd, we or us</td>
                            <td className="paragraph">
                                Elmelo Ltd, a company incorporated in England and Wales with registered
                                number 10257702 whose registered office is at 20 Bankside, Station
                                Approach, Kidlington, Oxfordshire, OX5 1JE
                            </td>
                        </tr>
                        <tr>
                            <td className="pText">User or you</td>
                            <td className="paragraph">
                                any third party that accesses the Website and is not either (i) employed by Elmelo Ltdand
                                acting in the course of their employment or (ii) engaged as a consultant or otherwise
                                providing services to Elmelo Ltd and accessing the Website in connection with the
                                provision of such services;
                            </td>
                        </tr>
                        <tr>
                            <td className="pText">Website</td>
                            <td className="paragraph">
                                the website that you are currently using, i24app.com, and any sub-domains of this
                                site unless expressly excluded by their own terms and conditions
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <p className="paragraph">
                        2. In this privacy policy, unless the context requires a different interpretation
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. the singular includes the plural and vice versa;
                        <br/>b. references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses,
                        schedules or appendices of this privacy policy;
                        <br/>c. a reference to a person includes firms, companies, government entities, trusts and
                        partnerships;
                        <br/>d. "including" is understood to mean "including without limitation";
                        <br/>e. reference to any statutory provision includes any modification or amendment of it;
                        <br/>f. the headings and sub-headings do not form part of this privacy policy
                    </p>
                    <div className="pText">
                        Scope of this privacy policy
                    </div>
                    <p className="paragraph">
                        3. This privacy policy applies only to the actions of Elmelo Ltd and Users with respect to this
                        Website. It does not extend to any websites that can be accessed from this Website including,
                        but not limited to, any links we may provide to social media websites.
                    </p>
                    <p className="paragraph">
                        4. For purposes of the applicable Data Protection Laws,Elmelo Ltdis the "data controller". This
                        means that Elmelo Ltd determines the purposes for which, and the manner in which, your Data is
                        processed.
                    </p>

                    <div className="pText">
                        Data Collected
                    </div>
                    <p className="paragraph">
                        5. We may collect the following Data, which includes personal Data, from you:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. name;
                        <br/>b. web browser type and version (automatically collected);
                        <br/>c. operating system (automatically collected);
                        <br/>d. a list of URLs starting with a referring site, your activity on this Website, and the
                        site you exit to (automatically collected);
                    </p>
                    <p className="paragraph">
                        in each case, in accordance with this privacy policy.
                    </p>

                    <div className="pText">
                        How we collect Data
                    </div>
                    <p className="paragraph">
                        6. We collect Data in the following ways:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. data is given to us by you;
                        <br/>b.data is collected automatically.
                    </p>

                    <div className="pText">
                        Data that is given us by you
                    </div>
                    <p className="paragraph">
                        7. Elmelo Ltd will collect your Data in a number of ways, for example:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. when you contact us through the Website, by telephone, post, e-mail or through any other
                        means;
                        <br/>b. when you register with us and set up an account to receive our products/services;
                    </p>
                    <p className="paragraph">
                        in each case, in accordance with this privacy policy.
                    </p>

                    <div className="pText">
                        Data that is collected automatically
                    </div>
                    <p className="paragraph">
                        8. To the extent that you access the Website, we will collect your Data automatically, for
                        example:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. we automatically collect some information about your visit to the Website. This information
                        helps us to make improvements to Website content and navigation, and includes your IP address,
                        the date, times and frequency with which you access the Website and the way you use and interact
                        with its content.
                    </p>


                    <div className="pText">
                        Our use of Data
                    </div>
                    <p className="paragraph">
                        9. Any or all of the above Data may be required by us from time to time in order to provide you
                        with the best possible service and experience when using our Website. Specifically, Data may be
                        used by us for the following reasons:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. internal record keeping;
                        <br/>b. improvement of our products / services;
                        <br/>c. transmission by email of marketing materials that may be of interest to you;
                    </p>
                    <p className="paragraph">
                        in each case, in accordance with this privacy policy.
                    </p>

                    <p className="paragraph">
                        10.We may use your Data for the above purposes if we deem it necessary to do so for our
                        legitimate interests. If you are not satisfied with this, you have the right to object in
                        certain circumstances (see the section headed "Your rights" below
                    </p>

                    <p className="paragraph">
                        11. For the delivery of direct marketing to you via e-mail, we'll need your consent, whether via
                        an opt-in or soft-opt-in.
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. soft opt-in consent is a specific type of consent which applies when you have previously
                        engaged with us (for example, you contact us to ask us for more details about a particular
                        product/service, and we are marketing similar products/services). Under "soft opt-in" consent, we
                        will take your consent as given unless you opt-out.
                        <br/>b. for other types of e-marketing, we are required to obtain your explicit consent; that
                        is, you need to take positive and affirmative action when consenting by, for example, checking a
                        tick box that we'll provide.
                        <br/>c. if you are not satisfied about our approach to marketing, you have the right to withdraw
                        consent at any time. To find out how to withdraw your consent, see the section headed "Your
                        rights" below.
                    </p>

                    <p className="paragraph">
                        12. When you register with us and set up an account to receive our services, the legal basis
                        for this processing is the performance of a contract between you and us and/or taking steps, at
                        your request, to enter into such a contract.
                    </p>


                    <div className="pText">
                        Who we share Data with
                    </div>
                    <p className="paragraph">
                        13. We may share your Data with the following groups of people for the following reasons:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. our employees, agents and/or professional advisors -to offer consultation upon request by the
                        user;
                    </p>
                    <p className="paragraph">
                        in each case, in accordance with this privacy policy.
                    </p>


                    <div className="pText">
                        Keeping Data secure
                    </div>
                    <p className="paragraph">
                        14. We will use technical and organisational measures to safeguard your Data, for example:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. access to your account is controlled by a password and a user name that is unique to you.
                        <br/> b. we store your Data on secure servers.
                    </p>
                    <p className="paragraph">
                        15. Technical and organisational measures include measures to deal with any suspected data
                        breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us
                        know immediately by contacting us via this e-mail address:hello@histreet-app.com.
                    </p>

                    <p className="paragraph">
                        16. If you want detailed information from Get Safe Online on how to protect your information and
                        your computers and devices against fraud, identity theft, viruses and many other online
                        problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and
                        leading businesses.
                    </p>


                    <div className="pText">
                        Data retention
                    </div>
                    <p className="paragraph">
                        17. Unless a longer retention period is required or permitted by law, we will only hold your
                        Data on our systems for the period necessary to fulfil the purposes outlined in this privacy
                        policy or until you request that the Data be deleted.
                    </p>
                    <p className="paragraph">
                        18. Even if we delete your Data, it may persist on backup or archival media for legal, tax or
                        regulatory purposes.
                    </p>

                    <div className="pText">
                        Your rights
                    </div>
                    <p className="paragraph">
                        19. You have the following rights in relation to your Data:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. Right to access- the right to request (i) copies of the information we hold about you at any
                        time, or (ii) that we modify, update or delete such information. If we provide you with access
                        to the information we hold about you, we will not charge you for this, unless your request is
                        "manifestly unfounded or excessive." Where we are legally permitted to do so, we may refuse your
                        request. If we refuse your request, we will tell you the reasons why.
                        <br/> b. Right to correct- the right to have your Data rectified if it is inaccurate or
                        incomplete.
                        <br/> c. Right to erase- the right to request that we delete or remove your Data from our
                        systems.
                        <br/> d. Right to restrict our use of your Data- the right to "block" us from using your Data or
                        limit the way in which we can use it.
                        <br/> e. Right to data portability- the right to request that we move, copy or transfer your
                        Data.
                        <br/> f. Right to object- the right to object to our use of your Data including where we use it
                        for our legitimate interests.
                    </p>

                    <p className="paragraph">
                        20. To make enquiries, exercise any of your rights set out above, or withdraw your consent to
                        the processing of your Data (where consent is our legal basis for processing your Data), please
                        contact us via this e-mail address:hello@histreet-app.com.
                    </p>
                    <p className="paragraph">
                        21. If you are not satisfied with the
                        way a complaint you make in relation to your Data is handled by us, you may be able to refer
                        your complaint to the relevant data protection authority. For the UK, this is the Information
                        Commissioner's Office (ICO). The ICO's contact details can be found on their website at
                        https://ico.org.uk/.
                    </p>
                    <p className="paragraph">
                        22. It is important that the Data we hold about you is accurate and current.
                        Please keep us informed if your Data changes during the period for which we hold it.
                    </p>


                    <div className="pText">
                        Links to other websites
                    </div>

                    <p className="paragraph">
                        23. This Website may, from time to time, provide links to other websites. We have no control
                        over such websites and are not responsible for the content of these websites. This privacy
                        policy does not extend to your use of such websites. You are advised to read the privacy policy
                        or statement of other websites prior to using them.
                    </p>


                    <div className="pText">
                        Changes of business ownership and control
                    </div>

                    <p className="paragraph">
                        24. Elmelo Ltd may, from time to time, expand or reduce our business and this may involve the
                        sale and/or the transfer of control of all or part of Elmelo Ltd. Data provided by Users will,
                        where it is relevant
                        to any part of our business so transferred, be transferred along with that part and the new
                        owner or newly controlling party will, under the terms of this privacy policy, be permitted to
                        use the Data for the purposes for which it was originally supplied to us.
                    </p>

                    <p className="paragraph">
                        25. We may also disclose Data to a prospective purchaser of our business or any part of it.
                    </p>

                    <p className="paragraph">
                        26.In the above instances, we will take steps with the aim of ensuring your privacy is
                        protected.
                    </p>


                    <div className="pText">
                        General
                    </div>

                    <p className="paragraph">
                        27. You may not transfer any of your rights under this privacy policy to any other person. We
                        may transfer our rights under this privacy policy where we reasonably believe your rights will
                        not be affected.
                    </p>

                    <p className="paragraph">
                        28. If any court or competent authority finds that any provision of this privacy policy (or part
                        of any provision) is invalid, illegal or unenforceable, that provision or part-provision will,
                        to the extent required, be deemed to be deleted, and the validity and enforceability of the
                        other provisions of this privacy policy will not be affected.
                    </p>

                    <p className="paragraph">
                        29.Unless otherwise agreed, no delay, act or omission by a party in exercising any right or
                        remedy will be deemed a waiver of that, or any other, right or remedy.
                    </p>

                    <p className="paragraph">
                        30. This Agreement will be governed by and interpreted according to the law ofEngland and Wales.
                        All disputes arising under the Agreement will be subject to the exclusive jurisdiction of
                        theEnglish and Welsh courts.
                    </p>

                    <div className="pText">
                        Changes to this privacy policy
                    </div>

                    <p className="paragraph">
                        31. Elmelo Ltd reserves the right to change this privacy policy as we may deem necessary from
                        time to time or as may be required by law. Any changes will be immediately posted on the Website
                        and you are deemed to have accepted the terms of the privacy policy on your first use of the
                        Website following the alterations.You may contact Elmelo Ltd by email athello@histreet-app.com
                    </p>

                    <div className="pText">
                        Attribution
                    </div>

                    <p className="paragraph">
                        32. This privacy policy was created using a document fromRocket
                        Lawyer(https://www.rocketlawyer.com/gb/en).03 May 2021
                    </p>
                </Container>
                <Ftr/>
            </div>
        )
    }

    /**
     */
    componentDidMount() {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Privacy_Home)


