import React from 'react'
import {
    Image,
    Button,
    Row,
    Col,
    Form,
    Modal
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowDown} from '@fortawesome/free-solid-svg-icons'
// import '../../css/_common.css'
//  import '../../css/_common.css'
class PickerEx extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
            selected: this.props.items.find( x => x.val === this.props.val ),
            bModal: this.props.bOpen ? true : false,
        }
    }

    /**
     */
    render()
    {
        // console.log( 'ui.Picker: PickerEx: render: this.state: ', this.state , this.props );


        return (
            <div className={`${this.props.pickerTitle ? 'pickerTitle' : 'pickerBox'}`} style={{flex:1,marginBottom:16,padding:8,borderRadius:4,justifyContent:"flex-start"}}>
                <div
                    style={{flex:1}}
                    onClick={()=>this.setState({bModal: true})}>
                    <div style={{display:"flex",flexDirection:"row",flex:1}}>
                        <div>
                            {this.props.val ? this.props.val : this.props.prompt ? this.props.prompt : 'Select an option'}
                        </div>
                        <div>
                            <FontAwesomeIcon style={{marginLeft: 24}} icon={faArrowDown} size="sm"/>
                        </div>
                    </div>

                </div>

                <div>
                    {
                        this.state.bModal
                            ?	<Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                show={true}
                                onHide={()=>this.setState( {bModal: false} )}
                                animation={true}
                            >
                                <div style={{flex:1}}>
                                    <div onClick={() => this.setState({bModal: false})}>
                                        <div style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.5)'}}></div>
                                    </div>
                                    <div style={{height:"80%",backgroundColor:"#fff",padding :16}}>
                                        <div className="infoTitle">
                                            Choose an Item
                                        </div>
                                        <div>
                                            {
                                                this.props.prompt ?
                                                    <div style={{color: "white", fontSize: 14}}>{this.props.prompt}</div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            this.props.items.map((item,idx)=>(

                                                this.RenderItem(item, idx)
                                            ))
                                        }
                                    </div>
                                </div>
                            </Modal>

                            : 	null
                    }
                </div>
            </div>
        )
    }	// render

    /**
     */
    RenderItem = (item) =>
    {
        return (
            <div key={item.val} onClick={()=>{
                if( this.props.onChange )
                    this.props.onChange( item.val )

                this.setState( {bModal: false, selected: item} )
            }}
                 className="pickerItem"
            >
                <div className="pickerLabel">{item.label}</div>
            </div>
        )
    }

}	// class PickerEx

export { PickerEx }
