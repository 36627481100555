/**
 *  @copyright  Elmelo Ltd
 */

import React from 'react'
import {
    Image,
    Button,
    Row,
    Col,
    Form,
    Modal
} from 'react-bootstrap'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import { Core,DDB} from '../../api'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faTimes, faSquare , faCheckSquare
} from '@fortawesome/free-solid-svg-icons'
import color from "../_common/colors";
import {navigate} from "@reach/router";


/**
 */
class Loyalty extends React.PureComponent
{
    /**
     */
    constructor (props)
    {
        super (props);

        this.state= {

            loading: false,
            loyalty_list: [],
            schemes : [],
            ts: Date.now()
        }
    }

    /**
     */
    componentDidMount ()
    {
        this.setState({loading: true});

        this._Loyalty_Get()
            .then()
            .catch();
    }

    /**
     */
    async _Loyalty_Get()
    {
        try
        {
            const cfg = this.props.__cfg
            
            const aws_core = new Core( {} )
            const aws_ddb = new DDB( {} )

            this.setState({loading: true})

            const p_query = {
                TableName: cfg.db( 'wallet', cfg.stage ),
                KeyConditionExpression: '#a = :a',
                ExpressionAttributeNames: {'#a': 'beta_user_id'},
                ExpressionAttributeValues: {':a': await aws_core.Id()},
            };

            const resp_query = await aws_ddb.Query( p_query );
            // // console.log("loyalty data", resp_query);
            this.setState({loyalty_list: resp_query.Items,loading: false})

        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }   // _Loyalty_Get

    /**
     */
    selectLoyalty = (loyalty) => {

        let _index = this.state.schemes.findIndex( item => item.id === loyalty.wallet_id )

        if(_index != -1)
        {
            loyalty.check = false
            this.state.schemes.splice(_index, 1)
        }
        else
        {
            loyalty.check = true
            this.state.schemes.push({id: loyalty.wallet_id , amount: loyalty.available  })
        }
        // console.log( 'schemes', this.state.schemes )
        this.setState({ts: Date.now()})
    }

    _ApplyLoyalty = () =>
    {
        this.props.Rdx_Checkout_SetLoyalty( this.state.schemes );
        this.props.showLoyalty(false);
    }

    /**
     */
    render ()
    {
        // // console.log("props", this.props.__checkout)
        
        const {loading,loyalty_list} = this.state;


        return (


            <div className="extraDiv">
                <div onClick={()=>this.props.showLoyalty(false)} className="buttonAuthDiv">
                    <FontAwesomeIcon
                        className="extraButtonAuth"
                        icon={faTimes}
                        size="lg"
                        color={"red"}/>
                </div>
                {
                    !loading ?
                        this.state.loyalty_list.length === 0 ?
                            <div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{color: 'rgba(0,0,0,0.25)'}}>No Loyalty Points are available!</div>
                            </div> :
                            <div>
                                <div className="extraDivText">Select Special Discount</div>
                                <div>
                                    {
                                        loyalty_list.map((wallet, index) => {
                                            return (
                                                <div key={index} onClick={()=> this.selectLoyalty(wallet)}>
                                                    {
                                                        <div className="addressSelect"
                                                             style={{color: color.colors.light}}>
                                                            <FontAwesomeIcon
                                                                style={{marginRight: 8}}
                                                                icon={wallet.check ? faCheckSquare : faSquare} size="lg"
                                                                color={wallet.check ? 'green': "#C4C4C4" }/>
                                                            <div>
                                                                { wallet.lt_name ? wallet.lt_name : wallet.wallet_id }
                                                                ( {wallet.available} )
                                                            </div>
                                                        </div>

                                                    }
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                                <div>
                                    <Button
                                        onClick={()=> this._ApplyLoyalty()}
                                        className="cartButton"
                                        style={{background: "linear-gradient(to right, #155799, #155799)"}}
                                        size="lg" variant="info">Apply
                                    </Button>
                                </div>

                            </div> :

                            <div>
                                Loading...
                            </div>
                }

            </div>
        )
    }   // render ...
}

//
const mapStateToProps = state => {
    return state ;
};

//
export default connect(mapStateToProps, actions)(Loyalty)



