/**
 * @copyright Elmelo Ltd.
 */

import React from 'react';
import{
    Modal,
    // Accordion, Card,
    Button,
    // Form,
    Alert,
    // Image
} from 'react-bootstrap'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp , faSquare , faCheckSquare , faPlusSquare,faCheckCircle,faTimes,faTrashAlt,faCheck,faCircle,faCircleNotch} from '@fortawesome/free-solid-svg-icons'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import color from '../_common/colors.json'
import './css/order.css'
import Hdr from '../_common/hdr'
import{Item_Box} from './_common'
import{ItemPrice} from './ItemPrice'
// import { RdxOrder_AddToCart } from '../../rdx/actions';
// import ONT from './ont'

/**
 */
class Items extends React.PureComponent
{
	/**
	 */
	constructor( props )
	{
        super( props )

		this.state = {
                //
            }
	}

	/**
	 */
	render()
	{
		// console.log( "Items : render : props : ", this.props );

		return(
			<div>
                <>
                {
                    this.props.item._type === "basic"
                ?   <Items_Basic {...this.props}
                        addToCart={this.addToCart}
                    />

                :   this.props.item._type === "mod"
                ?   <Items_Mod {...this.props}
                        addToCart={this.addToCart}
                    />

                :   this.props.item._type === "mod_ex"
                ?   <Items_ModEx {...this.props}
                        addToCart={this.addToCart}
                    />

                :   this.props.item._type === "setmeal"
                ?   <Items_SetMeal {...this.props}
                        addToCart={this.addToCart}
                    />

                :   null
                }
                </>

                <>
				{
					// this.props.__biz.showAllergenMsg
					// ?
					// <div className="bottomMessage">
					// 	<div className="bottomText">
					// 		{this.props.__biz.showAllergenMsg}
					// 	</div>
					// </div>
					// :
					// null
				}
                </>
			</div>
		);
    }	// render ...

    // /**
	//  */
	// componentDidMount()
	// {
	// 	//
	// }

	/**
	 */
	addToCart = ( item, sect, cnt_change ) =>
	{
		this.props.addToCart( item, sect, cnt_change );
	}
}	// Items

/**
 */
class Items_Basic extends React.PureComponent
{
	/**
	 */
	constructor( props )
	{
		super( props );
	}

	// /**
	//  */
	// componentDidMount()
	// {
	// 	//
	// }

	/**
	 */
	addToCart = () =>
	{
		// // console.log( 'Items_Basic: addToCart: 1' )

		// this.props.addToCart( this.props.item, this.props.sect, ++this.props.item._cnt );
		this.props.addToCart( this.props.item, this.props.sect, ++this.props.item._cnt )

		// // console.log( 'Items_Basic: addToCart: 2' )
	}

	/**
	 */
	render()
	{
		return(
			<Item_Box
				count={this.props.item._cnt}
				itemName={this.props.item.name}
				description={this.props.item.desc}
				itemPrice={ItemPrice(this.props.item, this.props.orderType)}
				onPress={this.addToCart}
			/>
			// <div className="singleItem"
			// 	 style={{borderColor:color.colors.borderLight, }}
			// >
			// 	<div className="commonFlex">
			// 		{
			// 			this.props.item._cnt ?
			// 				<FontAwesomeIcon
			// 					style={{margin:"4px 8px"}}
			// 					icon={faCheck} size="md" color="#43A047"/>
			// 				: null
			// 		}
			// 		<div style={{flex: 1}}>
			// 			<div className="commonFlex">
			// 				<div className="itemName">{this.props.item.name}</div>
			// 				{
			// 					this.props.item._cnt ?
			// 						<div className="orderCount">
			// 							{this.props.item._cnt}
			// 						</div> :
			// 						null
			// 				}
			// 			</div>
			// 			<div className="itemDescription">
             //                {this.props.item.desc}
			// 			</div>
			// 		</div>
			// 		<div className="menuRight">
			// 			<div className="itemPrice">£{this.props.item.price.def}
			// 			</div>
			// 			<div className="addtoCart"
			// 				 onClick={this.addToCart}
			// 			>
			// 				<FontAwesomeIcon icon={faPlusSquare} size="lg"
			// 								 color={color.colors.primary}/>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>
		);
	}	// render
}	// Items_Basic

/**
 */
class Items_Mod extends React.PureComponent
{
	/**
	 */
	constructor( props )
	{
		super( props );
	}

	// /**
	//  */
	// componentDidMount()
	// {
	// 	//
	// }

	/**
	 */
	addToCart = (mod) =>
	{
		++mod._cnt;
		++this.props.item._cnt;

		this.props.addToCart( this.props.item, this.props.sect, this.props.item._cnt );
		// this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
	}

	/**
	 */
	render()
	{
		// // console.log("render mod items", this.props)
		const it_p = ItemPrice(this.props.item, this.props.orderType)
		const bPrice = it_p ? it_p : 0

		return(
			<div className="singleItem"
				 style={{borderColor: color.colors.borderLight,}}
			>
				<div className="common">
					<div style={{flex: 1}}>
						<div className="itemName">{this.props.item.name}{bPrice? " ( Base Price £"+ bPrice + ")" : null }</div>
						<div className="itemDescription">
							{this.props.item.Desc}
						</div>
					</div>
				</div>
				<div>
				{
					this.props.item._mods.opts.map((mod, mod_index) => (
						<div
							onClick={ () => this.addToCart(mod) }
							className="common"
								style={{
									backgroundColor:mod._cnt > 0 ? "#ecdfdf" : "inherit",
									borderColor:color.colors.borderLight}}
								key={mod_index}>
							{
								mod._cnt > 0 ?
								<FontAwesomeIcon
									style={{margin:"4px 8px"}}
									icon={faCheck} size="md" color="#43A047"/>
								: null
							}
							<div className="common" style={{flex: 1}}>
								<div className="modItemName">
									{mod.name}
								</div>
								{
									mod._cnt ?
										<div className="orderCount">
											{mod._cnt}
										</div> :
										null
								}

								<div className="itemPrice">{bPrice ? "+" : null}£{ItemPrice(mod, this.props.orderType)}
								</div>
								<div
									// onClick={ () => this.addToCart(mod) }
								>
									<FontAwesomeIcon icon={faPlusSquare}
													 size="lg"
													 color={"#C94B4B"}
									/>
								</div>
							</div>
							{/*<div className="modItemPrice">*/}
							{/*	*/}
							{/*</div>*/}
						</div>
					))
				}
				</div>
			</div>
		);
	}	// render
}	// Items_Mod

/**
 */
class Items_ModEx extends React.PureComponent
{
	/**
	 */
	constructor( props )
	{
		super( props );
		this.state={
			showModEx:false,
		}
	}

	/**
	 */
	componentDidMount()
	{
		//
	}

	/**
	 */
	showModEx = ( val ) =>
	{
		this.setState({showModEx:val});
	}

	/**
	 */
	render()
	{
		const it_p = ItemPrice(this.props.item, this.props.orderType)
		const bPrice = it_p ? it_p : 0

		return(
			<div className="singleItem"
				 style={{backgroundColor:this.props.item._cnt > 0 ? "#ecdfdf" : "inherit",borderColor:color.colors.borderLight, }}
			>
				{
					this.state.showModEx ?
						<ModEx_Modal {...this.props}
									 bPrice={bPrice}
									 showModEx={this.showModEx}/> :
						null
				}
				<div onClick={() => this.showModEx(true)} className="common">
					<div style={{flex: 1}}>
						<div className="common">
							<div className="itemName">{this.props.item.name}</div>
                            {
                                this.props.item._cnt ?
									<div className="orderCount">
										{this.props.item._cnt}
									</div> :
									null
							}
						</div>
						<div className="itemDescription">
							{this.props.item.Desc}
						</div>
					</div>
					<div className="itemPrice">
						{bPrice ? "£"+bPrice : null}
					</div>
					<div className="addtoCart" /*onClick={ () => this.showModEx(true) }*/ >
						<FontAwesomeIcon icon={faArrowUp} size="lg"
										 color={"#C94B4B"}/>
					</div>
				</div>
			</div>
		);
	}	// render
}	// Items_ModEx

/**
 */
class Items_SetMeal extends React.PureComponent
{
	/**
	 */
	constructor( props )
	{
		super( props );
		this.state={
			setMeal:false,
		}
	}

	/**
	 */
	showSetMeal = ( val ) =>
	{
		this.setState( { setMeal: val } )
	}

	/**
	 */
	componentDidMount()
	{
		//
	}

	/**
	 */
	render()
	{
		// // console.log( "Items_SetMeal : render : props : ", this.props );

		return(
			<div>
				{
					this.state.setMeal
						?
						<SetMeal_Modal {...this.props} showSetMeal={this.showSetMeal}/>
						:
						null
				}
				<div className="singleItem"
					 style={{backgroundColor:this.props.item._cnt > 0 ? "#ecdfdf" : "inherit",borderColor: color.colors.borderLight}}
				>
					<div
						className="common"
						// onClick={() => this.showSetMeal(true)}
					>
						<div style={{flex: 1}}>
							<div className="common">
								<div className="itemName">{this.props.item.name}</div>
								{
									this.props.item._cnt ?
										<div className="orderCount">
											{this.props.item._cnt}
										</div> :
										null
								}
							</div>
							<div className="itemDescription">
								{this.props.item.Desc}
							</div>
						</div>

						<div className="itemPrice">£{this.props.item.price.def}
						</div>
						<div className="addtoCart" onClick={() => this.showSetMeal(true)}
						>
							<FontAwesomeIcon icon={faPlusSquare} size="lg"
											 color={"#C94B4B"}/>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

/**
 */
class SetMeal_Modal extends React.PureComponent
{
	/**
	 */
	constructor( props )
	{
		super( props );

		let {item} = this.props.item.setm_items;

		let fixed_items = [];

		item = item.map( ( grp, grpIndex ) => {
			grp.items = grp.items.map( ( cat, catIndex ) => {
				cat.list = cat.list.map( itm => {
						itm.itemCount = 0;
						return itm;
					} );

				if( cat.type === 'fixed' )
				{
					fixed_items.push({
						name: cat.list[0].name,
						grpIdx: grpIndex,
						catIdx: catIndex,
						quantity: cat.maxselect,
						type: 'fixed'
					});
				}

				cat.catCnt = 0;

				return cat;
			});

			grp.grpCnt = 0;

			return grp;
		} );

		const max_item_cnt = item.reduce( (acc_grp, cur_grp) => {
				return acc_grp + cur_grp.maxselect;
			}, 0 );

		// // console.log( "SetMeal_Modal : cstr : max_item_cnt: ", max_item_cnt );

		this.state = {
			total: 0,
			showCatIndex: -1,//0,
			showGrpIndex: -1,
			itemList: fixed_items,//[],
			selectedItemCounter: 0,
			limitReached: max_item_cnt ? false : true,
			showItemList: false,
			maxItemCount: max_item_cnt,//0,
			addPressed:false,
			errorMessage: ""
			// itemSelect:[],
		};
	}

	// /**
	//  */
	// componentDidMount()
	// {
	// 	//
	// }

	/**
	 */
	addSetMealItem = (item, grp_idx, cat_idx, item_idx) =>
	{
		const {itemList, selectedItemCounter} = this.state;
		const items = this.props.item.setm_items;

		const max_grp = items.item[grp_idx].maxselect;
		const max_cat = items.item[grp_idx].items[cat_idx].maxselect;

		const cnt_grp = itemList.reduce( (acc, cur) => {
			return cur.grpIdx === grp_idx
				? acc + cur.quantity
				: acc
				;
		}, 0 );

		const cnt_cat = itemList.reduce( (acc, cur) => {
			return (cur.grpIdx === grp_idx && cur.catIdx === cat_idx)
				? acc + cur.quantity
				: acc
				;
		}, 0 );

		if( cnt_grp === max_grp )
		{
			this.setState( { errorMessage: "Item Limit Reached!" } );

			window.setTimeout(() => {
				this.setState({errorMessage: false})
			}, 1500)
			return ;
		}

		if( cnt_cat === max_cat )
		{
			this.setState( { errorMessage: "Item Limit Reached!" } );

			window.setTimeout(() => {
				this.setState({errorMessage: false})
			}, 1500)
			return ;
		}

		let alreadyExists = -1;
		let newList = [];

		itemList.map((cartItem, index) => {
			const item_name = this.props.bShowCategoryWithItem
				? item.name + " (" + items.item[grp_idx].items[cat_idx].itemtitle + ')'
				: item.name
			;

			if (    cartItem.name === item_name
				&&  cartItem.grpIdx === grp_idx
				&&  cartItem.catIdx === cat_idx
			)
			{
				alreadyExists = index;
			}

			newList.push(cartItem);
		});

		if (alreadyExists !== -1)
		{
			newList[alreadyExists].quantity += 1;
		}
		else
		{
			const temp = {
				grpIdx: grp_idx,
				// parentName: items.item[cat_idx].dish,
				catIdx: cat_idx,
				catName: items.item[grp_idx].cat ? items.item[grp_idx].cat : items.item[grp_idx].dish,
				itemIdx: item_idx,
				// itemName: '',
				quantity: 1,
				item: {...items.item[grp_idx].items[cat_idx].list[item_idx]}
			};

			temp.name = this.props.bShowCategoryWithItem
				? item.name + " (" + items.item[grp_idx].items[cat_idx].itemtitle + ")"
				: item.name
			;

			newList.push(temp);
		}

		++items.item[grp_idx].grpCnt;
		++items.item[grp_idx].items[cat_idx].catCnt;
		++item.itemCount;

		this.setState({
			errorMessage: "Item Added",
			itemList: newList,
			selectedItemCounter: selectedItemCounter + 1
		});
		window.setTimeout(()=>{
			this.setState({errorMessage:false})
		},1500)

		// // console.log( "SetMeal_Modal : addSetMealItem : items: ", items );
		// // console.log( "SetMeal_Modal : addSetMealItem : item: ", item );

		// CHECK IF "ADD TO CART" ACTIVE
		// const itemListCount = newList.reduce( (acc, cur) => acc+cur.quantity, 0 );
		const total_added = items.item.reduce( (a, c) => {return a+c.grpCnt}, 0 )

		// // console.log( "SetMeal_Modal : addSetMealItem : total_added: ", total_added );

		if(total_added === this.state.maxItemCount)
		{
			this.setState({limitReached: true});
		}
		else if( cnt_cat === max_cat )
		{
			const show_next = this.ShowNext( grp_idx, cat_idx+1 );

			this.setState({
				showCatIndex: show_next.cat_idx,
				showGrpIndex: show_next.grp_idx,
			});
		}
		else
		{
		}
	}	// addSetMealItem

	/**
	 */
	addToCart = () =>
	{
		const setm_id = this.state.itemList.map( x => {
                return [x.grpIdx, x.catIdx, x.itemIdx, x.name, x.quantity].join( ':' );
            } ).join(';')

		if( !this.props.item._setm )
			this.props.item._setm = {}

		if( !this.props.item._setm[setm_id] )
		{
			const temp = {
                    title: this.props.item.name,
                    price: this.props.item.price.def,//_price,
                    items: this.state.itemList,
                    type: 'set_meal',
                    _cnt: 0,
                    _id: setm_id,
                }

			this.props.item._setm[setm_id] = temp;
		}

		++this.props.item._setm[setm_id]._cnt;
		++this.props.item._cnt;

		this.props.addToCart( this.props.item, this.props.sect, this.props.item._cnt );
		// this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )

		this.props.showSetMeal(false)
	}

	/**
	 */
	removeItem = ( index, grp_idx, cat_idx, item_idx ) =>
	{
		const items = this.props.item.setm_items;

		// const grp_idx = this.state.itemList.filter((_, i) => i === index)[0].grpIdx;
		// const cat_idx = this.state.itemList.filter((_, i) => i == index)[0].catIdx;
		// const item_idx = this.state.itemList.filter((_, i) => i == index)[0].itemIdx;

		const itemQuantity = this.state.itemList.filter((_, i) => i == index)[0].quantity;

		items.item[grp_idx].grpCnt -= itemQuantity;
		items.item[grp_idx].items[cat_idx].catCnt -= itemQuantity;
		items.item[grp_idx].items[cat_idx].list[item_idx].itemCount -= itemQuantity;

		this.setState({
			itemList: this.state.itemList.filter((_, i) => i !== index),
			selectedItemCounter: this.state.selectedItemCounter - itemQuantity,
			limitReached: false
		});
	}

	/**
	 */
	renderGrpItems = ( grpItems, grpIndex, grpMaxselect, grpCnt ) =>
	{
		return(
			// null
			<div className="common">
				<div className="setMealSubMenu">
				{
					grpItems.map((catObj, catIndex) => (
						<div key={catIndex} style={{flex: 1}}>
						{
							catObj.maxselect === catObj.catCnt && grpMaxselect === grpCnt
						?	null

						:	<div>
								<div onClick={() => this.setState({showCatIndex: catIndex})}>
								{
									this.state.showCatIndex === catIndex
								?	<div className=" activeSetMeal itemCatHeaderMini ">
									{catObj.itemtitle}
									{
										catObj.maxselect === 0
									?	<div className="itemDescription">
											( Selected by dafault )
										</div>

									:	<div className="itemDescription">
											( Max select {catObj.maxselect} )
										</div>
									}
									</div>

								:	<div className="itemCatHeaderMini">
									{catObj.itemtitle}
									{
										catObj.maxselect === 0
									?	<div className="itemDescription">
											( Selected by dafault )
										</div>

									:	<div className="itemDescription">
											( Max select {catObj.maxselect} )
										</div>
									}
									</div>
								}
								</div>
							</div>
						}
						</div>
					))
				}
				</div>
				<div style={{flex: 1}}>
				{
					grpItems.map((catObj, catIndex) => (
						<div key={catIndex} style={{flex: 1}}>
						{
							catObj.maxselect === catObj.catCnt && grpMaxselect === grpCnt
						?	null
						:	<div>
							{
								this.state.showCatIndex === catIndex
							?	this.renderCatItems(catObj.list, grpIndex, catIndex)
							:	null
							}
							</div>
						}
						</div>
					))
				}
				</div>
			</div>
		)
	}

	/**
	 */
	renderCatItems = ( listItems, grpIndex, catIndex ) =>
	{
		return(
			listItems.map( ( item, itemIndex ) => (
				<div key={itemIndex}  style={{paddingLeft: 16}}
					 onClick={() => this.addSetMealItem(item, grpIndex, catIndex, itemIndex)}
				>
				{
					item.itemCount
				?	<div className="setMealItemSelect">
						<FontAwesomeIcon
							style={{marginTop: 14,marginLeft:8}}
							icon={faCheckCircle} size="md" color="#43A047"/>
						<div className="common">
							<div className="setMealItem">
								{item.name}
							</div>
							<div className="orderCount"> {item.itemCount}</div>
						</div>
					</div>

				:	<div className="setMealItem">{item.name}</div>
				}
				</div>
			) )
		)
	}

	/**
	 */
	renderAddedItems = () =>
	{
		return (
			this.state.itemList.map( ( item, itemIndex ) => (

				<div className="addressSelect" style={{display: "flex", flexDirection: "row"}} key={itemIndex} >
					<div style={{color: color.colors.light, marginLeft: 8}}>
						{item.quantity}
					</div>
					<FontAwesomeIcon
						style={{marginLeft: 8, marginRight: 8}}
						icon={faTimes} size="lg" color="#C4C4C4"/>
					<div style={{color: color.colors.light}}>
						{item.name}({item.catName})
					</div>
					{
						item.type && item.type === "fixed"?
							null
							:

							<div style={{
								marginLeft: "auto",
							}}
								 onClick={() => this.removeItem( itemIndex, item.grpIdx, item.catIdx, item.itemIdx )}
							>
								<FontAwesomeIcon
									style={{marginRight: 8}}
									icon={faTrashAlt} size="lg" color="#C4C4C4"/>
							</div>

					}
				</div>
			) )
		)
	}

	/**
	 */
	render()
	{
		// // console.log( "SetMeal_Modal : render : props : ", this.props );
		// // console.log( "SetMeal_Modal : render : state : ", this.state );

		let {item} = this.props;

		return(
			<div>
				<Modal
					size="lg"
					show={true}
					onHide={() => this.props.showSetMeal(false)}
					aria-labelledby="example-modal-sizes-title-lg"
				>
					<Modal.Header closeButton>
						<div>
							<div className="common" style={{flex:1}}>
								<div className="itemName">
									{item.name}
								</div>
								<div className="itemName" style={{marginLeft: 8}}> ( £{item.price.def} )</div>
							</div>
							<div className="infoDescription">
								{item.Desc}
							</div>
						</div>
					</Modal.Header>

					<Modal.Body>
						<div style={{marginBottom:16}}>
							{this.renderAddedItems()}
						</div>

						<div>
						{
							item.setm_items.item.map((grpObj, grpIndex) => {
								grpObj.maxselect = grpObj.maxselect ? grpObj.maxselect : 0;

								return (
									<div key={grpIndex}>
									{
										grpObj.maxselect === 0 || grpObj.maxselect === grpObj.grpCnt
									?
										<div className="common selectedSetMeal">
											<FontAwesomeIcon style={{marginRight: 8}} icon={faCheckCircle}
												color="#C94B4B" size="lg"/>
											<h6>
											{ grpObj.dish }
											{
												grpObj.maxselect === 0
											?	<sup className="itemDescription" style={{color: "#c94b4b"}}>(Selected
													By Default)</sup>

											:	<sup className="itemDescription" style={{color: "#c94b4b"}}>(Selected {grpObj.grpCnt})</sup>
											}
											</h6>
										</div>
									:
										<div onClick={() => this.setState({showGrpIndex: grpIndex})}
												className="itemCatHeader">
											{ grpObj.dish }
											<sup className="itemDescription">
												( Select {grpObj.maxselect}from this category. )
											</sup>
										</div>
									}
									{
										this.state.showGrpIndex === grpIndex && grpObj.maxselect !== grpObj.grpCnt
									?	this.renderGrpItems(grpObj.items, grpIndex, grpObj.maxselect, grpObj.grpCnt)

									:	null
									}
									</div>
								)
							})
						}
						</div>

						<div>
						{
							this.state.limitReached
						?	<div className="buttonPadding">
								<Button
									onClick={ () => this.addToCart() }
									variant="success" style={{width: "100%"}}>
									Add to Cart
								</Button>
							</div>

						:	<div className="buttonPadding">
								<Button variant="danger" style={{width: "100%", cursor: "no-drop"}}>
									Add to Cart
								</Button>
							</div>
						}
						</div>
						<div>
							{this.state.limitReached
							? null
								:
								<div className="setMealWarn">
									*** Fullfill the criteria to active order button
								</div>
							}
						</div>
					</Modal.Body>
				</Modal>

				<div style={{position:"absolute", right:16,top:30,zIndex:11111}}>
				{
					this.state.errorMessage
				?	<React.Fragment>
						<Alert variant= {this.state.errorMessage === "Item Added" ? "success" : "danger"}>
							{this.state.errorMessage}
						</Alert>
					</React.Fragment>

				:	null
				}
				</div>
			</div>
		)
	}	// render
}	// Items_SetMeal

/**
 */
class ModEx_Modal extends React.PureComponent
{
	/**
	 */
	constructor(props)
	{
		super(props)

		this.state = {
                bCartBtnShow: false

                , selMod: null
                , selSecMods: {}
                , selAddons: {}

                , itemSelect: [],
            }

		this.m_p = 0
		this.s_m_p = 0
		this.a_p = 0
	}

	// /**
	//  */
	// componentDidMount()
	// {
	// 	//
	// }

	/**
	 */
	updateCartBtn = () =>
	{
		// console.log( "Items : updateCartBtn : props : ", this.props );

		let chkMod = false;
		let chkSecMod = false;
		let chkAddon = false;

		if( !this.props.item._mods )
		{
			chkMod = true;
		}

		if( this.props.item._mods && this.state.selMod )
		{
			chkMod = true;
		}

		if( this.props.item._secmods && this.props.item._secmods.length === Object.keys(this.state.selSecMods).length /*Object.keys(this.state.selSecMods).length &&*//*&& Object.keys(this.state.selAddons).length*/ )
		{
			chkSecMod = true;
		}

		if( !this.props.item._mods && !this.props.item._secmods )
		{
			chkAddon = true;
		}

		if( (chkMod && chkSecMod) || chkAddon )
			this.setState( { bCartBtnShow: true } );
	}

	/**
	 */
	OnPress_Mod = ( x, idx, parent ) =>
	{
		this.m_p = ItemPrice(x, this.props.orderType)
		this.setState( {selMod: {_id: parent._id, idx: idx, _cnt: 1, obj: x, parent: parent, str: ""} } );
	}   // OnPress_Mod

	/**
	 */
	OnPress_SecMod = ( x, idx, parent ) =>
	{
		let sel_sec_mods = {...this.state.selSecMods};

		// // console.log( 'OnPress_SecMod: parent: ', sel_sec_mods );


		sel_sec_mods[parent._id] = {_id: x._id, idx: idx, _cnt: 1, obj: x, parent: parent};

		// console.log(sel_sec_mods);


		this.s_m_p = Object.keys(sel_sec_mods).reduce( (acc, cur) => {

			return acc + ItemPrice(sel_sec_mods[cur].obj, this.props.orderType);

		}, 0 );


		this.setState( {selSecMods: sel_sec_mods} );


	}   // OnPress_SecMod

	/**
	 */
	OnPress_Addon = ( x, idx ) =>
	{
		let sel_addons = {...this.state.selAddons};

		if(sel_addons[x._id])
		{
			delete sel_addons[x._id]
			this.a_p -= ItemPrice(x, this.props.orderType)
		}
		else
		{
			sel_addons[x._id] = {_id: x._id, idx: idx, _cnt: 1, obj: x}
			this.a_p += ItemPrice(x, this.props.orderType)

		}
		this.setState( {selAddons: sel_addons} );
	}   // OnPress_Addon

	/**
	 */
	addToCart = () =>
	{
        const {item} = this.props;

        // const item_price = this.state.selMod ? [] : [{  }];

		// get mod array
		const arr_mods = this.state.selMod ? [ {...this.state.selMod} ] : [];

		// get sec_mod array
		let arr_secmods = Object.keys(this.state.selSecMods).reduce( (acc, cur_k) => {
			return [...acc, {...this.state.selSecMods[cur_k]}];
		}, [] );

		arr_secmods.sort( (a, b) => a.idx - b.idx );

		// get addon array
		let arr_addons = Object.keys(this.state.selAddons).reduce( (acc, cur_k) => {
			return [...acc , {...this.state.selAddons[cur_k]}];
		}, [] );

		arr_addons.sort( (a, b) => a.idx - b.idx );

		// create opt_obj id
		const arr_opts_obj = [...arr_mods, ...arr_secmods, ...arr_addons];

		// // console.log( "arr_opts_obj : ", arr_opts_obj );

		const arr_opts_str = arr_opts_obj.map( x => {

			let objStr = Object.keys(x.obj).map( objKey => {
				const _objObj = x.obj[objKey];
				return _objObj.name
			} );

			objStr = objStr.sort();
			objStr = objStr.join(':');

			return x._id + ':' + x.idx + ':' + x._cnt + ':' + objStr;
		} );

		// // console.log( "arr_opts_str : ", arr_opts_str );

		const opt_id = arr_opts_str.join(';');

		// // console.log( "opt_id : ", opt_id );

		// add to _opts array
		if( !this.props.item._opts )
		{
			this.props.item._opts = {};
		}

		if( !this.props.item._opts[opt_id] )
		{
			this.props.item._opts[opt_id] = {
					_cnt: 0
				,   _id: opt_id
				,   selMod: arr_mods
				,   selSecMods: arr_secmods
				,   selAddons: arr_addons
			};
		}

		++this.props.item._opts[opt_id]._cnt;
		++this.props.item._cnt;

		// // console.log( 'ModEx_Modal : addToCart : this.props.item : ', this.props.item );

		this.props.addToCart( this.props.item, this.props.sect, this.props.item._cnt );
		// this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )

		this.setState( {selMod: null, selSecMods: [], selAddons: []} );
		this.props.showModEx(false);
	}

	/**
	 */
	render()
	{
		this.updateCartBtn();   // @todo make this thing right by taking it outside render.

		// if(this.state.selMod && this.state.selMod.idx)
		// 	// console.log( "modIdx : ", this.state.selMod.idx );

		const {item} = this.props;

		// // console.log("modex item", item)

		const tot_price = this.props.bPrice + this.m_p +this.s_m_p + this.a_p

		return(
			<Modal
				size="xl"
				show={true}
				onHide={() => this.props.showModEx(false)}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
					<div>
						<div className="common">
							<div className="itemName" >
								{item.name}
							</div>
							{
								tot_price ?
									<div className="itemName" style={{marginLeft:8}}>
										( £{parseFloat(tot_price).toFixed(2)} )
									</div> : null

							}

						</div>
						<div className="infoDescription">
							{item.Desc}
						</div>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div>
						{
							item._mods
								?
								<React.Fragment>
									<div className="itemCatHeader">Primary Modifier <sup className="itemDescription">( Must Choose 1 )</sup></div>
									<div className="itemElements">
										{
											item._mods.opts.map( ( mod, modIdx ) => (

													<div key={modIdx} onClick={() => this.OnPress_Mod(mod, modIdx, this.props.item._mods, true)}>
														{
															this.state.selMod && this.state.selMod.idx === modIdx ?
																<div className="addressSelect"
																     style={{
																	     color: color.colors.black,
																	     fontWeight: 700,
																	     // border: "1px solid",
																	     // borderRadius: 10,
																	     // borderColor: color.colors.primary
																     }}>
																	<FontAwesomeIcon
																		style={{marginRight: 8}}
																		icon={faCheckCircle} size="lg" color="#43A047"/>
																	{mod.name}
																	{" (+£"+ItemPrice(mod , this.props.orderType) + ")" }

																</div>
																:
																<div className="addressSelect" style={{color: color.colors.light}}>
																	<FontAwesomeIcon
																		style={{marginRight: 8}}
																		icon={faCircle} size="lg" color="#f3f3f3"/>
																	{mod.name}
																	{" (+£"+ItemPrice(mod , this.props.orderType) + ")" }

																</div>
														}
													</div>

											))
										}
									</div>
								</React.Fragment>
								:
								null
						}
						{
							item._secmods && item._secmods.length
						?	<React.Fragment>
							{
								item._secmods.map((sec_mod, sec_mod_idx) => (
									<React.Fragment key={sec_mod._id}>
										<div className="itemCatHeader">{sec_mod.name} <sup className="itemDescription">( Must Choose 1 )</sup></div>
										<div className="itemElements">
										{
											sec_mod.opts.map((option, optionIdx) => {
												return (
													<div key={option.name}
															onClick={() => this.OnPress_SecMod(option, optionIdx, sec_mod)}>
														{
															this.state.selSecMods[sec_mod._id] && this.state.selSecMods[sec_mod._id]._id === option._id ?
																<div className="addressSelect"
																		style={{
																			color: color.colors.black,
																			fontWeight: 700,
																		}}>
																	<FontAwesomeIcon
																		style={{marginRight: 8}}
																		icon={faCheckCircle} size="lg"
																		color="#43A047"/>
																	{option.name}
																	{" (+£"+ItemPrice(option, this.props.orderType) +")" }


																</div>
																:
																<div className="addressSelect"
																	 style={{color: color.colors.light}}>
																	<FontAwesomeIcon
																		style={{marginRight: 8}}
																		icon={faCircle} size="lg" color="#f3f3f3"/>
																	{option.name}
																	{" (+£"+ItemPrice(option, this.props.orderType) +")" }

																</div>
														}
													</div>
												)
											})
										}
										</div>
									</React.Fragment>
								))
							}
							</React.Fragment>

						:	null
						}
						{
							item._addons && item._addons.length
						?	<React.Fragment>
							{
								item._addons.map((addon, addonIdx) => (
									<React.Fragment key={addon.name}>
										<div className="itemCatHeader">{addon.name} <sup className="itemDescription">( Choose Multiple )</sup></div>
										<div className="itemElements">
											{
												addon.opts.map((option, optionIdx) => {
													return(
														<div key={option.name} onClick={() => this.OnPress_Addon(option, optionIdx)}>
														{
															this.state.selAddons[option._id]
																?
																<div className="addressSelect"
																		style={{
																			marginTop:8,
																			marginBottom:8,
																			color: color.colors.black,
																			fontWeight: 700,
																			border: "1px solid",
																			borderRadius: 10,
																			borderColor: color.colors.primary
																		}}>
																	<FontAwesomeIcon
																		style={{marginRight: 8}}
																		icon={faCheckSquare} size="lg" color="#43A047"/>
																	{option.name}
																	{" (+£"+ItemPrice(option, this.props.orderType) +")" }

																</div>
																:
																<div className="addressSelect"
																	 style={{color: color.colors.light}}>
																	<FontAwesomeIcon
																		style={{marginRight: 8}}
																		icon={faSquare} size="lg" color="#f3f3f3"/>
																	{option.name}
																	{" (+£"+ItemPrice(option, this.props.orderType) +")" }

																</div>
														}
														</div>
													)
												})
											}
										</div>
									</React.Fragment>
								))
							}
							</React.Fragment>

						:	null
						}
					</div>
					{
						this.state.bCartBtnShow
					?	<div className="buttonPadding">
							<Button
								onClick={ () => this.addToCart() }
								variant="success" style={{width: "100%"}}>
								Add to Cart
							</Button>
						</div>

					:	<div className="buttonPadding">
							<Button variant="danger" style={{width: "100%", cursor: "no-drop"}}>
								Add to Cart
							</Button>
						</div>
					}
				</Modal.Body>
			</Modal>
		)
	}	// render

}	// ModEx_Modal

/**
 */
const mapStateToProps = state =>
{
	return state;
}

//
export default connect(mapStateToProps, actions)(Items);

// export default Items

