/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */

export default ( state = {
        info: {
                title: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            },
        delivery: {
                deliveryType: '',
                deliveryAddress: '',
                validAddress: true //todo - will be false
            },
        payment: {
                paymentType: '',
			    creditInfo: '',
            },
        charges: {
                subtotal: { _idx: 0, type: 'subtotal', name: 'Subtotal', amount: 0.0 },
                elmelo: { _idx: 1, type: 'histreet', name: 'Admin fees', amount: 0.5 },
                admin: { _idx: 2, type: 'admin', name: 'Admin fees', amount: 0.0 },
                delivery: { _idx: 3, type: 'delivery', name: 'Delivery fees', amount: 0.0 },
                collection: { _idx: 4, type: 'collection', name: 'Collection fees', amount: 0.0 },
                card: { _idx: 5, type: 'card', name: 'Internation card issuer', amount: 0.0 },
            },
        discounts: {
                generic: [],
                loyalty: [],
                coupon: [],
                combo: [],
                influencer: [],
            },
        type: '',
        addr: '',
        tsUpd: 0,


    }, action ) =>
{

    switch( action.type )
    {
        case 'checkout:set-subtotal':
            return {...state, charges:{...state.charges,
                    subtotal:{ _idx: 0, type: 'subtotal', name: 'Subtotal', amount: action.payload } }}

        case 'checkout:set-payment':
            if(action.payload.paymentType === 'cash')
                state.payment.creditInfo = ''
            return {...state, payment: {...state.payment , ...action.payload} }

        case 'checkout:set-charges':
            return {...state, charges: action.payload }

        case 'checkout:set-discounts':
            return {...state, discounts: action.payload }

        case 'checkout:set-user-info':
            return {...state, info: {...state.info,...action.payload} }

        case 'checkout:update-user-info':
            return {...state, info: {...state.info, ...action.payload} }

        case 'checkout:set-histreet-charge':
            return {...state, charges: {...state.charges,
                    elmelo:{ _idx: 1, type: 'histreet', name: 'Admin fees', amount: action.payload }} }


        default:
            return state

    }   // switch ...
}







