/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
export default ( state = {
    menu: null,
    menu_prep: null,
    fullMenu: null,
    bLoading: true,

}, action ) =>
{
    switch( action.type )
    {
    case 'menu:load':
        return { ...state, ...action.payload  }

    case 'menu:b_loading':
        return { ...state, bLoading:action.payload  }

    default:
        return state
    }   // switch ...
}




