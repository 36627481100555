/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    // Container,
    Form,
    Button, Alert
} from 'react-bootstrap'
import './css/auth.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { navigate } from "@reach/router"
import {Core} from "../../api";
import {TextInput} from "../_common/component"
// import elml_cfg from '../../_config/elml_cfg'

import Countdown from 'react-countdown';
/**
 */
class AuthVerification extends React.PureComponent
{
    constructor( props )
    {
        super( props )
        this.state ={
            code : "",
            errMsg : "",
            v_success: false,
            resendButton: false,
            resendWaiting: true
        }
    }

    /**
     */
    render()
    {

        // if( !this.props.location.state )
        //     navigate('signin');

        if(this.state.v_success)
        {
            return(
                <div>
                    <div className="authContainer">
                        <p style={{color:"green"}}>Congratulations! You have signed up successfully </p>
                        <div
                            className="authLittle">
                            <i onClick={() => navigate("signin")} variant="light" className="highlight"
                               style={{color: color.colors.primary}}>Click here</i>To Sign In.
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div>
                    <div className="authTitle">
                        Verification Process
                    </div>
                    <div className="authContainer">
                        <div onClick={()=> navigate('signin') } className="buttonAuthDiv">
                            <FontAwesomeIcon
                                className="buttonAuth"
                                icon={faTimes}
                                size="lg"
                                color={"red"}/>
                        </div>
                        <Form.Group>
                            <div className="authLittle">
                                We have sent an <i className="highlight">HISTREET</i> code to your mobile. Please Enter the Code to verify your account.
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <TextInput
                                placeholder="Enter Code"
                                type="number"
                                size="lg"
                                val={this.state.code}
                                onChange={(val)=>this.setState({code: val.target.value })}
                                className="numberHide"
                            />
                        </Form.Group>
                        {
                            this.state.errMsg?
                                <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                    {this.state.errMsg}
                                </Alert> : null
                        }
                        <Form.Group>
                            <Button
                                onClick={this.ConfirmUser}
                                style={{width:"100%",
                                    background: "linear-gradient(to right, #155799, #466099)",
                                    display: "flex", justifyContent: "center"
                                }}
                                size="lg" variant="info">Submit Code
                            </Button>
                        </Form.Group>
                        {
                            this.state.resendButton
                                ?
                                <Form.Group>
                                    {/* <div
                                        className="authLittle">
                                        <i onClick={this.ResendCode} variant="light" className="highlight"
                                        style={{color: color.colors.primary}}>Resend Code</i>
                                    </div> */}
                                    <Button className="resendButton" style={{backgroundColor:"#155799"}} onClick={this.ResendCode} >Resend Code</Button>

                                </Form.Group>
                                :
                                this.state.resendWaiting
                                    ?
                                    <Form.Group>
                                        <div
                                            className="authLittle">
                                            <i variant="light" className="highlight"
                                               style={{color: "#b7b7b7"}}> Press resend button if you do not receive the verification code within
                                            </i>
                                        </div>
                                        <div className="highlight" style={{color: "#155799",textAlign:"center"}}>
                                            <Countdown
                                                date={Date.now() + 120000}
                                                onComplete={ () => {this.setState({resendWaiting: false, resendButton: true})} }
                                            />,
                                        </div>
                                        <Button className="resendButton" disabled variant="secondary">Resend</Button>
                                        <div className="authLittle"> **Resend button is disable now. It will be active after the timer ends. </div>
                                    </Form.Group>
                                    :
                                    <Form.Group>
                                        <div
                                            className="authLittle">
                                            <i variant="light" className="highlight"
                                               style={{color: color.colors.primary}}>Sending Code</i>
                                        </div>
                                    </Form.Group>
                        }

                    </div>
                </div>
            </div>
        )
    }

    /**
     */
    ConfirmUser = async () =>
    {
        try
        {
            if(!this.state.code)
            {
                this.setState({errMsg: "Please enter the verification code" });
                return;
            }

            const aws_core = new Core( {} )

            const {code} = this.state;

            let user_name = "";

            // if( this.props.location.state )
            user_name = 'usr_' + this.props.location.state.cn_code.replace(/[+]/g, '') + this.props.location.state.phone_no ;

            // const current_user = await aws_core.currentuser();

            // if( current_user )
            // {
            //     //
            // }

            const data = await aws_core.SignUp_Confirm({username: user_name, auth_code: code});

            if(data === "SUCCESS")
            {
                this.setState({v_success: true})
            }
            else
            {
                console.error( "Verification : ConfirmUser : data : Not SUCCESS" );
            }

        }
        catch( err )
        {
            console.warn( 'SignUp: ConfirmUser: err: ', JSON.stringify(err) );

            this.setState({errMsg:err.message });

            // return Promise.reject( err )
        }
    }

    /**
     */
    ResendCode = async () =>
    {
        try
        {
            this.setState( { resendButton: false } );
            const aws_core = new Core( {} );

            let user_name = "";

            // if( this.props.location.state )
            user_name = 'usr_' + this.props.location.state.cn_code.replace("+", '') + this.props.location.state.phone_no ;

            // const current_user = await aws_core.currentuser()

            const resp_resend = await aws_core.SignUp_Resend({ username: user_name })

            if( resp_resend )
            {
                this.setState( { resendButton: false, resendWaiting: true } );
            }
            else
            {
                console.warn( "Verification : ResendCode : resp_resend failed." );
            }

            return {}
        }
        catch (err)
        {
            console.warn( 'Auth_Verification: ResendCode: err: ', JSON.stringify(err) );

            this.setState({ errMsg:err.message ? err.message : err });
        }
    }

    /**
     */
    Init = async () =>
    {
        try
        {
            const aws_core = new Core({})

            const current_cred = await aws_core.Credentials()

            if(current_cred.authenticated)
            {
                const current_user = await aws_core.currentuser()

                if(current_user.attributes && current_user.attributes.phone_number_verified )
                {
                    if( this.props.location.state.fromPage )
                    {
                        navigate(this.props.location.state.fromPage);
                    }
                    else
                    {
                        navigate('order')
                    }
                }
                else
                {
                    //
                }
            }
            else
            {
                if( !this.props.location.state )
                {
                    navigate('signin');
                    return;
                }
            }

            if( this.props.location.state.fromPage && this.props.location.state.fromPage === "signup" )
            {
                this.setState( { resendButton: false, resendWaiting: true } );
                return;
            }
            else
            {
                const user_name = 'usr_' + this.props.location.state.cn_code.replace("+", '') + this.props.location.state.phone_no;

                const resp_resend = await aws_core.SignUp_Resend({ username: user_name });

                if( resp_resend )
                {
                    this.setState( { resendButton: false, resendWaiting: true } );
                }
                else
                {
                    console.warn( "Verification : Init : resp_resend failed." );
                }

            }
            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'Verification: Init: err: ', JSON.stringify(err) );

            // this.setState( {errMsg: 'Please check your internet or reload the page.'} )
            this.setState({errMsg:err.message ? err.message : err});

            // this.setState( {showing: 'signup'} )

            return Promise.reject( err )
        }
    }

    /**
     */
    componentDidMount()
    {
        this.Init()
            .then()
            .catch()
    }
}  // class Auth_Verification

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( AuthVerification )


