/**
 *	@copyright	Elmelo Ltd.
 */

import {AStorage} from '../../api/Utils'

import * as Utils from './_utils'
import { ItemPrice } from '../../components/order/ItemPrice'
import * as Checkout from "./checkout";

/**
 */
export const RdxCart_Update = (item, sect, cnt_change) =>
{
    return ( dispatch, gs ) =>
    {
		// add to cart
		let cart_obj = gs().__cart.obj, cart_sects = gs().__cart.sects

        if( !cart_obj[sect.cat_id] )
        {
            cart_obj[sect.cat_id] = {
                    cat_id: sect.cat_id
                ,	cat_idx: sect.cat_idx
                ,	cat: sect.cat
                ,	data: []
                ,	data_obj: {}
                };

            // add to array
            cart_sects.push( cart_obj[sect.cat_id] );
            // sort
            cart_sects.sort( (a, b) => (a.cat_idx-b.cat_idx) );
        }

        if( !cart_obj[sect.cat_id].data_obj[item._id] )
        {
            cart_obj[sect.cat_id].data_obj[item._id] = item;
            cart_obj[sect.cat_id].data.push( item );
        }

        // check for zeros in cart items
        if( !item._cnt  )
        {
            const idx_item = cart_obj[sect.cat_id].data.findIndex(function (_item) {
                    return item._id === _item._id;
                });
            cart_obj[sect.cat_id].data.splice( idx_item,1);


            delete cart_obj[sect.cat_id].data_obj[item._id];

            if(!cart_obj[sect.cat_id].data.length)
            {
                delete cart_obj[sect.cat_id]
            }

            cart_sects = cart_sects.filter( x => x.data.length > 0 );

            // if( !cart_obj[sect.cat_id].data.length )
            // if( Object.entries(cart_obj[sect.cat_id].data_obj).length === 0 )
            // {
            //     // console.log("adsada 1");
            //
            //     delete cart_obj[sect.cat_id];
            // }
        }

        if(item._type === "mod_ex")
        {
            if(item._opts)
            {
                Object.keys(item._opts).map((key, idx) => {
                        const opt_obj = item._opts[key];
                        if (!opt_obj._cnt) {

                            delete item._opts[key];
                        }
                    });
            }
        }

        if(item._type === "setmeal")
        {
            if(item._setm)
            {
                Object.keys(item._setm).map( (key, idx) => {
                        const opt_obj = item._setm[key];
                        if (!opt_obj._cnt)
                        {
                            delete item._setm[key];
                        }
                    } )
            }
        }

        // sort out items in cart
        if( cart_obj[sect.cat_id] )
        {
            cart_obj[sect.cat_id].data.sort( (a, b) => {
                // if (a.Cat_Idx < b.Cat_Idx)
                // 	return -1;
                // else if (a.Cat_Idx > b.Cat_Idx)
                // 	return 1;
                // else if (a.Sub_Cat_Idx < b.Sub_Cat_Idx)
                // 	return -1;
                // else if (a.Sub_Cat_Idx > b.Sub_Cat_Idx)
                // 	return 1;
                if (a.idx < b.idx)
                    return -1;
                else if (a.idx > b.idx)
                    return 1;
                else if (a._v_idx < b._v_idx)
                    return -1;
                else if (a._v_idx > b._v_idx)
                    return 1;
                else
                    return 0;
            } )
        }

        // // console.log( "Rdx_Order : OnCnt : cart_Obj : ", cart_obj );
        // console.log( "Rdx_Order : OnCnt : cart_sects : ", cart_sects );

        dispatch( Cart_Load( cart_obj, cart_sects ) )

        dispatch( Cart_LocalSet({
            cart_sects: [...cart_sects]
        }));

        return {}
    }   // ...

	// return {
	// 	type: 'order:cart-upd',
	// 	payload: {item, sect, cnt_change},
	// }
}   // RdxCart_Update

/**
 */
export const RdxCart_SwitchType = ( order_type ) =>
{
    return (dispatch, gs) =>
    {
        try
        {
            let cart_obj = gs().__cart.obj, cart_sects = gs().__cart.sects
            // let total_cnt = gs().__cart.totalCnt, total_price = gs().__cart.totalPrice

            // console.log( 'actions/cart: RdxCart_SwitchType: cart_obj: ', cart_obj )
            // console.log( 'actions/cart: RdxCart_SwitchType: cart_sects: ', cart_sects )
            // console.log( 'actions/cart: RdxCart_SwitchType: total_cnt: ', total_cnt )
            // console.log( 'actions/cart: RdxCart_SwitchType: total_price: ', total_price )

            // console.log( 'actions/cart: RdxCart_SwitchType: gs().__menu: ', gs().__menu )

            const menu_prep = gs().__menu.menu_prep
            // const order_type = gs().__order.type

            // console.log( 'actions/cart: RdxCart_SwitchType: menu_prep: ', menu_prep )
            // console.log( 'actions/cart: RdxCart_SwitchType: order_type: ', order_type )

            //
            cart_sects = cart_sects.map( sect => {
                sect.data = sect.data.map( item => {
                        // console.log( 'actions/cart: RdxCart_SwitchType: item: ', item )

                        const item_tmp = menu_prep.items[item.id]

                        // console.log( 'actions/cart: RdxCart_SwitchType: item_tmp: ', item_tmp )

                        if( 'basic' === item.type )
                        {
                            // console.log( 'actions/cart: RdxCart_SwitchType: basic: item.price (b): ', item.price )

                            item.price = ItemPrice( item_tmp, order_type )

                            // console.log( 'actions/cart: RdxCart_SwitchType: basic: item.price (a): ', item.price )
                            // item.bVis =
                        }
                        else if( 'mod' === item.type )
                        {
                            // console.log( 'actions/cart: RdxCart_SwitchType: mod: item.price: ', item.price )

                            item.price = ItemPrice(item_tmp, order_type)

                            item.opts = Object.keys( item.opts ).map( k => {
                                    const opt_cart = item.opts[k]
                                    // const opt_cart = item.opts[k]

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod: opt: opt_cart: ', opt_cart )

                                    const opt_idx = item_tmp._mods.opts.findIndex( (x, idx_o) => x._id ? x._id === opt_cart.id : idx_o === opt_cart.id )

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod: opt: opt_idx: ', opt_idx )

                                    if( 0 > opt_idx )
                                    {
                                        return opt_cart
                                    }

                                    const opt_item = item_tmp._mods.opts[opt_idx]

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod: opt_item: ', opt_item )

                                    opt_cart.price = ItemPrice(opt_item, order_type)

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod: opt_item: ', opt_item )

                                    return opt_cart
                                } )

                            // console.log( 'actions/cart: RdxCart_SwitchType: mod: item: ', item )
                        }
                        else if( 'mod_ex' === item.type )
                        {
                            // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: item.price: ', item.price )

                            item.price = ItemPrice(item_tmp, order_type)

                            // item.opts = item.opts.map( opt_cart => {
                            item.opts = Object.keys( item.opts ).map( k => {
                                    const opt_cart = item.opts[k]

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: opt: opt_cart: ', opt_cart )

                                    const price_mods = opt_cart.mods.reduce( (a, c) => {
                                            a += ItemPrice( c.obj, order_type )

                                            return a
                                        }, 0 )

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: opt: price_mods: ', price_mods )

                                    const price_secmods = opt_cart.sec_mods.reduce( (a, c) => {
                                            a += ItemPrice( c.obj, order_type )

                                            return a
                                        }, 0 )

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: opt: price_secmods: ', price_secmods )

                                    const price_addons = opt_cart.addons.reduce( (a, c) => {
                                            a += ItemPrice( c.obj, order_type )

                                            return a
                                        }, 0 )

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: opt: price_addons: ', price_addons )

                                    // opt_cart.price = ItemPrice(opt_item, order_type)
                                    opt_cart.price = price_mods + price_secmods + price_addons

                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: opt_cart: ', opt_cart )

                                    return opt_cart
                                } )
                        }
                        else if( 'setmeal' === item.type )
                        {
                            item.price = ItemPrice(item_tmp, order_type)
                        }
                        else
                        {
                        }

                        return item
                    } )

                return sect
            } )

        // console.log( 'actions/cart: RdxCart_SwitchType: ', 'Total =>' )

        // get total
        const total_obj = cart_sects.reduce( (a_sect, sect) => {
                return sect.data.reduce( (a, item) => {
                        // console.log( 'actions/cart: RdxCart_SwitchType: total: item: ', item )

                        if( 'basic' === item.type )
                        {
                            a.cnt += item.cnt
                            a.price += (item.price * item.cnt)
                        }
                        else if( 'mod' === item.type )
                        {
                            a.cnt += item.cnt

                            a.price += item.opts.reduce( (a_opt, opt) => {
                                    return (item.price + opt.price + a_opt) * opt.cnt
                                }, 0 )

                            // console.log( 'actions/cart: RdxCart_SwitchType: mod: a.price: ', a.price )
                        }
                        else if( 'mod_ex' === item.type )
                        {
                            a.cnt += item.cnt

                            // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: a.cnt: ', a.cnt )

                            a.price += Object.keys( item.opts ).reduce( (a_opt, k) => {
                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: k: ', k )
                                    const opt = item.opts[k]
                                    // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: opt: ', opt )
                                    return (item.price + opt.price + a_opt).opt.cnt
                                }, 0 )

                            // console.log( 'actions/cart: RdxCart_SwitchType: mod_ex: a.price: ', a.price )
                        }
                        else if( 'setmeal' === item.type )
                        {
                            a.cnt += item.cnt
                            a.price += item.price * item.cnt
                        }
                        else
                        {
                            a.cnt += 0
                            a.price += 0
                        }

                        return a;
                    }, a_sect )
                }, {cnt: 0, price: 0.0} )

            //
            dispatch( Cart_Load( cart_obj, cart_sects ) )
            dispatch( RdxCart_Total( total_obj.cnt, total_obj.price ) )

            return {}
        }
        catch( err )
        {
            console.warn( 'actions/cart: RdxCart_SwitchType: err: ', err )

            return { err }
        }
    }   // return ...
}   // RdxCart_SwitchType

/**
 */
export const RdxCart_Init = () =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            const order_cart = await Cart_LocalGet( gs )

            console.log("order_cart",order_cart)

            if( !order_cart )
            {
                return {msg: 'OK'}
            }

            let cart_obj = {}, cart_sects = [] //,  counter = 0

            order_cart.sects.forEach( sect => {
                cart_obj[sect.cat_id] = {
                      cat_id: sect.cat_id
                    , cat_idx: sect.cat_idx
                    , cat: sect.cat
                    , data: []
                    , data_obj: {}
                };

                sect.data.forEach( item => {


                    gs().__menu.menu_prep.items[item._id]._cnt = item._cnt

                    if( item._mods )
                        gs().__menu.menu_prep.items[item._id]._mods = item._mods

                    if( item._modex )
                        gs().__menu.menu_prep.items[item._id]._modex = item._modex

                    if( item._opts )
                        gs().__menu.menu_prep.items[item._id]._opts = item._opts

                    if( item._setm )
                        gs().__menu.menu_prep.items[item._id]._setm = item._setm

                    cart_obj[sect.cat_id].data_obj[item._id] = gs().__menu.menu_prep.items[item._id];

                    cart_obj[sect.cat_id].data.push(cart_obj[sect.cat_id].data_obj[item._id])


                })
                cart_sects.push(cart_obj[sect.cat_id]);
            })

            console.log("order_cart",order_cart)
            console.log("order_cart",order_cart)


            dispatch( Cart_Load( cart_obj, cart_sects ) )
            dispatch( RdxCart_Total( order_cart.totalCnt, order_cart.totalPrice ) )
            dispatch( Checkout.Rdx_Checkout_SetSubtotal( order_cart.totalPrice ) )


            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'actions/cart: Cart_Init: err: ', err )

            return { err }
        }
    }   // ...
}   // Cart_Init

/**
 */
export const RdxCart_Total = ( total_cnt, total_price ) =>
{
    return {
        type: 'cart:total',
        payload: {cnt: total_cnt, price: total_price},
    }
}   // Cart_Load

/**
 */
const Cart_Upd = ( cart_obj, cart_sects ) =>
{
    return {
        type: 'cart:upd',
        payload: {obj: cart_obj, sects: cart_sects},
    }
}   // Cart_Upd

/**
 */
const Cart_Load = ( cart_obj, cart_sects ) =>
{
    return {
            type: 'cart:load',
            payload: {obj: cart_obj, sects: cart_sects},
        }
}   // Cart_Load

/**
 */
const LOCAL_CART_ARR = 'cart:arr'
const LOCAL_CART = 'cart'

/**
 */
const Cart_LocalSet = ( obj) =>
{
    try
    {
        return async ( dispatch, gs ) =>
        {
            try
            {
                const biz_id = obj && obj.biz_id? obj.biz_id : Utils.BizId( gs() )

                let biz_arr = await AStorage.Get( LOCAL_CART_ARR )

                if( !biz_arr )
                {
                    biz_arr = [biz_id]
                }
                else
                {
                    const find_biz = biz_arr.find( x => x === biz_id )

                    if( !find_biz )
                    {
                        biz_arr = [...biz_arr, biz_id]
                    }
                }

                //
                const key_store = obj && obj.biz_id ? [obj.biz_id , LOCAL_CART].join(':')
                    : Utils.Key( LOCAL_CART, gs() )

                // console.log( 'actions/_utils: Cart_LocalSet: key_store: ', key_store )
                // console.log( 'actions/_utils: Cart_LocalSet: biz_arr: ', biz_arr )


                await AStorage.Set( key_store,  {
                    sects: obj && obj.cart_sects ? obj.cart_sects : gs().__cart.sects,
                    totalCnt: gs().__cart.totalCnt,
                    totalPrice: gs().__cart.totalPrice,
                })

                await AStorage.Set( LOCAL_CART_ARR, biz_arr )

                return {msg: 'OK'}
            }
            catch( err )
            {
                console.warn( 'actions/cart: Cart_Init: err: ', err )

                return { err }
            }
        }

    }
    catch( err )
    {
        console.warn( 'Cart_LocalSet: err: ', err )

        return Promise.reject( err )
    }
}   // Cart_LocalSet

/**
 */
const Cart_LocalGet = async ( gs ) =>
{
    try
    {
        const key_cart = Utils.Key( LOCAL_CART, gs() )

        const order_cart = await AStorage.Get( key_cart )

        return order_cart
    }
    catch( err )
    {
        console.warn( 'Cart_LocalGet: err: ', err )

        return Promise.reject( err )
    }
}   // Cart_LocalGet

/**
 */
export const Rdx_Cart_LocalRem = () =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {

            const biz_id = Utils.BizId( gs() )

            let biz_arr = await AStorage.Get( LOCAL_CART_ARR )

            if( !biz_arr )
            {
                biz_arr = []
            }
            else
            {
                const biz_idx = biz_arr.findIndex( x => x === biz_id )

                if( 0 <= biz_idx )
                {
                    biz_arr.splice( biz_idx, 1 )
                }
            }

            const key_cart = Utils.Key( LOCAL_CART, gs() )

            await AStorage.RemoveItem( key_cart )
            await AStorage.Set( LOCAL_CART_ARR, biz_arr )

            return { msg: 'OK' }
        }
        catch( err )
        {
            console.warn( 'Cart_LocalRem: err: ', err )

            return Promise.reject( err )
        }
    }

}   // Cart_LocalRem

/**
 */
export const Convert_Cart_To_Invoice_Items = () =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            const sects = [...gs().__cart.sects]

            const cart_items = sects.reduce((acc, category) => {

                let cart_sects = {
                    cat_id: category.cat_id,
                    cat_idx: category.cat_idx,
                    data: [],
                }

                category.data.map((cur_item) => {

                    // console.log("cur_item", cur_item)

                    let item = gs().__menu.menu_prep.items[cur_item.id]

                    // console.log("item", item)

                    item._cnt = cur_item.cnt

                    if(cur_item.usrs && Object.entries(cur_item.usrs).length > 0)
                    {
                        item.usrs = cur_item.usrs
                    }

                    if(cur_item.type === 'mod')
                    {

                        item._mods.opts.map((mod, idx)=>{

                            if(cur_item.opts[idx])
                                mod._cnt =  cur_item.opts[idx].cnt

                            if(cur_item.opts[idx].usrs && Object.entries(cur_item.opts[idx]).length > 0 )
                                mod.usrs = cur_item.opts[idx].usrs
                        })
                    }

                    if(cur_item.type === 'mod_ex')
                    {
                        item._opts = cur_item.opts
                    }

                    if(cur_item.type === 'ssetmeal')
                    {
                        item._setm = cur_item.opts

                    }

                    cart_sects.data.push(item)

                });


                return [...acc, cart_sects]

            }, []);


            console.log("cart_items", cart_items)

            return cart_items
        }
        catch( err )
        {
            console.warn( 'actions/cart: Convert_Cart_To_Invoice_Items: err: ', err )

            return { err }
        }
    }   // ...
}   // Convert_Cart_To_Invoice_Items

/**
 */
export const CalculatePriceByUser = () =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            const sects = [...gs().__cart.sects]

            const usr_id = gs().__core.pubId

            // console.log("sects", sects)

            const total_amount = sects.reduce((acc, category) => {

                category.data.map((cur_item) => {

                    // console.log("cur_item", cur_item)

                    if(cur_item.type === 'basic')
                    {
                        if(cur_item.usrs[usr_id])
                            acc += cur_item.usrs[usr_id].cnt * cur_item.price
                    }
                    if(cur_item.type === 'mod')
                    {
                        Object.keys(cur_item.opts).map((key)=>{

                            const base_price = cur_item.price ? cur_item.price : 0

                            if(cur_item.opts[key].usrs[usr_id])
                                acc += base_price + (cur_item.opts[key].usrs[usr_id].cnt * cur_item.opts[key].price)
                        })
                    }

                    if(cur_item.type === 'mod_ex')
                    {
                        Object.keys(cur_item.opts).map((key)=>{

                            const base_price = cur_item.price ? cur_item.price : 0

                            if(cur_item.opts[key].usrs[usr_id])
                                acc +=  base_price + (cur_item.opts[key].usrs[usr_id].cnt * cur_item.opts[key].price)
                        })
                    }

                    if(cur_item.type === 'setmeal')
                    {
                        Object.keys(cur_item.opts).map((key)=>{

                            const base_price = cur_item.price ? cur_item.price : 0

                            if(cur_item.opts[key].usrs[usr_id])
                                acc += base_price + (cur_item.opts[key].usrs[usr_id].cnt * cur_item.opts[key].price)
                        })

                    }

                });


                return acc

            }, 0 );


            console.log("cart_items", total_amount)

            return total_amount
        }
        catch( err )
        {
            console.warn( 'actions/cart: Convert_Cart_To_Invoice_Items: err: ', err )

            return { err }
        }
    }   // ...
}   // CalculatePriceByUser

/**
 */
export const Rdx_Set_Cart = (obj , sect , total_cnt, total_price) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            gs().__cart.sects.forEach( sect => {
                sect.data.forEach( item => {
                    item._cnt = 0

                    if( item._mods )
                    {
                        item._mods.opts.forEach( opt => {opt._cnt = 0} )
                    }

                    if( item._opts )
                    {
                        item._opts = []
                    }

                    if( item._setm )
                    {
                        item._setm = {}
                    }
                } )
            })

            dispatch( Cart_Load( obj ? {...obj} : {}, sect ? [...sect] : [] ) )
            dispatch( RdxCart_Total( total_cnt ? total_cnt : 0, total_price? total_price : 0 ) )

            dispatch(Rdx_Cart_LocalRem())
        }
        catch( err )
        {
            console.warn( 'actions/cart: RdxCart_Set: err: ', err )

            return { err }
        }
    }   // ...
}   // RdxCart_Set

