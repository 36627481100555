/**
 * @copyright Elmelo Ltd.
 */

import React, {useState} from 'react'

// import {connect} from 'react-redux'
// import * as actions from '../../rdx/actions'

import { navigate } from "@reach/router"

import {Image} from "react-bootstrap";
import './invoice.css'

import elml_cfg from '../../_config/elml_cfg'

import {Lambda,} from '../../api/AWS'
import Loader from "react-loader-spinner";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardForm from './cardForm'

const stripePromise = loadStripe(elml_cfg.stripe.key[elml_cfg.stage]);

/**
 */
const Invoice = ( props ) =>
{
    const [isInit, SetIsInit] = React.useState( false )
    const [metaObj, SetMetaObj] = React.useState( null )
    const [bizImg, SetBizImg] = React.useState( null )
    const [payMethod, SetPayMethod] = React.useState( false );
    const [processing, setProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [p_msg] = useState({
        est_viewed:'has viewed your estimate.',
        invc_viewed:'has viewed your invoice.',
        paid:'has settled your invoice 💸',
        agreed:'has agreed to your estimate! 🤝',

    })

    /**
     */
    React.useEffect( () => {
        Init()
            .then( ( item ) => {
                getBiz( item ).catch();
                // SendPush(item,'paid').then()
            } )
            .catch();

    }, []);

    React.useEffect( () => {
       console.log(metaObj)
    }, [metaObj]);

    /**
     */
    const Init = async () =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            SetIsInit( true )

            const p_lambda =
            {
                stage: elml_cfg.stage,
                actType: "invoice",
                act: "invoice:getmd",
                data: {
                    uid: decodeURIComponent(props.meta),
                }
            };

            console.log(p_lambda)

            const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage ) );
            console.log(resp_lambda)

            if( !resp_lambda )
            {
                throw new Error( 'Invoice not found.' );
            }

            const data = resp_lambda.Item;

            console.log("data",data)

            SetMetaObj( data );

            if(data && data.type.toLowerCase() === 'estimate' && (data.status.trim().toLowerCase() === 'pending' || data.status.trim().toLowerCase() === 'edited'))
            {
                await UpdateEstimateStatus("viewed" , data)
                await SendPush(data,'est_viewed');
            }
            if(data && data.type.toLowerCase() === 'invoice' && (data.status.trim().toLowerCase() === 'new' || data.status.trim().toLowerCase() === 'pending'))
            {
                // console.log('invc_viewed')
                await UpdateEstimateStatus("Viewed" , data)
                await SendPush(data,'invc_viewed');
            }

            SetIsInit( false );

            return resp_lambda.Item ;
        }
        catch( err )
        {
            console.warn( 'Invoice: Init: err: ', err )

            SetIsInit( false )

            alert( err.message )

            return {err}
        }
    }   // Init

    /**
     *
     */
    const getBiz = async ( item ) =>
    {
        try
        {
            if( item )
            {
                const aws_lambda = new Lambda( {} );

                const p_lamdba =
                {
                    stage: elml_cfg.stage,
                    actType: "biz",
                    act: "biz:getinfo",

                    biz_id: item.biz_id
                };

                const resp_lambda = await aws_lambda.Invoke( p_lamdba, elml_cfg.lambda('unauth', elml_cfg.stage ) );

                const _img = resp_lambda.biz_images.icon_app ? resp_lambda.biz_images.icon_app : null;

                SetBizImg( _img.img_url );

                if( resp_lambda.setup_progress.pay_method )
                    SetPayMethod( resp_lambda.setup_progress.pay_method )
            }
        }
        catch( err )
        {
            console.error( "invoice2 : Invoice : getBiz : err : ", err );
        }
    }

    /**
     */
    const OnClick = () =>
    {
        try
        {
            if( !metaObj )
            {
                alert( 'Reserve object not ready' )

                return {}
            }

            const data = encodeURIComponent( JSON.stringify({uid: metaObj.uid , stage: props.stage }) )

            const link = `https://histreet-app.com/invoice/?data:${data}`

            const deep_link = `https://histreet.page.link/?link=${link}&ifl=https://testflight.apple.com/join/Hnhxjs9z&ibi=com.histreetapp.app&apn=com.histreet.app&ofl=https://histreet-app.com/invoice/${props.stage}/${encodeURIComponent(metaObj.uid)}`

            // const testFlight = "&ifl=https://testflight.apple.com/join/Hnhxjs9z"
            // const appStore = "&isi=1477813443"

            navigate(encodeURI(deep_link))

        }
        catch( err )
        {
            console.warn( 'Invoice: OnClick: err: ', err )
        }
    }   // OnClick
    /**
     */
    const UpdateEstimateStatus = async (status, item) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} );

            setProcessing(true);

            const p_lambda = {
                stage: elml_cfg.stage,
                actType: "invoice",
                act: "estimate:status:upd",

                biz_id: item.biz_id,
                dt_create: item.dt_create,

                status: status
            };

            const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );

            if(resp_lambda.msg === 'OK')
            {
                item.status = status
                SetMetaObj(item)
                if (status === 'agreed')
                {
                    await SendPush(item,'agreed')
                }
            }

            setProcessing(false);

            return resp_lambda;

        }
        catch( err )
        {
            setProcessing(false);

            console.warn( 'Invoice: UpdateEstimateStatus: err: ', err )
        }
    }   // UpdateEstimateStatus

    const SendPush = async (data,type)=>{
        try {
            console.log("SendPush: metaObj",data)

            // return {}
            const aws_lambda = new Lambda( {} );
            const p_lambda = {
                stage: elml_cfg.stage,
                actType: "notify",
                act: "notify:push",

                notification:{
                  title:data.invc_cus.name?data.invc_cus.name:data.c_name?data.c_name:'Customer',
                  body:p_msg[type],
                  collapse_key:data.biz_id
                },
                data:{
                    title:data.invc_cus.name?data.invc_cus.name:data.c_name?data.c_name:'Customer',
                    body:p_msg[type],
                    inv_uid:data.uid,
                    biz_id: data.biz_id,
                    notification_type:type
                },
                pub_id_list:[data.biz_id]
            };
            console.log("SendPush: p_lambda",p_lambda)
            const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );
            console.log("SendPush: resp_lambda",resp_lambda)
            //
            // return resp_lambda
        }catch (e) {
            console.warn('Invoice2: SendPush:err',e)
            return Promise.reject(e)
        }
    }



    /**
     */
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
            <Image className="hsImage" src={require('../assets/imageIcon/hs.png')}/>

            <div className="localText">
                Stay local, pay safely
            </div>
            {
                isInit ?

                    <div style={{display: "flex",
                        flex:1,
                        marginTop:32,
                        justifyContent:"center",
                        alignItems:"center"}}>
                        <Loader
                            type="Puff"
                            color="#466099"
                            height={160}
                            width={160}
                        />
                    </div>:
                    <div className="invoiceBg">
                        <>
                            {
                                metaObj
                                    ?
                                    <div className="outerDiv">
                                        <div className="innerDiv">
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                {
                                                    bizImg
                                                    ?
                                                        <div style={{flex: 1, paddingRight: 8, textAlign: "right",display:"flex",flexDirection:"column",justifyContent:"flex-end"}}>
                                                            <Image
                                                                style={{
                                                                    width: 100,
                                                                    height: 100,
                                                                    resizeMode: "cover",
                                                                    borderRadius: 50,
                                                                    marginLeft:8
                                                                }}
                                                                src= {bizImg}
                                                            />
                                                        </div>
                                                    :
                                                        <div className="imageClass">
                                                            <div
                                                                className="imageBigText">{metaObj.b_name ? metaObj.b_name.charAt(0) : '#'}</div>
                                                        </div>
                                                }
                                                <div style={{flex:1, paddingRight: 8, textAlign: "right",display:"flex", flexDirection:"column", justifyContent:"flex-end"}}>
                                                    <div
                                                        className="smallText">{new Date(metaObj.dt_create * 1000).toLocaleDateString("en-UK")}</div>
                                                    <div className="smallText">{metaObj.type.toLowerCase() === 'estimate' ? 'Estimate' : metaObj.dt_create}</div>
                                                </div>
                                            </div>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div className="nameDiv">
                                                    <div
                                                        className="bigText">{ metaObj.invc_cus && metaObj.invc_cus.name ? metaObj.invc_cus.name : "Customer Name"}</div>
                                                    <div className="smallText">
                                                        {
                                                            metaObj.invc_cus && metaObj.invc_cus.addr
                                                                ?
                                                                metaObj.invc_cus.addr.line1 ? metaObj.invc_cus.addr.line1 : " " :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="smallText">
                                                        {
                                                            metaObj.invc_cus && metaObj.invc_cus.addr
                                                                ? metaObj.invc_cus.addr.line2 ? metaObj.invc_cus.addr.line2 : " "
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="smallText">
                                                        {
                                                            metaObj.invc_cus && metaObj.invc_cus.addr
                                                                ?
                                                                    [
                                                                        metaObj.invc_cus.addr.county ? metaObj.invc_cus.addr.county : " ",
                                                                    ]
                                                                :
                                                                    null
                                                        }
                                                    </div>
                                                    <div className="smallText">
                                                        {
                                                            metaObj.invc_cus && metaObj.invc_cus.addr
                                                                ?
                                                                [
                                                                    metaObj.invc_cus.addr.postcode ? metaObj.invc_cus.addr.postcode : " ",
                                                                ]
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="nameDiv" style={{
                                                    justifyContent: "flex-end",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end"
                                                }}>
                                                    <div
                                                        className="bigText">{metaObj.b_name ? metaObj.b_name : ''}</div>
                                                    <div className="smallText">
                                                        {
                                                            metaObj.b_addr
                                                            ?
                                                                metaObj.b_addr.line1 ? metaObj.b_addr.line1 : " "
                                                            :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="smallText">
                                                        {
                                                            metaObj.b_addr
                                                                ?
                                                                metaObj.b_addr.line2 ? metaObj.b_addr.line2 : " "
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="smallText">
                                                        {
                                                            metaObj.b_addr
                                                            ?
                                                                [
                                                                    metaObj.b_addr.county ? metaObj.b_addr.county : " ",
                                                                ]
                                                            :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="smallText">
                                                        {
                                                            metaObj.b_addr
                                                                ?
                                                                [
                                                                    metaObj.b_addr.postcode ? metaObj.b_addr.postcode : " ",
                                                                ]
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <>
                                                {
                                                    metaObj.items
                                                        ? <div>
                                                            <div className="itemGradient">
                                                                <div className="itemGradientInner">
                                                                    <div className="bigTextCenter" style={{textAlign:"left"}}>Items</div>
                                                                    <div className="bigTextCenter">VAT</div>
                                                                    <div className="bigTextCenter">Quantity</div>
                                                                    <div className="bigTextCenter" style={{textAlign:"right"}}>Price</div>
                                                                </div>
                                                            </div>
                                                            <div style={{minHeight:200}}>
                                                                {
                                                                    metaObj.items.map((item, idx) => (
                                                                        <div
                                                                            style={{display: "flex", flexDirection: "row"}}>
                                                                            <div className="itemText"
                                                                                 style={{textAlign: "left", marginLeft: 8}}>
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="itemText">
                                                                                {item.vat ? item.vat : '0'}{'%'}
                                                                            </div>
                                                                            <div className="itemText">
                                                                                {item.quantity}
                                                                            </div>
                                                                            <div className="itemText" style={{
                                                                                textAlign: "right",
                                                                                marginRight: 8
                                                                            }}>
                                                                                £{(Math.round(item.price.def) ).toFixed(2)}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="prices">
                                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                                    <div>Sub Total</div>
                                                                    <div style={{width:100,textAlign:"right"}}>{'£'}{(metaObj.a_sub/100).toFixed(2)}</div>
                                                                </div>
                                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                                    <div>VAT</div>
                                                                    <div style={{width:100,textAlign:"right"}}>{'£'}{(metaObj.a_vat/100).toFixed(2)}</div>
                                                                </div>
                                                                <div className="totalDiv">
                                                                    <div>Total</div>
                                                                    <div style={{width:100,textAlign:"right"}}>{'£'}{(metaObj.a_tot/100).toFixed(2)}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        : null
                                                }
                                            </>
                                        </div>
                                    </div>

                                    : null
                            }
                        </>
                        {
                            metaObj && metaObj.type.toLowerCase() === 'invoice' && payMethod
                            ?
                                metaObj && metaObj.paid_status === "paid"
                                ?
                                    <div style={{flex: 1, padding: 50}}>
                                        <div className="greenButton">Paid</div>
                                    </div>
                                :
                                    <div>

                                        <div className="payText">
                                            <Elements stripe={stripePromise}>
                                                <CardForm invoice={ metaObj }
                                                    onSuccess={ obj => {
                                                        SetMetaObj( obj );
                                                        setPaymentSuccess( true );
                                                        SendPush(obj,'paid').then()
                                                    } }
                                                />
                                            </Elements>
                                        </div>
                                        {/* <div className="payText">or pay through HiStreet</div>
                                        <div
                                            // type="submit"
                                            onClick={OnClick}
                                            className="invoiceBtn">
                                            <div className="getBtnText">
                                                Open HiStreet
                                            </div>
                                        </div> */}

                                        {/* <div className="payText"> Or pay with BACS</div>
                                        <div className="accountText">Account Holder Name</div>
                                        <div className="valueText">JJ Plumbing</div>
                                        <div className="accountText">Account Number</div>
                                        <div className="valueText">123456789</div>
                                        <div className="accountText">Sort Code</div>
                                        <div className="valueText">00-00-00-00</div> */}
                                    </div>
                            :
                                metaObj && metaObj.type.toLowerCase() === 'estimate' && ( metaObj.status.toLowerCase() === 'pending' || metaObj.status.toLowerCase() === 'viewed'|| metaObj.status.toLowerCase() === 'edited')
                                ?
                                    <div
                                        onClick={ ()=> processing ? {} : UpdateEstimateStatus('agreed', metaObj)}
                                        className="invoiceBtn"
                                    >
                                    {
                                        processing
                                        ?
                                        <div className="getBtnText">Processing...</div>
                                        :
                                        <div className="getBtnText">
                                            Agree
                                        </div>
                                    }
                                    </div>
                                :
                                    metaObj && metaObj.type.toLowerCase() === 'estimate' && metaObj.status.toLowerCase() === 'agreed'
                                    ?
                                        <div
                                            // onClick={OnAgreeClick}
                                            className="invoiceBtn">
                                            <div className="getBtnText">
                                                Agreed
                                            </div>
                                        </div>
                                    :
                                        <div style={{flex: 1, padding: 50}}>
                                            <div className="redButton">Payment methods not initialized.</div>
                                        </div>
                        }
                    </div>
            }
            {
                paymentSuccess &&
                <div className="payDone">
                    <div className="payDoneDiv">
                        <Image src={require('../assets/imageIcon/tick.png')}/>
                        <div className="payDivText">Payment Success!</div>
                        <div className="invoiceBtn">
                            <div className="getBtnText" onClick={() => setPaymentSuccess(false)} >
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Invoice

