/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
export default ( state = {
        type: 'collection',
        tbl_no: ''
    }, action ) =>
{
	switch( action.type )
	{
		// case 'order:cart_upd':
        //     state.OnCnt(action.payload.item, action.payload.sect, action.payload.cnt_change, action.payload.user);
        //     return state.Update();

		// case 'order:info_type':
		// 	state.setType(action.payload);
        //     return state.Update();

		// case 'order:set_tbl_no':
        //     state.SetTable(action.payload);
        //     return state.Update();

		// case 'order:cart_clear':
        //     state.clearCart();
		// 	return state.Update();

		// case 'order:info_cust':
		// 	switch( action.payload.type )
		// 	{
		// 		case 'name':
        //             state.setName({...action.payload.data});
        //             return  state.Update();
		// 		case 'addr':
        //             state.setAddr({...action.payload.data});
        //             return  state.Update();
        //         case 'phone':
        //             state.setPhone(action.payload.data);
        //             return  state.Update();
        //         case 'email':
        //             state.setEmail(action.payload.data);
        //             return  state.Update();

        //         default:
		// 			return state;
		// 	}
		// 	// return { ...state,  };

        // case 'order:update':
        //     return state.Update();

        //     case 'order:clear':
        //     state.ClearAll();
        //     return state.Update();

        case 'order:init-status':
            return {...state, initStatus: action.payload}
            // state.SetInitStatus( action.payload )
            // return state.Update()

        case 'order:biz_id':
            return {...state, bizId: action.payload}
        case 'order:set-type':
            return {...state, type: action.payload}
        case 'order:set-table':
            return {...state, tbl_no: action.payload}

        // case 'order:menu':
        //     state.SetMenu( action.payload )
        //     return state.Update()

        // case 'order:old-order':
        //     state.SetOldOrder( action.payload )
        //     return state.Update()

        // case 'create:gr_order':
        //     state.SetGroupOrder( action.payload )
        //     return state.Update()
        // case 'create:gr_usr':
        //     state.SetGroupUser( action.payload )
        //     return state.Update()
        // case 'set_cur_order':
        //     state.SetCurOrder(action.payload)
        //     return state.Update();

		default:
			return state;
	}	// switch action.type
}






