/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import {Button} from  'react-bootstrap'
import {connect} from 'react-redux'
import {ItemPrice} from './ItemPrice'
import ONT from './ont'
import * as actions from '../../rdx/actions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft,faTrashAlt,faMinus,faPlus,faTimes} from '@fortawesome/free-solid-svg-icons'
import './css/order.css'
import { navigate } from "@reach/router"
/**
 */
class Order_Cart extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );

        this.state = {
            height: window.innerHeight - (70 + 160),
            catheight: window.innerHeight - 70,
            width:window.innerWidth,
            orderProcess:false,
            errMsg: "",
        };
    }

    /**
     */
    showOrderProcess = ( val ) =>
    {
        this.setState({orderProcess:val})
    }

    getOT = async() =>
    {
        const dt = new Date();
        const day = dt.getDay();

        const ot_day = JSON.parse(this.props.__biz.biz_ot).days[day].map(x => {
            x.open = x.open.map(val => {
                try
                {
                    val = val.toString();
                }
                catch (err)
                {
                    console.warn( "ot_day : open : err : ", err );
                }

                return val.padStart(2, '0');
            });

            x.close = x.close.map(val => {
                try
                {
                    val = val.toString();
                }
                catch (err)
                {
                    console.warn( "ot_day : close : err : ", err );
                }

                return val.padStart(2, '0');
            });

            const open_n = parseInt(x.open.join(''), 10);
            const close_n = parseInt(x.close.join(''), 10);

            return {...x, open_n, close_n};
        });


        const hours = dt.getHours().toString().padStart(2, '0');
        const mins = dt.getMinutes().toString().padStart(2, '0');
        const secs = dt.getSeconds().toString().padStart(2, '0');
        const t_in_number = parseInt([hours, mins, secs].join(''), 10);
        const ot_cur = ot_day.find(x => (t_in_number >= x.open_n && t_in_number < x.close_n));

        if (ot_cur) {
            const ot_str = [ot_cur.open[0], ot_cur.open[1]].join(':') + ' - ' + [ot_cur.close[0], ot_cur.close[1]].join(':');
            return {open: true, msg:""};
        }

        let ot_day_sorted = [...ot_day];

        ot_day_sorted.sort( (a, b) => (a.open_n - b.open_n) );
        ot_day_sorted = ot_day_sorted.map(x => {
            x._diff = t_in_number - x.open_n;

            return x;
        });

        ot_day_sorted = ot_day_sorted.filter(x => x._diff <= 0);

        if (ot_day_sorted.length)
        {
            ot_day_sorted.sort((a, b) => (b._diff - a._diff));
            const ot_next = ot_day_sorted[0];
            const ot_next_str = [ot_next.open[0], ot_next.open[1]].join(':') + ' - ' + [ot_next.close[0], ot_next.close[1]].join(':');

            return { open: false, msg: "We are closed right now. Will start service from " + ot_next_str };
        }
        else
        {
            return { open: false, msg: "We are closed for today." };
        }
    }

    placeOrder = async() =>
    {
        // console.log( "beta_id : ", this.props.__core.betaId );
        // console.log( "this.props : ", this.props );

        this.props.Rdx_Order_SetType(this.props.orderType)

        if(this.props.orderType.toLowerCase() === 'instore')
            this.props.Rdx_Checkout_Set_Histreet_Charge(0)

        if(this.props.__cfg.stage === "test")
        {
            // navigate("/order/checkout");
            navigate('/order/checkout', { state: {fromPage: ['','order',this.props.__biz.biz_id  , this.props.orderType].join('/') } } )

            return
        }
        const ifOpen = await this.getOT();

        if( this.props.__biz.suspend.alpha === "true" )
        {
            alert( this.props.__biz.suspend.alpha_msg );
        }
        else if( this.props.__biz.suspend.beta === "true" )
        {
            alert( this.props.__biz.suspend.beta_msg );
        }
        else if( !ifOpen.open )
        {
            alert( ifOpen.msg );
        }
        else if( !this.props.__order.cart_sects.length )
        {
            this.setState({errMsg: "Please add an item"});
            alert("Add an Item to Proceed.")
        }
        else
        {
            // navigate("checkout");
            navigate('/order/checkout', { state: {fromPage: ['','order',this.props.__biz.biz_id  , this.props.orderType].join('/') } } )

        }
    }

    // /**
    //  */
    // componentDidMount()
    // {
    //     //
    // }

    /**
     */
    clearCart = () =>
    {
        this.props.Rdx_Set_Cart();
        this.props.Rdx_Checkout_SetSubtotal(0);

    }

    /**
     */
    render()
    {
        // console.log( "Order_Cart : render : props : ", this.props );
        // // console.log( "Order_Cart : render : state : ", this.state );

        return (
            <div>
                <div className="innerCart">
                    {
                        this.props.showCart
                            ?   <div
                                className="cartbackBtn"
                                style={{marginRight: 8, marginTop: 8, cursor: "pointer"}}
                                onClick={() => this.props.showCart(false)}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} size="lg" color={"#C94B4B"}/>
                            </div>

                            :   null
                    }
                    <h2 style={{flex: 1}}>Cart</h2>
                    <div className="cartDelete"
                         onClick={this.clearCart}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" color={"#C94B4B"}/>
                    </div>
                </div>
                <div className="cartItemsContainer"
                     style={{height:this.state.height, borderColor: "#DDD",}}
                >
                    {/*<Cart_Items {...this.props} />*/}
                    {
                        !this.props.__cart.sects.length
                            ? <div className="imageText">
                                {/*<Image*/}
                                {/*    className="orderCompleteImage"*/}
                                {/*    style={{width:250}}*/}
                                {/*    src={require('../assets/img/placeholder/noItem.png')}*/}
                                {/*    rounded*/}
                                {/*/>*/}
                                <div className="alertMessage">
                                    No items it the cart now
                                </div>
                            </div>

                            :   this.props.__cart.sects.map((sect) => {
                                return sect.data.map( item => (
                                    item._type === "basic"
                                        ?   <Cart_Item_Basic {...this.props}
                                                             item={item} sect={sect}
                                                             key={item._id}/>

                                        :   item._type === "mod"
                                        ?   <Cart_Item_Mod {...this.props}
                                                           item={item} sect={sect}
                                                           key={item._id}/>

                                        :   item._type === "mod_ex"
                                            ?   <Cart_Item_ModEx {...this.props}
                                                                 item={item} sect={sect}
                                                                 key={item._id}/>

                                            :   item._type === "setmeal"
                                                ?   <Cart_Item_SetMeal {...this.props}
                                                                       item={item} sect={sect}
                                                                       key={item._id}/>

                                                // :   <div>Unknown Item Type</div>
                                                :   null
                                ) )
                            } )
                    }
                </div>

                <div className="cartBottom">
                    <h6>Total (including vat)</h6>
                    <h3>£{ this.props.__cart.totalPrice.toFixed(2) }</h3>
                    {
                        'auth' === this.props.__core.authStatus
                            ?   <Button
                                onClick={() => this.placeOrder()}
                                className="cartButton"
                                style={{backgroundColor: "#C94B4B",}}
                                size="lg" variant="info">Place Order
                            </Button>

                            :   <Button
                                onClick={() =>
                                    navigate('/auth/signin', { state: {fromPage: ['','order',this.props.__biz.biz_id  , this.props.orderType].join('/') } } )
                                }
                                className="cartButton"
                                style={{backgroundColor: "#C94B4B",}}
                                size="lg" variant="info">Signin to Order
                            </Button>
                    }
                </div>
            </div>
        )
    }   // render
}   // class Order_Cart

/**
 */
class Cart_Item_Basic extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );
    }

    // /**
    //  */
    // componentDidMount()
    // {
    //     //
    // }

    /**
     */
    updateItemQuantity = type =>
    {
        if( '+' === type )
        {
            ++this.props.item._cnt;
        }
        else if( '-' === type )
        {
            if( this.props.item._cnt > 0 )
                --this.props.item._cnt;
        }
        else
        {
            return;
        }

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
        // this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
        // const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

        // this.props.Rdx_Checkout_SetSubtotal(_total.total);
        // this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
    }

    /**
     */
    clearItemFromCart = () =>
    {
        this.props.item._cnt = 0;

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
        // this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
        // const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

        // this.props.Rdx_Checkout_SetSubtotal(_total.total);
        // this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
    }

    /**
     */
    render()
    {
        return(
            <div className="cartItems" style={{borderColor: "#ddd"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div className="cartItemTitle">
                        {this.props.item.name}
                    </div>
                    <FontAwesomeIcon className="cartRemove"  icon={faTimes} size="lg"
                                     onClick={() => this.clearItemFromCart()}
                                     color={"#ddd"}/>
                </div>
                <div className="cartCrossDiv">
                    <FontAwesomeIcon
                        className="cartPlus"
                        onClick={() => this.updateItemQuantity('+')}
                        icon={faPlus} size="xs" color={"#ddd"}/>
                    <div className="cartItemCount">
                        {this.props.item._cnt}
                    </div>
                    <FontAwesomeIcon
                        className="cartPlus"
                        onClick={() => this.updateItemQuantity('-')}
                        icon={faMinus} size="xs" color={"#ddd"}/>
                    <div className="cartPriceTitle">
                        £{(ItemPrice(this.props.item , this.props.orderType) * this.props.item._cnt).toFixed(2)}
                    </div>
                </div>
            </div>
        );
    }   // render
}   // ...

/**
 */
class Cart_Item_Mod extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );
    }

    // /**
    //  */
    // componentDidMount()
    // {
    //     //
    // }

    /**
     */
    updateItemQuantity = ( type, mod ) =>
    {
        if( '+' === type )
        {
            ++mod._cnt;
            ++this.props.item._cnt;
        }
        else if( '-' === type )
        {
            --mod._cnt;
            --this.props.item._cnt;
        }
        else
        {
            return;
        }

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
        // this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
        // const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

        // this.props.Rdx_Checkout_SetSubtotal(_total.total);
        // this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
    }

    /**
     */
    clearItemFromCart = ( mod ) =>
    {
        this.props.item._cnt -= mod._cnt;
        mod._cnt = 0;

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
        // this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
        // const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

        // this.props.Rdx_Checkout_SetSubtotal(_total.total);
        // this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
    }

    /**
     */
    render()
    {

        const it_p = ItemPrice(this.props.item, this.props.orderType)
        const bPrice = it_p ? it_p : 0

        return(
            <div className="cartItems" style={{borderColor: "#ddd"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div className="cartItemTitle">
                        {this.props.item.name}
                        {bPrice? " (£"+bPrice +")" : null}
                    </div>
                    {/*{*/}
                    {/*    bPrice ?*/}
                    {/*        <div className="itemPrice">£{bPrice}</div>*/}
                    {/*        : null*/}

                    {/*}*/}
                </div>
                <div>
                    {
                        this.props.item._mods && this.props.item._mods.opts
                            ?
                            this.props.item._mods.opts.map( (mod, mod_idx) => (
                                mod._cnt
                                    ?
                                    <React.Fragment key={mod_idx}>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="cartModTitle">{mod.name}</div>
                                            <FontAwesomeIcon className="cartRemove"  icon={faTimes} size="lg"
                                                             onClick={() => this.clearItemFromCart(mod)}
                                                             color={"#ddd"}/>
                                        </div>
                                        <div className="cartCrossDiv">
                                            <FontAwesomeIcon
                                                className="cartPlus"
                                                onClick={() => this.updateItemQuantity('+', mod)}
                                                icon={faPlus} size="xs" color={"#ddd"}/>
                                            <div className="cartItemCount">
                                                {mod._cnt}
                                            </div>
                                            <FontAwesomeIcon
                                                className="cartPlus"
                                                onClick={() => this.updateItemQuantity('-', mod)}
                                                icon={faMinus} size="xs" color={"#ddd"}/>
                                            <div className="cartPriceTitle">
                                                £{((ItemPrice(this.props.item, this.props.orderType) + ItemPrice(mod, this.props.orderType) )* mod._cnt).toFixed(2)}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            ) ).filter( mod => mod )
                            :
                            <React.Fragment>
                                Modifier Options are not available.
                            </React.Fragment>
                    }


                </div>
            </div>
        );
    }   // render
}   // ...

/**
 */
class Cart_Item_ModEx extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );
    }

    // /**
    //  */
    // componentDidMount()
    // {
    //     //
    // }

    /**
     */
    updateItemQuantity = ( type, optObj ) =>
    {
        if( '+' === type )
        {
            ++optObj._cnt;
            ++this.props.item._cnt;
        }
        else if( '-' === type )
        {
            --optObj._cnt;
            --this.props.item._cnt;
        }
        else
        {
            return;
        }

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
        // this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
        // const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

        // this.props.Rdx_Checkout_SetSubtotal(_total.total);
        // this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
    }

    /**
     */
    clearItemFromCart = ( optObj ) =>
    {
        this.props.item._cnt -= optObj._cnt;
        optObj._cnt = 0;

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )
        // this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
        // const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

        // this.props.Rdx_Checkout_SetSubtotal(_total.total);
        // this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
    }

    /**
     */
    render()
    {
        const {item} = this.props;

        const it_p = ItemPrice(item, this.props.orderType)
        const bPrice = it_p ? it_p : 0

        return(
            Object.keys(item._opts).map( ( optKey ) => {
                const optObj = item._opts[optKey];
                return(
                    <div className="cartItems" style={{borderColor: "#ddd"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className="cartItemTitle">
                                {item.name}
                            </div>
                            {
                                bPrice ?
                                    <div className="cartPriceTitle">£{bPrice}</div>
                                    : null

                            }

                            <FontAwesomeIcon className="cartRemove" style={{marginTop:bPrice ? -18 : 0 }} icon={faTimes} size="lg"
                                             onClick={ () => this.clearItemFromCart( optObj ) }
                                             color={"#ddd"}/>
                        </div>
                        {
                            optObj.selMod
                                ?
                                optObj.selMod.map( (mod) => (
                                    <React.Fragment key={mod._id}>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="cartModTitle">
                                                Primary modifier: {mod.obj.name}
                                            </div>
                                            <div className="cartPriceTitle">£{ItemPrice(mod.obj, this.props.orderType)}</div>
                                        </div>
                                    </React.Fragment>
                                ) )
                                :
                                null
                        }
                        {
                            optObj.selSecMods
                                ?
                                optObj.selSecMods.map( (mod) => (
                                    <React.Fragment key={mod._id}>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="cartModTitle">
                                                Sec mods: {mod.obj.name}
                                            </div>
                                            <div className="cartPriceTitle">£{ItemPrice(mod.obj, this.props.orderType)}</div>
                                        </div>
                                    </React.Fragment>
                                ) )
                                :
                                null
                        }
                        {
                            optObj.selAddons
                                ?
                                optObj.selAddons.map( (addons) => (

                                    <React.Fragment key={addons._id}>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="cartModTitle">
                                                Addons: {addons.obj.name}
                                            </div>
                                            <div className="cartPriceTitle">£{ItemPrice(addons.obj, this.props.orderType)}</div>
                                        </div>
                                    </React.Fragment>

                                ))
                                :
                                null
                        }
                        <div className="cartCrossDiv">
                            <FontAwesomeIcon
                                className="cartPlus"
                                onClick={() => this.updateItemQuantity('+', optObj)}
                                icon={faPlus} size="xs" color={"#ddd"}
                            />
                            <div className="cartItemCount">
                                {optObj._cnt}
                            </div>
                            <FontAwesomeIcon
                                className="cartPlus"
                                onClick={() => this.updateItemQuantity('-', optObj)}
                                icon={faMinus} size="xs" color={"#ddd"}
                            />
                            <div className="cartPriceTitle">
                                £{ ONT.GetTotal_ModEx(item , optObj , this.props.orderType).toFixed(2) }
                            </div>
                        </div>
                    </div>
                )
            } )
        )
    }   // render
}   // ...

/**
 */
class Cart_Item_SetMeal extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );
    }

    // /**
    //  */
    // componentDidMount()
    // {
    //     //
    // }

    /**
     */
    updateItemQuanity = ( type, setMealObj ) =>
    {
        if( type === "+" )
        {
            ++setMealObj._cnt;
            ++this.props.item._cnt;
        }
        else
        {
            --setMealObj._cnt;
            --this.props.item._cnt;

            setMealObj._cnt = setMealObj._cnt > 0 ? setMealObj._cnt : 0;

            this.props.item._cnt = this.props.item._cnt > 0 ? this.props.item._cnt : 0
        }

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )

    }

    /**
     */
    clearItemFromCart = setMealObj =>
    {
        this.props.item._cnt -= setMealObj._cnt;
        setMealObj._cnt = 0;

        this.props.RdxOrder_AddToCart( this.props.item, this.props.sect, this.props.item._cnt )

    }

    /**
     */
    render()
    {
        const {item} = this.props;

        return (Object.keys(item._setm).map((setMealItem) => {
                const setMealObj = item._setm[setMealItem]

                return (
                    <div className="cartItems" style={{borderColor: "#ddd"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className="cartItemTitle">
                                {item.name}
                            </div>
                            <FontAwesomeIcon className="cartRemove" icon={faTimes} size="lg"
                                             color={"#ddd"}
                                             onClick={() => this.clearItemFromCart(setMealObj)}
                            />
                        </div>
                        {
                            setMealObj.items.map((_item, _idx) => (
                                <React.Fragment key={_idx}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div
                                            className="cartModTitle">{_item.quantity} x {_item.name}({_item.catName})
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                        }
                        <div className="cartCrossDiv">
                            <FontAwesomeIcon
                                className="cartPlus"
                                onClick={() => this.updateItemQuanity("+", setMealObj)}
                                icon={faPlus} size="xs" color={"#ddd"}
                            />
                            <div className="cartItemCount">
                                {setMealObj._cnt}
                            </div>
                            <FontAwesomeIcon
                                className="cartPlus"
                                onClick={() => this.updateItemQuanity("-", setMealObj)}
                                icon={faMinus} size="xs" color={"#ddd"}
                            />
                            <div className="cartPriceTitle">
                                £{(item.price.def * setMealObj._cnt).toFixed(2)}
                            </div>
                        </div>
                    </div>
                );

            })
        );
    }   // render
}   // ...

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Order_Cart )


