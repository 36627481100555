/**
 *	@copyright	Elmelo Ltd
 */

import {Utils} from './Utils';
import {Colour} from './Utils_UI';

/**
 */
class Utils_Menu
{
    /**
     */
    static Prep = ( fullMenu, biz_id ) =>
    {
        return Utils_Menu.Prep_V1( fullMenu, biz_id );
    }

    /**
     */
    static Prep_V1 = ( fullMenu, biz_id ) =>
    {
        // console.log("fullmenu", fullMenu);
        fullMenu.biz_id = biz_id;

        if( !fullMenu.cats )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.cats = fullMenu.cats.map( (cat, idx) => {
            //     try{
            //         return Utils_Menu.Prep_Cat( cat, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.cats = Utils_Menu.Array2Obj( fullMenu.cats );
        }

        if( !fullMenu.subcats )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.sub_cats = fullMenu.sub_cats.map( (sub_cat, idx) => {
            //     try{
            //         return Utils_Menu.Prep_SubCat( sub_cat, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.subcats = Utils_Menu.Array2Obj( fullMenu.subcats );
        }

        if( !fullMenu.mods )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.mods = fullMenu.mods.map( (mod, idx) => {
            //     try{
            //         return Utils_Menu.Prep_Mod( mod, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.mods = Utils_Menu.Array2Obj( fullMenu.mods );
        }

        if( !fullMenu.secmods )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.sec_mods = fullMenu.sec_mods.map( (sec_mod, idx) => {
            //     try{
            //         return Utils_Menu.Prep_SecMod( sec_mod, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.secmods = Utils_Menu.Array2Obj( fullMenu.secmods );
        }

        if( !fullMenu.addons )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.addons = fullMenu.addons.map( (addon, idx) => {
            //     try{
            //         return Utils_Menu.Prep_Addon( addon, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.addons = Utils_Menu.Array2Obj( fullMenu.addons );
        }

        if( !fullMenu.tags )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.tags = fullMenu.tags.map( (tag, idx) => {
            //     try{
            //         return Utils_Menu.Prep_Tag( tag, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.tags = Utils_Menu.Array2Obj( fullMenu.tags );
        }

        if( !fullMenu.allergens )
        {
            /// @todo ...
        }
        else
        {
            // fullMenu.allergens = fullMenu.allergens.map( (allergen, idx) => {
            //     try{
            //         return Utils_Menu.Prep_Allergen( allergen, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            fullMenu.allergens = Utils_Menu.Array2Obj( fullMenu.allergens );
        }

        if( !fullMenu.items )
        {
            /// @todo ...
        }
        else
        {
            // console.log( 'Utils_Menu: Prep_V1: fullMenu.items', fullMenu.items );

            // fullMenu.items = fullMenu.items.map( (item, idx) => {
            //     // if( idx === 6 )
            //     // {
            //     //     console.log( JSON.stringify(item, 0, 2) );
            //     //     const tmp_item = Utils_Menu.Prep_Item( item, idx, fullMenu );
            //     //     console.log( JSON.stringify(tmp_item, 0, 2) );
            //     //     return tmp_item
            //     // }
            //     try{
            //         return Utils_Menu.Prep_Item( item, idx, fullMenu );
            //     }
            //     catch( err ){
            //         return null;
            //     }
            // } ).filter( x => x );

            // console.log( 'Utils_Menu: Prep_V1: fullMenu.items_1', fullMenu.items_1 );

            fullMenu.items = Utils_Menu.Array2Obj( fullMenu.items );

            // console.log( 'Utils_Menu: Prep_V1: fullMenu.items', fullMenu.items );
        }

        return fullMenu;
    }	// Prep_V1

    static Prep_Cat = ( cat, idx, fullMenu ) =>
    {
        const cat_version = '1';

        cat = Utils.ParseString(cat);

        // console.log( 'cat: ', JSON.stringify(cat) );

        if( !cat._id )
        {
            cat._id = Utils.MD5( [fullMenu.biz_id, cat.name, cat_version].join(':') );
            // console.log( 'cat.MD5: '
            //     , 'fullMenu.biz_id: ', fullMenu.biz_id
            //     , ', cat.Name: ', cat.Name
            //     , ', cat_version', cat_version
            //     );
        }

        // console.log( 'cat._id: ', cat._id );

        if( !cat.idx )
            cat.idx = idx;
        else
        {
            if( 'string' === typeof idx )
                cat.idx = parseInt( idx );
        }

        return cat;
    }

    static Prep_SubCat = (sub_cat, idx, fullMenu) =>
    {
        const sub_cat_version = '0';

        sub_cat = Utils.ParseString(sub_cat);

        // console.log( 'sub_cat: ', JSON.stringify(sub_cat) );

        if (!sub_cat._id) {
            const cat_id = sub_cat.Parent ? sub_cat.Parent : sub_cat.ParentName;
            sub_cat._id = Utils.MD5([fullMenu.biz_id, cat_id, sub_cat.Name, sub_cat_version].join(':'));
            // console.log( 'sub_cat.MD5: '
            //     , 'fullMenu.biz_id: ', fullMenu.biz_id
            //     , ', cat_id: ', cat_id
            //     , ', sub_cat.Name: ', sub_cat.Name
            //     , ', sub_cat_version', sub_cat_version
            //     );
        }

        // console.log( 'sub_cat._id: ', sub_cat._id );

        if (!sub_cat.Parent)
        {
            console.log( 'sub_cat', sub_cat );

            sub_cat.Parent = Utils_Menu.GetObjByName(sub_cat.ParentName, fullMenu.cats)._id;
        }

        if( !sub_cat.Cat_Idx ){
            // console.log( 'fullMenu.cats[sub_cat.Parent]', fullMenu.cats[sub_cat.Parent] );
            sub_cat.Cat_Idx = Utils_Menu.GetObjByName(sub_cat.Parent, fullMenu.cats).Idx;
        }

        if( !sub_cat.Idx )
            sub_cat.Idx = idx;
        else
        {
            if( 'string' === typeof sub_cat.Idx )
                sub_cat.Idx = parseInt( sub_cat.Idx );
        }

        return sub_cat;
    }

    static Prep_Mod = (mod, idx, fullMenu) =>
    {
        const mod_version = '0';

        mod = Utils.ParseString(mod);

        if( !mod._id )
        {
            mod._id = Utils.MD5( [fullMenu.biz_id, mod.Name, mod_version].join(':') );
        }

        return mod;
    }

    static Prep_SecMod = (sec_mod, idx, fullMenu) =>
    {
        const sec_mod_version = '0';

        sec_mod = Utils.ParseString(sec_mod);

        if( !sec_mod._id )
        {
            sec_mod._id = Utils.MD5( [fullMenu.biz_id, sec_mod.Name, sec_mod_version].join(':') );
        }

        return sec_mod;
    }

    static Prep_Addon = (addon, idx, fullMenu) =>
    {
        const addon_version = '0';

        addon = Utils.ParseString(addon);

        if( !addon._id )
        {
            addon._id = Utils.MD5( [fullMenu.biz_id, addon.Name, addon_version].join(':') );
        }

        return addon;
    }

    static Prep_Tag = (tag, idx, fullMenu) =>
    {
        const tag_version = '0';

        tag = Utils.ParseString(tag)

        if( !tag._id )
        {
            tag._id = Utils.MD5( [fullMenu.biz_id, tag.Name, tag_version].join(':') );
        }

        return tag;
    }

    static Prep_Allergen = (allergen, idx, fullMenu) =>
    {
        const allergen_version = '0';

        allergen = Utils.ParseString(allergen);

        if( !allergen._id )
        {
            allergen._id = Utils.MD5( [fullMenu.biz_id, allergen.Name, allergen_version].join(':') );
        }

        return allergen;
    }

    static Prep_Item = ( item, idx, fullMenu ) =>
    {
        const item_version = '0';

        item = Utils.ParseString(item);

        if (!item.Cat_Id)
        {
            // console.log( 'item.Cat: ', item.Cat );
            const tmp_cat = Utils_Menu.GetObjByName(item.Cat, fullMenu.cats);
            // console.log( 'tmp_cat: ', JSON.stringify(tmp_cat) );
            item.Cat_Id = tmp_cat ? tmp_cat._id : null;
        }

        if (!item.Sub_Cat_Id)
        {
            // console.log( 'item.Sub_Cat: ', item.Sub_Cat );
            const tmp_subcat = Utils_Menu.GetObjByName(item.Sub_Cat, fullMenu.sub_cats);
            // console.log( 'tmp_subcat: ', JSON.stringify(tmp_subcat) );
            item.Sub_Cat_Id = tmp_subcat ? tmp_subcat._id : null;
        }

        if( item.Mods && item.Mods.length && !item.Mods_Id )
        {
            const tmp_mod = Utils_Menu.GetObjByName(item.Mods, fullMenu.mods);

            // console.log( 'Prep_Item: item.Mods: ', item.Mods );
            // console.log( 'Prep_Item: tmp_mod: ', tmp_mod );

            item.Mods_Id = tmp_mod ? tmp_mod._id : null;
        }

        try {
            if (!item._id) {
                item._id = Utils.MD5([fullMenu.biz_id, item.Cat_Id, item.Sub_Cat_Id, item.Name, item_version].join(':'));
            }
        }
        catch(err){
            console.error("Try3: ", err);
            throw err;
        }

        if( !item.Cat_Idx )
            item.Cat_Idx = fullMenu.cats[item.Cat_Id].Idx;

        if( !item.Sub_Cat_Idx )
            item.Sub_Cat_Idx = fullMenu.sub_cats[item.Sub_Cat_Id].Idx;

        if( !item._col )
            item._col = Colour.FromStr( item._id );

        if( !item._col_inv )
            item._col_inv = Colour.Invert( item._col );

        if( !item._tag )
        {
            const word_arr = item.Name.split( ' ' );

            if( 1 === word_arr.length )
            {
                if( 1 < word_arr[0].length )
                    item._tag = word_arr[0].substring(0, 2);
                else if( 1 === word_arr[0].length )
                    item._tag = word_arr[0].substring(0, 1);
                else
                    item._tag = '';
            }
            else if( 1 < word_arr.length )
            {
                item._tag = word_arr[0].substring(0,1)+word_arr[1].substring(0,1);
            }
            else
            {
                item._tag = '';
            }

            item._tag = item._tag.toUpperCase();

            // item._tag = Colour.FromStr( item._id );
        }

        // console.log( 'Utils_Menu: Prep_Item: ', item );

        return item;//{...item};
    }

    /**
     */
    static GetObjByName = ( name, arr_obj ) =>
    {
        try{
            const tar_key = Object.keys(arr_obj).find( key => {
                if( arr_obj[key] && arr_obj[key].Name == name )
                    return true;

                return false;
            } );

            return arr_obj[tar_key];
        }
        catch( err ){
            return null;
        }
    }   // GetSubCatByName

    /**
     */
    static Array2Obj = ( arr ) =>
    {
        let obj_ = {};

        arr.forEach( x => {
            obj_[x._id] = x;
        } );

        return obj_;
    }	// Array2Obj
}	// class Utils_Menu


export default Utils_Menu;

