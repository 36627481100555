/**
 *	@copyright	Elmelo Ltd.
 */

export default ( state = {
    authStatus: 'inprogress',   // 'auth' / 'unauth' / 'veri_reqd'
    bNetwork: true, bInternet: true,
}, action ) =>
{
    switch( action.type )
    {

    case 'core:auth_status':
        return {...state, authStatus: action.payload}

    case 'core:b_network':
        return {...state, bNetwork: action.payload}

    case 'core:b_internet':
        return {...state, bInternet: action.payload}

    default:
        return state;
    }	// switch action.type
}


