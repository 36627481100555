/**
 *	@copyright	Elmelo Ltd
 */

const elml = {
	stage: "live",
	// stage: "test",

	platform: 'hs',
	usr_t: 'beta',

	email_address: 'noreply@histreet-app.com',

	elmelo_v_iot_order: '2',
	_region: 'eu-west-1',

	// api_google: 'AIzaSyCfMBcvAJsfDMMsNqKBxjfb0kSOpFZaNh8',
    // api_sendbird: ( stage ) => {
    //     return 'test' === stage ? 'BDAE413B-EFCB-45D6-8126-F51220CFF1BC' : 'BDAE413B-EFCB-45D6-8126-F51220CFF1BC';
    //     // return 'test' === stage ? 'B9167652-674D-4EEB-963C-9E738160837B' : 'BDAE413B-EFCB-45D6-8126-F51220CFF1BC';
	// },

	aws: {
            acnt: '391466644385',
            roi: 'eu-west-1',
        },

	// api: ( name, stage = elml.stage ) => {
	// 		switch( stage )
	// 		{
	// 		case 'test':
	// 			switch( name )
	// 			{
	// 			case 'google': return 'AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M'//'AIzaSyCfMBcvAJsfDMMsNqKBxjfb0kSOpFZaNh8';
	// 			case 'sendbird': return 'BDAE413B-EFCB-45D6-8126-F51220CFF1BC';
	// 			default: null;
	// 			}	// switch

	// 		case 'live':
	// 		default:
	// 			switch( name )
	// 			{
	// 			case 'google': return 'AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M'//'AIzaSyCfMBcvAJsfDMMsNqKBxjfb0kSOpFZaNh8';
	// 			case 'sendbird': return 'BDAE413B-EFCB-45D6-8126-F51220CFF1BC';
	// 			default: null;
	// 			}	// switch
	// 		}
	// 	},

	stripe: {
        key: {
                live: 'pk_live_CsZHj0WJH3LXOllagfsTQVJO',
                test: 'pk_test_g9kLXsq7bwsQjllLFWMpJqEp',
            },
            v: '2019-12-03',
        },

	db: ( name, stage = elml.stage ) => {
            switch( stage )
            {
            case 'test':
                switch( name )
                {
				case 'biz': return 'test_elmelo_biz_2'
				case 'geos': return 'test_elmelo_geos_2';
				case 'images': return 'test_elmelo_images_1';
				case 'menu': return 'test_elmelo_menu_1';
				case 'serv': return 'test_elmelo_serv_1';

				case 'order': return 'test_elmelo_order_3';

				case 'biz_info': return 'test_elmelo_rat_1';
				case 'pay_disc': return 'test_elmelo_pay_discount_1';
				case 'soc_event': return 'test_elmelo_event_0';
				case 'soc_cal': return 'test_elmelo_cal_0';
				case 'soc_rsrv': return 'test_elmelo_rsrv_0';
				case 'notify': return 'test_elmelo_notify_2';
				case 'wallet': return 'test_elmelo_wallet_3';

				case 'mship': return 'test_elml_mship_1';
				case 'pass': return 'test_elml_pass_1';
				case 'pr_doc': return 'test_elml_pass_id_1';
				case 'pub_info': return 'test_elmelo_beta_pub_info_1';

                default: return null;
                }   // // switch

            case 'live':
            default:
                switch( name )
                {
				case 'biz': return 'elmelo_biz_2'
				case 'geos': return 'elmelo_geos_2'
				case 'images': return 'elmelo_images_1';
				case 'menu': return 'elmelo_menu_1';
				case 'serv': return 'elmelo_serv_1';

				case 'order': return 'elmelo_order_3';

				case 'biz_info': return 'elmelo_rat_1';
				case 'pay_disc': return 'elmelo_pay_discount_1';
				case 'soc_event': return 'elmelo_event_0';
				case 'soc_cal': return 'elmelo_cal_0';
				case 'soc_rsrv': return 'elmelo_rsrv_0';
				case 'notify': return 'elmelo_notify_2';
				case 'wallet': return 'elmelo_wallet_3';

				case 'mship': return 'elml_mship_1';
				case 'pass': return 'elml_pass_1';
				case 'pr_doc': return 'elml_pass_id_1';
				case 'pub_info': return 'elmelo_beta_pub_info_1';

                default: return null;
                }   // // switch
            }   // switch
		},

    //
    lambda: ( name, stage = elml.stage ) => {
            switch( stage )
            {
            case 'test':
                switch( name )
                {
				case 'unauth': return 'histreet-unauth-dev-app-CnNRpkBzKkF6'
                case 'public': return 'histreet-public'

				// case 'beta': return 'histreet-beta-dev-app-8A4MZAGLW94R';
				// case 'chat': return 'elmelo-chat-1-app-181NI3WP6LRY2';
				// case 'push': return 'elmelo-push-dev-app-VEZ60FHLP2ET';

				// case 'social': return 'elmeloScanPayment';
				// case 'pay': return 'test_elmeloPay_Stripe_v2';
				// case 'payv3': return 'elmeloPay_Stripe_v3';
				// case 'notify' : return "test_elmeloPay_Notify_v2";

                default: return null;
                }   // // switch

            case 'live':
            default:
                switch( name )
                {
				case 'unauth': return 'histreet-unauth-live-app-gXOpPIBoeQB2'
                case 'public': return 'histreet-public'

				// case 'beta': return 'histreet-beta-app-1WKNYNGXKOZ78';
				// // case 'beta': return 'histreet-beta-dev-app-8A4MZAGLW94R';

				// case 'push': return 'elmelo-push-app-H6T5Q7BEGLO1';
				// // case 'push': return 'elmelo-push-dev-app-VEZ60FHLP2ET'

				// case 'chat': return 'elmelo-chat-app-WVCFRU0LV7PK';
				// // case 'chat': return 'elmelo-chat-1-app-181NI3WP6LRY2'

				// case 'social': return 'elmeloScanPayment';
				// case 'pay': return 'test_elmeloPay_Stripe_v2';
				// case 'payv3': return 'elmeloPay_Stripe_v3';
				// case 'notify' : return "test_elmeloPay_Notify_v2";

                default: return null;
                }   // // switch
            }   // switch
		},

	chat: {
		bPush: true,
		bDb: true,
		bIoT: true,
	},

	iot: {
		platform: 'hs',

		prefix: 'a2gb0bhen4igg6-ats',
		accessKeyId: 'AKIAJ2KUM6DLJGPJWGUA',
		secretAccessKey: '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',

		policy: 'elml_chat',
	},

	push: {
		platform: 'hs',
	},

	buckets: {
		chat_photos: 'elmelo-chat/photos',
		chat_files: 'elmelo-chat/files',
	}
};

//
export default elml;
