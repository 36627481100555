/**
 *	@copyright	Elmelo Ltd.
 */

// import {AStorage} from '../api/Utils'
import Utils_Menu from '../../api/Utils_Menu'
import {Colour} from '../../api/Utils_UI'
import {DDB,} from '../../api/AWS'

// import * as Utils from './_utils'

// /**
//  */
// export const Menu_Init_Local = () =>
// {
//     return async ( dispatch, gs ) =>
//     {
//         try
//         {
//             const key_menu = Utils.Key( 'menu', gs() )

//             const menu_obj_stored = await AStorage.Get( key_menu )

//             if( menu_obj_stored )
//             {
//                 dispatch( MenuLoad( menu_obj_stored ) )
//             }

//             return { msg: 'OK' }
//         }
//         catch( err )
//         {
//             console.warn( 'actions/order: Menu_Init_Local: err: ', err )

//             // return Promise.reject( err )
//             return { err }
//         }
//     }   // return ...
// }   // Menu_Init_Local

/**
 */
export const RdxMenu_Init = ( biz_id , get_menu ) =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            dispatch( MenuIsLoading( true ) )

            const aws_ddb = new DDB( {} )

            /// todo compare with last update and download only when there is a change

            const menu_obj_stored = localStorage.getItem( Core_StoreId(gs, 'menu') )

            if( menu_obj_stored )
            {
                const fullMenu = JSON.parse( menu_obj_stored )

                const fullMenu_prep = Utils_Menu.Prep({...fullMenu}, gs().__biz.biz_id);

                const menu_sects = Order_PrepMenu(fullMenu, fullMenu_prep, 0/*data_menu.last_upd_menu*/);

                const menu_obj_new = {menu: menu_sects, menu_prep: fullMenu_prep, fullMenu: fullMenu}

                dispatch( MenuLoad( menu_obj_new ) )

                dispatch( MenuIsLoading( false ) )

            }   // if menu_obj_stored

            let arr_resp = [];

            let p_query = {
                    TableName: gs().__cfg.db('menu', gs().__cfg.stage),
                    KeyConditionExpression: '#a = :a',
                    // ProjectionExpression: '',
                    ExpressionAttributeNames: {'#a': 'biz_id'},
                    ExpressionAttributeValues: {':a': gs().__biz.biz_id},
                }

            while( true )
            {
                // console.log( 'actions/menu: RdxMenu_Init: p_query: ', p_query )

                const resp_query = await aws_ddb.Query( p_query )

                // console.log( 'actions/menu: RdxMenu_Init: resp_query: ', resp_query )

                arr_resp = [...arr_resp, ...resp_query.Items]

                if( !resp_query.LastEvaluatedKey )
                    break

                p_query.ExclusiveStartKey = resp_query.LastEvaluatedKey
            }   // while true

            const fullMenu = arr_resp.reduce( (a, c) => {
                const key_ = 'item' === c._t ? 'items'
                    :   'cat' === c._t ? 'cats'
                        :   'subcat' === c._t ? 'subcats'
                            :   'mod' === c._t ? 'mods'
                                :   'addon' === c._t ? 'addons'
                                    :   'tag' === c._t ? 'tags'
                                        :   'allergen' === c._t ? 'allergens'
                                            :   'ingredient' === c._t ? 'ingredients'
                                                :   'na'

                if( !a[key_] )
                {
                    // a[key_] = []
                    return a;
                }

                a[key_] = [...a[key_], c]

                return a;
            }, {format_st: 'v1', biz_id: gs().__biz.biz_id, items: [], cats: [], subcats: [], mods: [], addons: [], tags: [], allergens: [], ingredients: {}} );

            const fullMenu_prep = Utils_Menu.Prep({...fullMenu}, gs().__biz.biz_id);

            const menu_sects = /*await */Order_PrepMenu(fullMenu, fullMenu_prep, 0/*data_menu.last_upd_menu*/);

            const menu_obj_new = {menu: menu_sects, menu_prep: fullMenu_prep, fullMenu: fullMenu}


            dispatch( MenuLoad( menu_obj_new ) )

            dispatch( MenuIsLoading( false ) )

            localStorage.setItem( Core_StoreId(gs, 'menu'), JSON.stringify(fullMenu) )

            //
            return { msg: 'OK' }
        }
        catch( err )
        {
            console.warn( 'actions/menu: Menu_Init: err: ', err )

            dispatch( MenuIsLoading( false ) )

            return { err }
            // return Promise.reject( err )
        }
    }   // return ...
}   // Menu_Init

/**
 */
const Core_StoreId = (gs, id) =>
{
    return [gs().__biz.biz_id, id].join( ':' )
}

/** menu:load
 */
function MenuLoad( menu_obj )
{
    return {
        type: 'menu:load',
        payload: menu_obj,
    }
}

/** menu:load
 */
function MenuIsLoading( b_loading )
{
    return {
        type: 'menu:b_loading',
        payload: b_loading,
    }
}

/**
 */
function Order_PrepMenu(menu, menu_prep)
{
    let menu_obj = [], tmp_menu_obj = {};

    // console.log("menu", menu)
    // console.log("menu_prep", menu_prep)

    //
    try
    {
    menu.items.forEach(item => {
        try
        {
            const sec_id = [item.cat.id, item.subcat.id].join(':');

            if (!tmp_menu_obj[sec_id] && menu_prep.cats[item.cat.id] && menu_prep.subcats[item.subcat.id] )
            {
                // console.log( 'sec_id: ', sec_id );
                const col_ = Colour.FromStr([item.cat.id, item.subcat.id].join(':'));

                // const cat_obj = menu.

                tmp_menu_obj[sec_id] = {
                      data: []
                    , title: [item.cat.name, item.subcat.name].join(':')
                    , cat: item.cat.name
                    , sub_cat: item.subcat.name
                    , cat_id: item.cat.id
                    , sub_cat_id: item.subcat.id
                    , cat_idx: menu_prep.cats[item.cat.id].idx
                    , sub_cat_idx: menu_prep.subcats[item.subcat.id].idx
                    , col: col_
                    , col_inv: Colour.Invert(col_)
                    , avail:  menu_prep.subcats[item.subcat.id].avail ?  menu_prep.subcats[item.subcat.id].avail : {}
                    , vis:  menu_prep.subcats[item.subcat.id].vis ?  menu_prep.subcats[item.subcat.id].vis : {}
                    // , tag: Core_CreateTag(item.Sub_Cat)
                    };

                // console.log( sec_id );

                tmp_menu_obj[sec_id].data.push(item)
            }
            else
            {
                //
            }
        }
        catch( err )
        {
            console.warn( 'actions/menu: Order_PrepMenu: item: ', item )
            console.warn( 'actions/menu: Order_PrepMenu: menu_prep.cats[item.cat.id]: ', menu_prep.cats[item.cat.id] )
            console.warn( 'actions/menu: Order_PrepMenu: menu_prep.subcats[item.subcat.id]: ', menu_prep.subcats[item.subcat.id] )
            console.warn( 'actions/menu: Order_PrepMenu: err: ', err )
            throw err
        }
    })	// menu.items
    }
    catch( err )
    {
        //
    }

    // console.log( 'PrepMenu: tmp_menu_obj', tmp_menu_obj );

    //
    Object.keys(tmp_menu_obj).forEach(x => {
            menu_obj.push(tmp_menu_obj[x]);
        })

    // console.log( 'PrepMenu: menu_obj 1', menu_obj );

    // sort menu_obj
    menu_obj.sort((a, b) => {
            if (a.cat_idx < b.cat_idx)
                return -1;
            else if (a.cat_idx > b.cat_idx)
                return 1;
            else if (a.sub_cat_idx < b.sub_cat_idx)
                return -1;
            else if (a.sub_cat_idx > b.sub_cat_idx)
                return 1;
            else
                return 0;
        })

    // sort data in menu obj
    menu_obj = menu_obj.map(sect => {
        // console.log( 'sect: ', sect );

        sect.data.sort((a, b) => (a.idx - b.idx));

        // console.log( 'sect.data: ', sect.data );

        sect.data = sect.data.map((x, idx) => {
            x._v_idx = idx;
            x._cnt = 0;

            // console.log('x.Name: ', x, ', x.Mods', x.Mods ? 'true' : 'false', ', x.Mods.length: ');

            const col_ = Colour.FromStr(x._id);

            x.col = col_
            x.col_inv = Colour.Invert(col_)

            //
            if (x.setm_items)
                x._type = 'setmeal';
            else if (x.mod.name)
                x._type = 'mod';
            else
                x._type = 'basic';

            /// @todo check for mod_ex
            if ( x.secmods.length || x.addons.length )
            {
                x._type = 'mod_ex'
            }
            // if (x.Addons && x.Addons.length)
            // {
            //     x._type = 'mod_ex'
            // }

            /// @todo check for setmeal

            // modifiers
            if (x._type === 'mod' || x._type === 'mod_ex')
            {
                // console.log( '+++++++++++++ mod_ex: x: ', x );

                if (menu_prep.mods)
                {
                    x._mods = menu_prep.mods[x.mod.id] ?
                        JSON.parse(JSON.stringify(menu_prep.mods[x.mod.id]))
                        : null
                    ;

                    // if( !x._mods )
                    //     return mod;

                    // Making deep copies and initiating values ...
                    if (x._mods)
                    {
                        x._mods = {...x._mods};
                        x._mods.opts = [...x._mods.opts];
                        x._mods.opts = x._mods.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });
                    }   // if x._mods
                }   // if menu_prep.mods
            }   // if x._type === mod

            // secondary modifiers and addons
            if (x._type === 'mod_ex')
            {

                if (menu_prep.mods)
                {
                    x._mods = menu_prep.mods[x.mod.id] ?
                        JSON.parse(JSON.stringify(menu_prep.mods[x.mod.id]))
                        : null
                    ;

                    // if( !x._mods )
                    //     return mod;

                    // Making deep copies and initiating values ...
                    if (x._mods)
                    {
                        x._mods = {...x._mods};
                        x._mods.opts = [...x._mods.opts];
                        x._mods.opts = x._mods.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });
                    }   // if x._mods
                }   // if menu_prep.mods

                if (x.secmods && x.secmods.length)
                {
                    x._secmods = x.secmods.reduce((acc, sec_id) => {
                        if (!menu_prep.mods[sec_id.val])
                            return acc;

                        const sec_mod = {...menu_prep.mods[sec_id.val]};

                        // deep copy ...
                        sec_mod.opts = [...sec_mod.opts];
                        sec_mod.opts = sec_mod.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });

                        // return acc.push( sec_mod );
                        return [...acc, sec_mod];
                    }, []);
                }   // if menu_prep.sec_mods

                if (menu_prep.addons && x.addons && x.addons.length)
                {
                    x._addons = x.addons.reduce((acc, addon) => {
                        // addon = Utils.MD5([menu_prep.biz_id, addon, '0'].join(':'));

                        if (!menu_prep.addons[addon.val])
                            return acc;

                        const addon_obj = {...menu_prep.addons[addon.val]};
                        addon_obj.opts = [...addon_obj.opts];
                        addon_obj.opts = addon_obj.opts.map( opt => {
                            opt._cnt = 0;
                            return opt;
                        });

                        // return acc.push( addon_obj );
                        return [...acc, addon_obj];
                    }, []);
                }   // if menu_prep.addons

            }   // x._type === 'mod_ex'

            //
            return x;
        });

        // console.log( 'sect.data: ', sect.data );
        return sect;
    });

    menu_obj = menu_obj.map((x, idx) => {
        x.sect_idx = idx;

        return x;
    });

    return menu_obj;
}	// Order_PrepMenu




