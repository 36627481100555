/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    // Image,
    // Button,
    // Row,
    // Col,
    // Form,
    Modal, Alert
} from 'react-bootstrap'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import color from '../_common/colors.json'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import ContentLoader from "react-content-loader";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import elml_cfg from '../../_config/elml_cfg'
// import '../../css/_common.css'

import {Core,Lambda, Utils} from "../../api";

// import {TextInput} from "../_common/components"
// import {JS} from "aws-amplify";

// const wv_html = require('./addNewCard_web.html')
import {navigate} from "@reach/router";
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

/**
 */
class AddNew_Card extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props);

        this.state = {
            bRetreiving: true,
            intent: null,
            // srcIframe: '/addNewCard.html?'+'',
            client_name: '',
            client_secret: '',
        };
    }


    /**
     */
    render()
    {
        // console.log('AddNew_Card : render : props : ', this.props);
        // console.log('AddNew_Card : render : state : ', this.state);

        return (

            <div className="overlayModal">
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true}
                    onHide={() => this.props.hideModal()}
                    animation={true}
                    style={{zIndex: 1111111111,marginVertical:16}}
                    //className="cardViewPhone"
                >
                    {
                        this.state.bRetreiving
                            ? <div className="overlayContainer">
                                <ContentLoader
                                    height={160}
                                    width={200}
                                    speed={1}
                                    primaryColor="#f3f3f3"
                                    secondaryColor="#ecebeb"
                                >
                                    <rect x="0" y="13" rx="4" ry="4" width="400" height="9"/>
                                    <rect x="0" y="29" rx="4" ry="4" width="100" height="8"/>
                                    <rect x="0" y="50" rx="4" ry="4" width="400" height="10"/>
                                    <rect x="0" y="65" rx="4" ry="4" width="400" height="10"/>
                                    <rect x="0" y="79" rx="4" ry="4" width="100" height="10"/>
                                    <rect x="0" y="99" rx="5" ry="5" width="400" height="200"/>
                                </ContentLoader>
                            </div>

                            : this.state.intent
                            ? <div style={{marginVertical: 16}}>
                                <div>
                                    <h4 className="infoTitle" style={{margin:16}}>Add New Card</h4>
                                    <div
                                        onClick={() => this.props.hideModal()}
                                        className="overlayClose">
                                        <FontAwesomeIcon
                                            style={{marginLeft: 4, marginBottom: 4}}
                                            icon={faTimes} size="lg" color="#ef5350"/>
                                    </div>
                                </div>
                                {
                                    this.state.errMsg
                                        ? <Alert variant="danger"
                                                 onClose={() => this.setState({errMsg: ""})}
                                                 dismissible
                                        >
                                            {this.state.errMsg}
                                        </Alert>

                                        : null
                                }

                                <iframe
                                    // src="https://s3-eu-west-1.amazonaws.com/rat.elmelo.com/ratatouille/addNewCard_web.html"
                                    // src={'/addNewCard.html?'+'stage='+this.props.__cfg.stage}
                                    src={[
                                        '/addNewCard.html?' + 'stage=' + this.props.__cfg.stage,
                                        'client_name=' + this.state.client_name,
                                        'client_secret=' + this.state.client_secret,
                                    ].join('&')}
                                    originwhitelist={['*']}
                                    injectedjavascript={this.state.strJs}
                                    // javascriptenabled= "true"
                                    // domstorageenabled= "true"
                                    frameBorder="0"
                                    width="100%"
                                    height="350px"
                                />
                            </div>

                            : null
                    }
                </Modal>
            </div>
        )
    }   // render

    /**
     */
    componentDidMount()
    {
        window.addEventListener('message', this.OnMsg, false);

        this.Init()
            .then( data => {} )
            .catch( err => {} )
    }

    /**
     */
    OnMsg = ( event ) =>
    {
        // console.log( 'AddNewCard: OnMsg: event: ', event )

        const resp = JSON.parse( event.data )

        console.log( 'AddNewCard: OnMsg: resp: ', resp )

        if( resp.err )
        {
            let err_msg = 'Failed to add your card. Please contact your bank.'

            switch( resp.err.type )
            {
                case 'invalid_request_error':
                    switch( resp.err.code )
                    {
                        case 'setup_intent_authentication_failure':
                            err_msg = 'Card authentication failed. Please contact your bank.'
                            break
                        default:
                            err_msg = resp.err.message
                            break
                    }

                    break
                default:
                    err_msg = resp.err.message;
                    break
            }

            alert(
                err_msg,
                // [
                //     { text: 'OK', onPress: () => {
                //             this.setState( {bModal: false} )
                //             if( this.props.OnClose )
                //                 this.props.OnClose()
                //             if( this.props.hideModal )
                //                 this.props.hideModal()
                //             this.props.LoadCardList()
                //         }},
                // ],
                {cancelable: false}
            )

            this.setState( {bModal: false} )
            if( this.props.OnClose )
                this.props.OnClose()
            if( this.props.hideModal )
                this.props.hideModal()
            this.props.LoadCardList()

            console.error( "AddNewCard: OnMsg: err: ", resp );

            return {}
        }
        else
        {
            this.setState( {bModal: false} )

            this.props.LoadCardList()
                .then()
                .catch()
            this.props.hideModal()

            this.SendEmail()
                .then( data => {
                    // console.log( "then : ", data );
                } )
                .catch( err => {
                    // console.log( "err : ", err );

                } );
            // // console.log( "after" );

            return {}
        }
        // this.addNewCard(JSON.parse(event.nativeEvent.data))
    }

    /**
     */
    SendEmail = async () =>
    {
        try
        {
            const aws_core = new Core( {} )
            const aws_lambda = new Lambda()

            const cfg = this.props.__cfg
            const tmp_email = await aws_core.Email()

            const params = {
                usr: "beta",
                actType: "notify",
                act: "card:add",
                email_from: cfg.email_address,
                email_to: [ tmp_email.addr, ],
                msg_sub: "You've added a card on HiStreet.",
                msg_body: "You've added a card on HiStreet",
                stage: cfg.stage,
            };

            const resp = await aws_lambda.Invoke( params, cfg.lambda( 'beta', cfg.stage ) );

            if( resp.errorMessage )
            {
                throw new Error( resp.errorMessage )
            }

            return resp;
        }
        catch( err )
        {
            console.warn( 'AddNew_Card: SendEmail: err: ', err )

            return Promise.reject( err )
        }
    }   // SendEmail

    /**
     */
    Init = async () =>
    {
        try
        {
            this.setState( {bRetreiving: true} )

            const cfg = this.props.__cfg

            const aws_core = new Core()
            const aws_lambda = new Lambda()

            const name_obj = await aws_core.Name()
            const email_obj = await aws_core.Email()

            // console.log("name_obj", name_obj)
            // console.log("email_obj", email_obj)

            if(!email_obj.addr && ( !name_obj.first || !name_obj.last ) )
            {
                this.props.hideModal()
                this.submit( "Please add email address and name to add a card" );

                return
            }
            if(!email_obj.addr)
            {
                this.props.hideModal()
                this.submit( "Please add email address to add a card" );

                return

            }
            // if(email_obj.addr && !email_obj.bVerified)
            // {
            //     this.props.hideModal()
            //     this.submit( "Please verify email address" );
            //
            //     return
            //
            // }
            if(!name_obj.title || !name_obj.first || !name_obj.last)
            {
                this.props.hideModal()
                this.submit( "Please add name to add a card" );

                return
            }


            const p_lambda = {
                usr: 'beta',
                actType: 'pay',
                act: 'card:shared:add',
                stage: cfg.stage,
                email: email_obj.addr,
                name: [name_obj.title, name_obj.first, name_obj.last].join( ' ' ),
            }

            // console.log( 'AddNewCard: Init: p_lambda: ', p_lambda )

            const resp_lambda = await aws_lambda.Invoke( p_lambda, cfg.lambda( 'beta', cfg.stage ) )

            // console.log( 'AddNewCard: Init: resp_lambda: ', resp_lambda )

            if( resp_lambda.errorMessage )
            {
                throw new Error( resp_lambda.errorMessage )
            }


            // const str_js = this.InjectedJS_BeforeLoaded(
            //         resp_lambda.resp.client_secret,
            //         [name_obj.title, name_obj.first, name_obj.last].join( ' ' )
            //     )

            this.setState( {
                bRetreiving: false,
                // strJs: str_js,
                intent: resp_lambda.resp,
                client_name: [name_obj.title, name_obj.first, name_obj.last].join( ' ' ),
                client_secret: resp_lambda.resp.client_secret,
            } )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'AddNewCard: Init: err: ', err )

            this.setState( {bRetreiving: false} )

            return Promise.reject( err )
        }
    }   // Init

    submit = (title) =>
    {

        confirmAlert({
            title: title,
            message: 'From Profile',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => navigate('profile')
                }
            ]
        });

    };


    // /**
    //  */
    // InjectedJS_BeforeLoaded = ( client_secret, client_name ) =>
    // {
    //     // // console.log( 'AddNewCard: InjectedJS_BeforeLoaded: client_secret: ', client_secret )
    //     // // console.log( 'AddNewCard: InjectedJS_BeforeLoaded: client_name: ', client_name )

    //     const elml_stage = this.props.__cfg.stage

    //     const elml_pub_key = {
    //         live: 'pk_live_CsZHj0WJH3LXOllagfsTQVJO',
    //         test: 'pk_test_g9kLXsq7bwsQjllLFWMpJqEp',
    //     }

    //     const ret_str = `(function(){
    //         var hs_pk = '${elml_pub_key[elml_stage]}';
    //         var client_secret = '${client_secret}';
    //         var client_name = '${client_name}';

    //         Init( hs_pk, client_secret, client_name );

    //         true;
    //         })();
    //         `
    //     return ret_str
    // }   // InjectedJS
}   // AddNewCard

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(AddNew_Card);
