import React, { useState, useEffect } from "react";
import { CardElement, PaymentRequestButtonElement, useStripe, useElements } from "@stripe/react-stripe-js";

import elml_cfg from '../../_config/elml_cfg'
import {Lambda,} from '../../api/AWS'
import invoice from "./invoice";
import { Invoice_Save } from "../checkout/checkout_invoice";
import './invoice.css'

const CardForm = ( props ) =>
{
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	// const [clientSecret, setClientSecret] = useState('');

	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const elements = useElements();

	let card = null;
	let pr = null;
	// let clientSecret = null;

	useEffect(() =>
	{
	// 	// Create PaymentIntent as soon as the page loads
	// 	// window
	// 	// 	.fetch("/create-payment-intent", {
	// 	// 		method: "POST",
	// 	// 		headers: {
	// 	// 			"Content-Type": "application/json"
	// 	// 		},
	// 	// 		body: JSON.stringify({ items: [{ id: "xl-tshirt" }] })
	// 	// 	})
	// 	// 	.then(res =>
	// 	// 	{
	// 	// 		return res.json();
	// 	// 	})
	// 	// 	.then(data =>
	// 	// 	{
	// 	// 		setClientSecret(data.clientSecret);
	// 	// 	});

	});


	useEffect(() => {
		if (stripe)
		{
			makePayment()
				.then()
				.catch( err => {
					//
				} );
		}
	}, [stripe]);

	const makePayment = async () =>
	{
		try
		{
			pr = stripe.paymentRequest({
				country: 'GB',
				currency: 'gbp',
				total: {
					label: props.invoice.b_name ? props.invoice.b_name : '',
					amount: props.invoice.a_tot,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			});

			const result = await pr.canMakePayment();

			if( result )
			{
				setPaymentRequest(pr);
			}

			pr.on( 'paymentmethod', OnPM );

			return {}
		}
		catch( err )
		{
			console.warn( 'CardForm: makePayment: err: ', err )

			return {err}
		}
	}	// makePayment

	const OnPM = async ( ev ) =>
	{
		try
		{
			const clientSecret = await createPaymentIntent();

			console.log( "!!!!!!!!!!!!!!!!!! ev : ", ev );
	
			if( ev.paymentMethod.card.country !== "GB" )
			{
				setError(`Payment failed. Only GB cards are accepted.`);
				setProcessing(false);
				return;
			}

			// Confirm the PaymentIntent without handling potential next actions (yet).
			const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
				clientSecret,
				{payment_method: ev.paymentMethod.id},
				{handleActions: false}
			);

			if (confirmError)
			{
				// Report to the browser that the payment failed, prompting it to
				// re-show the payment interface, or show an error message and close
				// the payment interface.
				ev.complete('fail');
			}
			else
			{
				// Report to the browser that the confirmation was successful, prompting
				// it to close the browser payment method collection interface.
				ev.complete('success');
				// Check if the PaymentIntent requires any actions and if so let Stripe.js
				// handle the flow. If using an API version older than "2019-02-11"
				// instead check for: `paymentIntent.status === "requires_source_action"`.
				if (paymentIntent.status === "requires_action")
				{
					// Let Stripe.js handle the rest of the payment flow.
					const {error} = await stripe.confirmCardPayment(clientSecret);
					if (error)
					{
						// The payment failed -- ask your customer for a new payment method.
					}
					else
					{
						// The payment has succeeded.
					}
				}
				else
				{
					const _paySuccess = await paySuccess( paymentIntent.id );
					const _invoiceUpd = await invoiceUpd();

					const _sendpush = SendPush()
						.then()
						.catch()

					props.onSuccess( {...props.invoice, paid_status: "paid"} );
					// The payment has succeeded.
				}
			}

			return {}
		}
		catch( err )
		{
			console.warn( 'CardForm: OnPM: err: ', err )

			return {err}
		}
	}	// OnPM

	const createPaymentIntent = async() =>
	{
		try
		{
			const aws_lambda = new Lambda( {} );

			const p_lambda =
			{
				stage: elml_cfg.stage,
				actType: "pay",
				act: "charge:guest:card",

				invoice_uid: props.invoice.uid,
				dt_origin: Date.now(),

				alpha: {
					biz_id: props.invoice.biz_id,
					biz_name: props.invoice.b_name
				},

				trans: {
					amount: props.invoice.a_tot,
					currency: 'GBP',
					desc: props.invoice.b_name
				},

				payment_type: "card",
				invoice: props.invoice,
			};

			const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );

			return resp_lambda.client_secret;
		}
		catch( err )
		{
			console.error( "createPaymentIntent : catch : err : ", err );
		}
	}

	const cardStyle = {
		iconStyle: 'solid',
		style: {
			base: {
				iconColor: '#ddd',
				color: '#000',
				fontWeight: 400,
				fontFamily: 'Open Sans,Roboto, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				margin:'16px',
				height:40,
				backgroundColor:"#F5F5F5",
				boxShadow:"1px 1px 1px #000",

				':-webkit-autofill': {
				color: '#0C4AAC',
				},
				'::placeholder': {
				color: '#707070',
				},
			},
			invalid: {
				iconColor: '#dd0000',
				color: '#dd0000',
			},
		}
	};

	const handleChange = async (event) =>
	{
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleSubmit = async event =>
	{
		event.preventDefault();
		setProcessing(true);

		const paymentIntent = await createPaymentIntent();

		const paymentMethod = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement)
		});

		if( paymentMethod.paymentMethod.card.country !== "GB" )
		{
			setError(`Payment failed. Only GB cards are accepted.`);
		 	setProcessing(false);
			return;
		}

		const payload = await stripe.confirmCardPayment(paymentIntent, {
			payment_method: paymentMethod.paymentMethod.id
		});

		if (payload.error)
		{
			setError(`Payment failed. ${payload.error.message}`);
			setProcessing(false);
			return;
		}
		else
		{
			const _paySuccess = await paySuccess( payload.paymentIntent.id, payload.paymentIntent.payment_method );

			const _invoiceUpd = await invoiceUpd();

			const _sendpush = SendPush()
				.then()
				.catch()

			setError(null);
			setSucceeded(true);
			setProcessing(false);

			props.onSuccess( {...props.invoice, paid_status: "paid", payment_success: true} );
		}
	};

	const SendPush = async () =>
	{
		try
		{
			const aws_lambda = new Lambda( {} );

			const p_lambda = {
				stage: elml_cfg.stage,
				actType: "notify",
				act: "notify:push",
				b_push: true,
				pub_id_list: [props.invoice.biz_id],
				sender: props.invoice.invc_cus.name,
				msg: /*props.invoice.invc_cus.name + */'Just settled your invoice',
				ch_id: props.invoice.ch_id ? props.invoice.ch_id : '',
				data: {
					inv_uid: props.invoice.uid,
					biz_id: props.invoice.biz_id,
					paid_status: 'paid',
					type: 'invoice-paid'
				}
			};
			// console.log( "invoice2 : SendPush : p_lambda : ", p_lambda );

			const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );

			// console.log( "invoice2 : SendPush : resp_lambda : ", resp_lambda );

			return resp_lambda;
		}
		catch( err )
		{
			console.error( "CardForm : SendPush : catch : ", err );
		}
	}

	const paySuccess = async (pi, pm) =>
	{
		try
		{
			const aws_lambda = new Lambda( {} );

			const p_lambda = {
				stage: elml_cfg.stage,
				actType: "pay",
				act: "charge:successful",

				alpha: {
					biz_id: props.invoice.biz_id,
					biz_name: props.invoice.b_name
				},

				uid: props.invoice.uid,
				dt_create: invoice.dt_create,
				dt_origin: Date.now(),

				payment_type: "card",

				trans: {
					amount: props.invoice.a_tot,
					currency: 'GBP',
					desc: props.invoice.b_name
				},

				ext: {
					device: "web"
				},

				invoice: props.invoice,

				pi: pi,
			};

			const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );

			return resp_lambda;
		}
		catch( err )
		{
			console.error( "CardForm : paySuccessful : catch : ", err );
		}
	}

	const invoiceUpd = async () =>
	{
		try
		{
			const aws_lambda = new Lambda( {} );

			const p_lambda = {
				stage: elml_cfg.stage,
				actType: "invoice",
				act: "invoice:status_upd",

				biz_id: props.invoice.biz_id,
				dt_create: props.invoice.dt_create
			};

			const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );

			return resp_lambda;
		}
		catch( err )
		{
			console.error( "CardForm : invoiceUpd : catch : ", err );
		}
	}

	// if (paymentRequest)
	// {
	// 	return
	// }

	return (
		<>
			{
				paymentRequest &&
					<div style={{width: "95%", marginRight: "auto", marginLeft: "auto", marginBottom: 16}}>
						<PaymentRequestButtonElement options={{paymentRequest}}/>
					</div>
			}

			<div>
				Pay with card
			</div>

			<form id="payment-form" /* onSubmit={ handleSubmit } */>
				<div className="cardWrapper">
					<CardElement id="card-element" options={ cardStyle } onChange={ handleChange } />
				</div>
				{ error && (
					<div
						style={{color:"#c94b4b",fontSize:18,padding:8,fontWeight:600}}
						className="card-error" role="alert">
						{ error }
					</div>
				) }

				<button
					style={{border:"none", backgroundColor:"#FFF", width:"95%"}}
					disabled={ processing || disabled || succeeded }
					id="submit"
					// onClick={handleSubmit}
				>
					{
						processing
						?
							<div>Processing</div>
						:
						// <div>	// @todo make button disabled when no card
						// 	{
						// 		processing || disabled || succeeded
						// 		?
								<div
									// type="submit"
									className="payButton"
									id="button-text"
									onClick={handleSubmit}
								>
									<div className="getBtnText">
										Pay
									</div>
								</div>
								// :
								// <div>Pay</div>
						// 	}
						// <div/>
					}
				</button>
			</form>

		</>

		// <form id="payment-form" onSubmit={ handleSubmit }>
		// 	<CardElement id="card-element" options={ cardStyle } onChange={ handleChange } />
		// 	<button
		// 		disabled={ processing || disabled || succeeded }
		// 		id="submit"
		// 	>
		// 		<span id="button-text">
		// 			{ processing ? (
		// 				<div className="spinner" id="spinner"></div>
		// 			) : (
		// 				"Pay now"
		// 			) }
		// 		</span>
		// 	</button>
		// 	{/* Show any error that happens when processing the payment */ }
		// 	{ error && (
		// 		<div className="card-error" role="alert">
		// 			{ error }
		// 		</div>
		// 	) }
		// 	{/* Show a success message upon completion */ }
		// 	<p className={ succeeded ? "result-message" : "result-message hidden" }>
		// 		Payment succeeded, see the result in your
		// 		<a
		// 			href={ `https://dashboard.stripe.com/test/payments` }
		// 		>
		// 			{ " " }
		// 			Stripe dashboard.
		// 		</a> Refresh the page to pay again.
		// 	</p>
		// </form>
	);

	const SendEmail = async () =>
	{
		try
		{
			const aws_lambda = new Lambda( {} );

			const p_lambda = {
				actType: "notify",
				act: "notify:mail",
				email_from: elml_cfg.email_address,
				email_to: ['sshawon95@gmail.com'],
				msg_sub: "Invoice Payment.",
				msg_body: "Invoice payment done",
				stage: elml_cfg.stage,
			};

			// const p_lambda = {
			// 	actType: "notify",
			// 	act: "notify:push:mail",
			// 	email_from: elml_cfg.email_address,
			// 	email_to: ['sshawon95@gmail.com'],
			// 	msg_sub: "Invoice Payment.",
			// 	msg_body: "Invoice payment done",
			// 	stage: elml_cfg.stage,
			// 	b_push: true,
			// 	pub_id_list: [props.invoice.biz_id],
			// 	sender: props.invoice.invc_cus.name,
			// 	msg: 'Invoice Payment Update',
			// 	ch_id: props.invoice.ch_id ? props.invoice.ch_id : '',
			// 	data: {
			// 		inv_uid: props.invoice.uid,
			// 		biz_id: props.invoice.biz_id,
			// 		paid_status: 'paid',
			// 		type: 'invoice-paid'
			// 	}
			// };

			console.log( "invoice2 : SendEmail : p_lambda : ", p_lambda );


			const resp_lambda = await aws_lambda.Invoke( p_lambda, elml_cfg.lambda('unauth', elml_cfg.stage) );

			console.log( "invoice2 : SendEmail : resp_lambda : ", resp_lambda );

			return resp_lambda;
		}
		catch( err )
		{
			console.error( "CardForm : notifyInvoice : catch : ", err );
		}
	}
}

export default CardForm;
