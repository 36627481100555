/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
export default ( state = {
    obj: {}, sects: [],
    totalCnt: 0, totalPrice: 0,
    iot: null,
    usrs: [],
    iot_dt: {}
}, action ) =>
{
    switch( action.type )
    {
    case 'cart:load':
        return { ...state, ...action.payload  }

    case 'cart:total':
        return { ...state, totalCnt: action.payload.cnt, totalPrice: action.payload.price }

    case 'cart:grp:iot':
        return { ...state, iot: action.payload }

    case 'cart:grp:usrs':
        return { ...state, usrs: action.payload }

    case 'cart:grp:iot:dt':
        return { ...state, iot_dt: action.payload }

    default:
        return state
    }   // switch ...
}




