/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { navigate } from "@reach/router"

import {Image} from "react-bootstrap";
import './invoice.css'

// import {
//     isIOS,
//     isAndroid,
//     isMacOs
// } from "react-device-detect";
// import {digits} from "mathjs/es/utils/number";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

/**
 */
class Invoice extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
            meta: null
        }
    }

    /**
     */
    render()
    {
        return (
            <div style={{display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
                <Image className="hsImage" src={require('../assets/imageIcon/hs.png')}/>
                <div className="localText">Stay local, pay safely</div>
                <div className="invoiceBg">
                    <>
                        {
                            this.state.meta
                                ?   <div className="outerDiv">
                                    <div className="innerDiv">
                                        <div style={{display:"flex",flexDirection:"row"}}>
                                            <div className="nameDiv">
                                                <div className="bigText">{this.state.meta.bizName ? this.state.meta.bizName : ''}</div>
                                                <div className="smallText">
                                                    {
                                                        this.state.meta.bizAdr ?
                                                            this.state.meta.bizAdr.line1 + '' + this.state.meta.bizAdr.line2 + ','
                                                            : ''
                                                    }
                                                </div>

                                                <div className="smallText">
                                                    {
                                                        this.state.meta.bizAdr ? [
                                                            this.state.meta.bizAdr.county,
                                                            this.state.meta.bizAdr.postcode,
                                                        ].join(', ') : ''
                                                    }
                                                </div>

                                            </div>
                                            <div style={{paddingRight: 8, textAlign: "right"}}>
                                                <div className="smallBoldText">{this.state.date.toLocaleDateString("en-UK")}</div>
                                                <div className="smallBoldText">{this.state.meta.dtCreate}</div>
                                            </div>
                                        </div>
                                        {
                                            this.state.meta.items
                                                ?
                                                <div>
                                                    <div className="itemGradient">
                                                        <div className="bigTextCenter">Item Description</div>
                                                    </div>
                                                    {
                                                        this.state.meta.items.map((item,idx)=>(

                                                            <div className="itemText" key={idx}>
                                                                {item.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                null
                                        }


                                    </div>

                                    <div className="outerText"> <span style={{color:"#0C4AAC",fontWeight:"700"}}>{this.state.meta.bizName ? this.state.meta.bizName : ''}</span> sent you an invoice.</div>
                                    <div className="outerText">To view in full and pay securely, get the HiStreet app.</div>
                                </div>

                                :   null
                        }
                    </>

                    <div
                        type="submit"
                        onClick={ this.OnClick }
                        className="invoiceBtn">
                        <div className="getBtnText">
                            Open HiStreet
                        </div>
                    </div>
                </div>
            </div>
        )
    }   // render


    /**
     */
    componentDidMount()
    {
        // alert( 'params: this.props.beta_id: ', this.props.beta_id )
        // alert( 'params: rsrv_dt: ', this.props.rsrv_dt )
        // alert( 'params: meta: ', this.props.meta )

        this.GetFullLink()
            .then( data => {} )
            .catch( data => {} )

        console.log( 'params: this.props: ', this.props )
        console.log( 'params: this.props.beta_id: ', this.props.beta_id )

        if( !this.props.meta /*|| !this.props.rsrv_dt || !this.props.meta*/ )
        {
            alert( 'All the parameters are not found' )

            return {}
        }

        try
        {
            // console.log("this.isMacOs", isMacOs)
            console.log("Invoice: componentDidMount: this.props: ", this.props)

            // const data = this.props.meta && typeof this.props.meta? JSON.parse(decodeURI(this.props.meta)) : null;
            const meta_obj = JSON.parse(decodeURI(this.props.meta))

            console.log("Invoice: componentDidMount: meta_obj: ", meta_obj)

            // console.log("data", encodeURI(this.props.meta));

            // const _date =  new Date(data.dtCreate ? data.dtCreate * 1000: '');
            const date_obj = new Date(meta_obj.dtCreate * 1000)

            console.log("Invoice: componentDidMount: date_obj: ", date_obj)

            this.setState( {meta: meta_obj, date: date_obj} )
        }
        catch( err )
        {
            alert( 'Something wrong with meta' )
        }
    }   // componentDidMount

    /**
     */
    GetFullLink = async () =>
    {
        try
        {
            return {msg: 'OK'}
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }
    /**
     */
    OnClick = async () =>
    {
        if(this.state.meta)
        {

            const data = encodeURI(JSON.stringify({uid: this.state.meta}))

            const link = `https://histreet-app.com/invoice/?data:${data}`

            const deep_link = `https://histreet.page.link/?link=${link}&isi=1477813443&ibi=com.histreetapp.app&apn=com.histreet.app&ofl=https://histreet-app.com/invoice/${data}`

            // console.log("deep_link",deep_link)

            navigate(encodeURI(deep_link))

            // const link = `${'https://histreet-app.com/invoice/'+encodeURI(JSON.stringify(this.state.meta))}`
            //
            // const deep_link = `https://histreet.page.link/?link=${link}&ifl=https://testflight.apple.com/join/Hnhxjs9z&ibi=com.histreetapp.app&ofl=${link}`
            //
            // // console.log("deep_link",deep_link)
            //
            // navigate(deep_link)

        }
    }
}   // class Invoice

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Invoice )


