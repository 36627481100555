/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import AuthLogin from './Login'
import AuthSignIn from './SignIn'
import AuthSignUp from './SignUp'

import AuthVerification from './Verification'
import AuthForgotten from './Forgotten'
import AuthLogout from './Logout'

/**
 */
class App_Auth extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <AuthLogin path='/auth/login' default/>
                <AuthSignIn path='/auth/signin' />
                <AuthSignUp path='/auth/signup' />

                <AuthVerification path='/auth/verification' />
                <AuthForgotten path='/auth/forgotten' />
                <AuthLogout path='/auth/logout' />
            </Router>
            )
    }   // render

    /**
     */
    componentDidMount()
    {
        // console.log( 'App_Auth: componentDidMount: st' )
    }
}   // class App_Auth

/**
 */
export {
    // App_Auth,
    AuthLogin, AuthSignIn, AuthSignUp, AuthVerification, AuthForgotten, AuthLogout,
}
