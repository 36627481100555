/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faShoppingCart,
    faShoppingBag,
    faStore,
    faListUl,
    faPlusSquare,
    faTrashAlt,
    faTimes,
    faPlus,
    faMinus,
    faTruckMoving,
    faMoneyBill
} from '@fortawesome/free-solid-svg-icons'

import {Image, Modal} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import Hdr from  '../_common/instoreHdr'
import color from '../_common/colors.json'
import './css/order.css'
// import{Item_div} from './_common'
import {MenuPage_Box} from './_common'
import Item from './items'
import Order_Cart from './cart'

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faSearch,faLocationArrow,faClock,faStar,faUser,faPhone,} from '@fortawesome/free-solid-svg-icons'
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import Loader from 'react-loader-spinner'

/**
 */
class Order_Home extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        // console.log( 'Order_Home: cstr: this.props: ', this.props )

        this.state={
                bizId: null,//this.props.bizId,
                showCategory: false,
                showCart: false,
                showType: false,
                promoModal: true,
                orderType: this.props.orderType ? this.props.orderType : 'collection',
                subCatRefs: [],
                sHeight:window.innerHeight-72,
                sWidth:window.innerWidth,
            }

        // console.log( 'Order_Home: cstr: this.state: ', this.state )
    }
    updateWidthAndHeight = () => {
        this.setState({sWidth:window.innerWidth});
        this.setState({sHeight:window.innerHeight});
    };

    showCategory = (val) =>{
        this.setState({showCategory:val})
        val?
            document.body.classList.add('bodyOf') :
            document.body.classList.remove('bodyOf')
    }

    showCart = (val) =>{
        this.setState({showCart:val})
        console.log("showcart",val);
        val?
            document.body.classList.add('bodyOff') :
            document.body.classList.remove('bodyOff')
    }

    showPromo = (val) =>{
        this.setState({promoModal:val,showType:true})
    }

    showType = (val) =>{
        this.setState({showType:val})
    }

    scrollToSubcat = subcatID =>
    {
        this.subCatRefs[subcatID].current.scrollIntoView( {
            behavior: 'smooth',
            block: 'start',
        } );
        // this.setState({showCategory:false})
    }
    /**
     */
    componentWillMount()
    {
        window.removeEventListener('resize',this.updateWidthAndHeight)

    }

    /**
     */
    render()
    {
        console.log( 'Order_Home: render: this.props: ', this.props )
        // console.log( 'Order_Home: render: this.state: ', this.state.showCart )

        // if( this.state.bizId !== this.props.bizId )
        // {
        //     this.setState( {bizId: this.props.bizId}, () => this.Init( this.props.bizId ) )
        // }
        if(this.state.promoModal)
        {
            document.body.classList.add('bodyOf')
        }
        if(this.state.showType)
        {
            document.body.classList.add('offBody')
        }
        if(!this.state.promoModal)
        {
            document.body.classList.remove('bodyOf')
        }
        if(!this.state.showType)
        {
            document.body.classList.remove('offBody')
        }
        // if( !this.state.bizId )
        // {
        //     return (
        //         <div>Please go to search and choose a business</div>
        //         )
        // }

        if( 'inprogress' === this.props.__order.initStatus || this.props.__biz.initStatus === 'inprogress' || this.props.__menu.bLoading )
        {
            return(
                <div>
                    <div className="centerScreen" style={{height:this.state.sHeight}}>
                        <Loader
                            type="Bars"
                            color="#C94B4B"
                            height={this.state.sHeight/3}
                            width={this.state.sWidth/3}
                            timeout={30000000}

                        />
                    </div>
                </div>

            )
        }

        if(!this.props.__menu.menu )
        {
            return (
                <div>Sorry we have not added menu yet.</div>
                )
        }

        if( !this.refsCreated && this.props.__menu.menu )
        {
            this.subCatRefs = this.props.__menu.menu.reduce((acc, value) => {
                acc[ [value.cat_id , value.sub_cat_id].join(":") ] = React.createRef();
                return acc;
            }, {});

            this.refsCreated = true;
        }

        // return (
        //     <div>
        //         <Hdr/>
        //         <div className="commonFlex">
        //             <Row className="itemSection">
        //                 <Item_div
        //                     itemName="Mutton Curry"
        //                     itemPrice=" $ 12.59"
        //                     desc="an authentic dish from our own chef cooked with mixed vegetables, garlic"
        //                     tags="spicy"
        //                     allergies="peanut"
        //                     addBtn={true}
        //                 />
        //                 <Item_div
        //                     itemName="Chicken Masala"
        //                     mod={true}
        //                     tags="spicy"
        //                     allergies="peanut"
        //                     addBtn={true}
        //                 />
        //                 <Item_div
        //                     itemName="Pilao Masala"
        //                     itemPrice=" $ 12.59"
        //                     tags="hot"
        //                     allergies="cummins"
        //                     addBtn={false}
        //                 />
        //             </Row>
        //             <div className="cartSection">cart</div>
        //         </div>
        //     </div>
        //     )
        return (
            <div style={{paddingTop: 0}}>
                {/* <NavHdr order={true}/> */}
                {/*<Hdr/>*/}
                <Hdr bizName={this.props.__biz.biz_title}/>
                <div className="orderWrapper">
                    <div className="orderContainer">
                        <>
                        {/*{   // left categories*/}
                        {/*    this.state.showCategory*/}
                        {/*?   <div className="overlayModal">*/}
                        {/*        <div className="phoneCat">*/}
                        {/*            <div className="commonFlex phoneCatHead">*/}
                        {/*                <div>*/}
                        {/*                    Category*/}
                        {/*                </div>*/}
                        {/*                <div*/}
                        {/*                    className="catClose"*/}
                        {/*                    onClick={() => this.showCategory(false)}>*/}
                        {/*                    <FontAwesomeIcon icon={faTimes} color="red" size="lg"/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}

                        {/*            <div style={{marginTop:46,paddingLeft:16,paddingRight:16}}>*/}
                        {/*            {*/}
                        {/*                this.props.__menu.menu*/}
                        {/*            ?   this.props.__menu.menu.map((sect, sect_idx) => (*/}
                        {/*                    <MenuPage_Box*/}
                        {/*                        className="categoryDiv"*/}
                        {/*                        style={{borderColor: color.colors.light}}*/}
                        {/*                        key={[sect.cat_id, sect.sub_cat_id].join(':')}*/}
                        {/*                        onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"))}*/}
                        {/*                        title={sect.title}*/}
                        {/*                    />*/}
                        {/*                ))*/}

                        {/*            :   null*/}
                        {/*            }*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*:   <div className="categoryContainer"*/}
                        {/*        style={{height: this.state.catheight}}*/}
                        {/*    >*/}
                        {/*    {*/}
                        {/*        this.props.__core.menu && this.props.__core.menu.menu*/}
                        {/*    ?   this.props.__core.menu.menu.map((sect, sect_idx) => (*/}
                        {/*            <MenuPage_Box*/}
                        {/*                className="categoryDiv"*/}
                        {/*                style={{borderColor: color.colors.light}}*/}
                        {/*                key={[sect.cat_id, sect.sub_cat_id].join(':')}*/}
                        {/*                onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"))}*/}
                        {/*                title={sect.title}*/}
                        {/*            />*/}
                        {/*        ))*/}

                        {/*    :   null*/}
                        {/*    }*/}
                        {/*    </div>*/}
                        {/*}*/}

                            {
                                this.state.promoModal ?
                                    <div onClick={() => this.showPromo(false)} className="overlayPromo">
                                        <div className="promo">
                                            <div className="promoBody">
                                                <div style={{padding: 16}}>
                                                    <div
                                                        className="closePromo"
                                                        onClick={() => this.showPromo(false)}>
                                                        <FontAwesomeIcon
                                                            icon={faTimesCircle} size="2x"
                                                            color={"#fff"}
                                                        />
                                                    </div>
                                                    <Image style={{marginTop: 16, marginBottom: 16}}
                                                           src={require('./image/mobile.png')}/>
                                                    <div className="promoLargeText">Order using HiStreet app to enjoy advanced features like group order and many others.</div>
                                                    <div className="common" style={{justifyContent:"center",alignItems:"center"}}>
                                                        <a target="_blank"
                                                           href="https://play.google.com/store/apps/details?id=com.histreet.app">
                                                            <Image className="gapPromo"
                                                                   src={require('./image/google.png')}/>
                                                        </a>
                                                        <a target="_blank"
                                                           href="https://apps.apple.com/gb/app/histreetapp/id1477813443">
                                                            <Image className="gapPromo"
                                                                   src={require('./image/ios.png')}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    null
                            }

                            {
                                this.state.showType ?
                                    <div className="overlayPromo">
                                        <div className="promo">
                                            <div className="typeBody">
                                                {/* <div className="closePromo"
                                                     onClick={() => this.setState({showType: false})}>
                                                    <FontAwesomeIcon
                                                        icon={faTimesCircle} size="2x"
                                                        color={"red"}
                                                    />
                                                </div> */}
                                                <div className="common">
                                                    <div className="typeBox">
                                                        <FontAwesomeIcon
                                                            style={{margin: 8}}
                                                            icon={faTruckMoving} size="2x"
                                                            color={"#b7b7b7"}
                                                        />
                                                        <div className="typeText">Delivery</div>
                                                    </div>
                                                    <div className="typeBox">
                                                        <FontAwesomeIcon
                                                            style={{margin: 8}}
                                                            icon={faShoppingBag} size="2x"
                                                            color={"#b7b7b7"}
                                                        />
                                                        <div className="typeText">Collection</div>
                                                    </div>
                                                    <div className="typeBox"
                                                         style={{background: "linear-gradient(to right, #C94B4B, #4B134F)"}}>
                                                        <FontAwesomeIcon
                                                            style={{margin: 8}}
                                                            icon={faStore} size="2x"
                                                            color={"#fff"}
                                                        />
                                                        <div className="typeText" style={{color:"#fff"}}>InStore</div>
                                                    </div>

                                                </div>
                                                <div className="processTabSelect">
                                                    <FontAwesomeIcon style={{marginTop:5}} icon={faStore} size="lg" color="#C94B4B"/>
                                                    <div className="processText" style={{color: "#C94B4B"}}>Select Table Number</div>
                                                </div>

                                                <div className="storeTableBox">
                                                    {
                                                        this.props.__biz.tbl_no
                                                        ? this.RenderTables(this.props.__biz.tbl_no)
                                                        : null

                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div> :
                                    null
                            }

                            {
                                this.state.showCategory ?
                                    <Modal show={true} onHide={() => this.showCategory(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title className="categoryLarge">Category</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="categoryBody">
                                            {
                                            this.props.__menu.menu
                                            ?   this.props.__menu.menu.map((sect, sect_idx) => (
                                                    <MenuPage_Box
                                                        className="categoryDiv"
                                                        style={{borderColor: color.colors.light}}
                                                        key={[sect.cat_id, sect.sub_cat_id].join(':')}
                                                        onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"))}
                                                        title={sect.title}
                                                    />
                                                )) :
                                                null
                                            }
                                        </Modal.Body>
                                    </Modal> :
                                    null
                            }

                        </>

                        {/*middle menu items*/}
                        <div className= "csItemRightAfter">
                            <div className="itemContainer">
                                <>
                                {
                                //     this.props.__menu.menu
                                // ?   this.props.__menu.menu.map((sect, sect_idx) => (
                                    this.props.__menu.menu.map((sect, sect_idx) => (
                                        // <h4 style={{color: color.colors.primary, marginBottom: 20}}>{sect.title}</h4>
                                        <div
                                            key={[sect.cat_id, sect.sub_cat_id].join(':')}
                                            ref={this.subCatRefs[[sect.cat_id, sect.sub_cat_id].join(":")]}
                                        >
                                            {/*{*/}
                                            {/*    console.log(sect)*/}
                                            {/*}*/}
                                            <MenuPage_Box
                                                className="itemDiv"
                                                id={sect.sub_cat_id}
                                                title={sect.title}
                                                key={sect.sub_cat_id}
                                                item={
                                                    sect.data.map((item, item_idx) => (
                                                        // "t" === item.vis && item.vis.online ? item.vis.online : (item.vis && item.vis.def ? item.vis.def : null) ?
                                                        //console.log(item.vis && item.vis.online ? item.vis.online : (item.vis && item.vis.def ? item.vis.def : null),item.name):
                                                        //     :null

                                                        "t" === (item.vis && item.vis.online ? item.vis.online : (item.vis && item.vis.def ? item.vis.def : null) )
                                                    ?   "t" === (item.avail && item.avail.online ? item.avail.online : (item.avail && item.avail.def ? item.avail.def :null))
                                                    ?   <div
                                                                style={{marginLeft:8,marginRight:8}}
                                                                key={item._id}>
                                                            <Item
                                                                item={item} item_idx={item_idx}
                                                                sect={sect}
                                                                orderType={this.state.orderType}
                                                                addToCart={ (item, sect, cnt) => {
                                                                        console.log("addToCart :item :", item)
                                                                        console.log("addToCart :sect :", sect)
                                                                        console.log("addToCart :cnt :", cnt)
                                                                        this.props.RdxOrder_AddToCart( item, sect, cnt )
                                                                            .then( data => {} )
                                                                            .catch( err => {} )
                                                                    } }
                                                            />
                                                        </div>

                                                        :   null
                                                        :   null
                                                    ))
                                                }
                                            />
                                        </div>
                                    ))

                                // :   null
                                }
                                </>

                                {/*phone cart and category */}
                                <div
                                    onClick={() => this.showCart(true)}
                                    className="cartMobile"
                                    style={{background: "linear-gradient(to right, #C94B4B, #4B134F)"}}
                                >

                                    <div className="cartTotalDiv">
                                        Total
                                        <span style={{
                                            fontSize: 20,
                                            marginLeft: 10,
                                            letterSpacing: 0.5,
                                            fontWeight: 700
                                        }}>
                                            £{this.props.__cart.totalPrice.toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="cartIcon">
                                        <FontAwesomeIcon
                                            icon={faShoppingCart} size="lg"
                                            color={"#C94B4B"}
                                        />
                                        <div className="cartCount">{this.props.__cart.totalCnt}</div>
                                    </div>
                                </div>

                                <div
                                    onClick={() => this.showCategory(true)}
                                    className="categoryFloat"
                                >
                                    <FontAwesomeIcon style={{marginRight: 5}} icon={faListUl} size="xs"
                                                     color={color.colors.primary}
                                    />
                                    category
                                </div>
                            </div>
                        </div>
                        <div
                             className={this.state.showCart ? "mobileCart" : "cartContainer"}
                        >
                             <Order_Cart
                                 showCart={this.showCart}
                                 orderType={this.state.orderType}
                             />
                        </div>

                    </div>
                </div>
            </div>
            )
    }
    /**
     */
    RenderTables = (tbl_no) =>
    {
        let all_tables = [];

        for( let i=1; i<= tbl_no; i++ )
        {
            all_tables.push(
                <div className="storeTable" onClick={()=>{

                    this.props.Rdx_Instore_Table(i)
                    this.setState({showType: false})
                }}>
                    <div className="tableText">{i}</div>
                </div>
            )
        }	// for i

        return all_tables
    }	// RenderTables

    /**
     */
    componentDidMount()
    {
        this.props.Rdx_Order_SetType(this.props.orderType)
        this.Init( this.props.bizId )
        window.addEventListener('resize',this.updateWidthAndHeight)
    }

    /**
     */
    Init = async ( biz_id ) =>
    {
        try
        {
            console.log( 'Order_Home: Init: biz_id: ', biz_id )

            await this.props.RdxOrder_Init( biz_id )

            return {}
        }
        catch( err )
        {
            console.warn( 'Order_Home: Init: err: ', err )

            return {err}
        }
    }   // Init

    // /**
    //  */
    // handleChange = date =>
    // {
    //     this.setState({
    //         startDate: date
    //     })
    // }
}   // class Order_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Order_Home )


