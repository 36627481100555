/**
 *  @copyright  Elmelo Ltd
 */

import React from 'react'
import {
    Container,
    Button,
    Form, Alert, Spinner,Image
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import {navigate} from "@reach/router";

import './css/checkout.css'
import { Core , DDB , Utils} from "../../api";
// import NavHdr from "../_common/nav_hdr";


/**
 */
class Checkout_Success extends React.PureComponent
{
    /**
     */
    constructor (props)
    {
        super (props);

        // Firebase Analytics event
        // try{
        //     Analytics.setAnalyticsCollectionEnabled(true);
        //     Analytics.setCurrentScreen('SuccessScreen', 'App');
        //     Analytics.logEvent('SuccessOpen', {
        //         'user' : 'BetaUser'
        //     });
        // } catch (err) {
        //     console..log('Error while Analytics event registery!!! : ',err);
        // }

        // // console.log( 'SuccessScreen: cstr: this.props: ', this.props );

        //
        // const shareLinkContent = {
        //     contentType: 'link',
        //     contentUrl: variables.social_share.link,
        //     contentDescription: variables.social_share.description
        // };

        // //
        // const photoUri = '../assets/img/logo_highstreet.png';
        // const sharePhotoContent = {
        //     contentType: 'photo',
        //     photos: [{ imageUrl: photoUri }],
        // };

        this.state = {
            loading: false,
            orderStatus: 'new',
            mstStatus_H1: null,
            mstStatus_H2: null,
            mstStatus_H3: null,
        };

        this._interval = null;

    }   // cstr

    /**
     */
    componentDidMount()
    {
        // console.log("success screen props", this.props.location);
        // // console.log("success screen props.total", this.props.navigation.state.params.total);

        /**
         * Analytics
         */
        // Analytics.Enable().catch( err => {} );
        // Analytics.Page( 'lastorder' ).catch( err => {} );
        // Analytics.Event( 'page:lastorder' ).catch( err => {} );

        //
        // const aws_db = new DDB( {} );
        //
        //
        // // this.shareSuccess();
        // this._interval = setInterval( ()=>{
        //     // // console.log('TimerMixin: tick tock: ', Date.now());
        //
        //     const cfg = this.props.__cfg
        //     const props_p = this.props.location.state;
        //
        //     const p_get = {
        //             TableName: cfg.db('order', cfg.stage)
        //         ,   Key: {
        //                 beta_user_id: props_p.beta_user_id
        //             ,   order_id    : props_p.order_id
        //             }
        //         // ,   ProjectionExpression: '#a'
        //         // ,   ExpressionAttributeNames: {'#a': 'respond'}
        //         };
        //
        //     // console.log('SuccessScreen: componentDidMount: p_get: ', p_get);
        //
        //     aws_db.Get( p_get )
        //         .then( data => {
        //
        //             const order_obj = data.Item;
        //
        //             // console.log( 'SuccessScreen: OrderUpd: order_obj: ', order_obj );
        //
        //             if( !order_obj ){
        //                 clearInterval( this._interval );
        //                 /// @todo   Report to core... Shouldn't happen.
        //                 return ;
        //             }
        //
        //             const inv_obj = Utils.Parse( order_obj.invoice );
        //
        //             // // console.log( 'SuccessScreen: OrderUpd: inv_obj: ', inv_obj );
        //
        //             if( !inv_obj ){
        //                 clearInterval( this._interval );
        //                 /// @todo   Report to core... Shouldn't happen.
        //                 return ;
        //             }
        //
        //             //
        //             const delivery_option = inv_obj.delivery_option.toLowerCase();
        //             const order_type = 'collection' === delivery_option ? 'collection' : 'delivery';
        //
        //             // // console.log( 'SuccessScreen: OrderUpd: delivery_option: ', delivery_option );
        //             // // console.log( 'SuccessScreen: OrderUpd: order_type: ', order_type );
        //
        //             let msg_h1 = null, msg_h2 = null, msg_h3 = null;
        //
        //             if( 'new' === order_obj.respond )
        //             {
        //                 msg_h1 = 'Your order is placed successfully.';
        //                 msg_h2 = '(For card payments,  payments are only transfered once the order is accepted)';
        //                 msg_h3 = 'Order Status: Waiting to be acknowledged.';
        //             }
        //             else if( 'accepted' === order_obj.respond )
        //             {
        //                 const dt_accepted = order_obj.dt_accepted ? order_obj.dt_accepted : order_obj.respond_dt;
        //                 // const dt_accepted_duration = ord
        //
        //                 if( !dt_accepted || !order_obj.dt_accepted_duration )
        //                     return ;
        //
        //                 //
        //                 msg_h1 = 'Thank you for your order.';
        //                 msg_h2 = ['We expect your order to be ready for', order_type, 'in', order_obj.dt_accepted_duration, 'minutes'].join(' ');
        //                 msg_h3 = 'Order Status: In the kitchen.';
        //             }
        //             else if( 'declined' === order_obj.respond )
        //             {
        //                 msg_h1 = 'We are really sorry to say NO this time.';
        //                 msg_h2 = '(For card payments,  payments are reversed and should be back to you soon)';
        //                 msg_h3 = 'Order Status: Sorry :(';
        //
        //                 clearInterval( this._interval );
        //             }
        //             else if( 'ready' === order_obj.respond )
        //             {
        //                 msg_h1 = ['Your order is ready'].join(' ');
        //                 msg_h2 = 'collection' === order_type
        //                     ? 'Please come and collect your order'
        //                     : 'Your order is on its way to be delivered'
        //                     ;
        //                 msg_h3 = 'collection' === order_type
        //                     ? 'Order Status: Ready for collection'
        //                     : 'Order Status: On its way.'
        //                     ;
        //             }
        //             else if( 'released' === order_obj.respond )
        //             {
        //                 msg_h1 = 'Thank you for your order today.';
        //                 msg_h2 = 'Looking forward to your next order.';
        //                 msg_h3 = 'Order Status: Complete.';
        //
        //                 clearInterval( this._interval );
        //             }
        //
        //             if( this.state.orderStatus === order_obj.respond )
        //                 return ;
        //
        //             this.setState( {
        //                     orderStatus: order_obj.respond
        //                 ,   msgStatus_H1: msg_h1
        //                 ,   msgStatus_H2: msg_h2
        //                 ,   msgStatus_H3: msg_h3
        //                 } );
        //         } )
        //         .catch( err => {
        //             console.warn( 'SuccessScreen: OrderUpd: _ddb.Get: err', err )
        //         } )
        //         ;
        //
        //     // this._ddb.Get( p_get, (err, data) => {
        //     //     if(err){
        //     //         // console.log( err );
        //     //         return ;
        //     //     }
        //
        //     //     // console.log( 'SuccessScreen: OrderUpd: data: ', data );
        //
        //     //     //
        //     // } );
        // }, /*1000*60*2*/20000 );
    }

    /**
     */
    componentWillUnmount()
    {
        clearInterval( this._interval );
    }


    /**
     */
    render ()
    {
        return (
            <div style={{marginTop: 64}}>
                {/*<NavHdr />*/}
                <div>
                    <div style={{
                        display:"flex",
                        flex: 1,
                        flexDirection:"column",
                        justifyContent: 'center',
                        alignItems: 'center',
                        // padding: 24,
                        backgroundColor: 'white'
                    }}>
                        <div>
                            <div className="infoTitle" style={{textAlign:"center"}}>
                                {this.state.msgStatus_H1 ? this.state.msgStatus_H1
                                    : 'Your order is placed successfully.'}
                            </div>

                            <div style={{textAlign:"center"}}>
                                {this.state.msgStatus_H2 ? this.state.msgStatus_H2
                                    : '(For card payments,  payments are only transfered once the order is accepted.)'}
                            </div>

                            <div style={{textAlign:"center"}}>
                                {this.state.msgStatus_H3 ? this.state.msgStatus_H3
                                    : 'Order Status: Waiting to be acknowledged.'}
                            </div>
                        </div>

                        <div>
                            {
                                'new' === this.state.orderStatus
                                    ?
                                    <Image
                                        className="orderCompleteImage"
                                        src={require('./image/completeOrder.png')}
                                        rounded
                                    />

                                    : 'accepted' === this.state.orderStatus
                                    ?
                                    <Image
                                        className="orderCompleteImage"
                                        src={require('./image/completeOrder.png')}
                                        rounded
                                    />

                                    : 'declined' === this.state.orderStatus
                                        ?
                                        <Image
                                            className="orderCompleteImage"
                                            src={require('./image/completeOrder.png')}
                                            rounded
                                        />

                                        : 'ready' === this.state.orderStatus
                                            ?
                                            <Image
                                                className="orderCompleteImage"
                                                src={require('./image/completeOrder.png')}
                                                rounded
                                            />

                                            : 'released' === this.state.orderStatus
                                                ?
                                                <Image
                                                    className="orderCompleteImage"
                                                    src={require('./image/completeOrder.png')}
                                                    rounded
                                                />

                                                :
                                                <Image
                                                    className="orderCompleteImage"
                                                    src={require('./image/completeOrder.png')}
                                                    rounded
                                                />
                            }
                        </div>
                        <Button
                            onClick={() => navigate('order')}
                            style={{marginVertical:16}}
                            className="confirmButton"
                        >
                            Go Back to Menu
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}   // render


/**
 */
const mapStateToProps = (state) => {
    return state;
};

/**
 */
export default connect ( mapStateToProps, actions )(Checkout_Success);

