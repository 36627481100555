/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import './contact.css'
import {Container, Image, Button, iframe} from 'react-bootstrap'
import {navigate} from "@reach/router"

/**
 */
class Contact_Home extends React.PureComponent {
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render() {
        return (
            <div>
                <Hdr contact={true}/>
                <div className="contactBg">
                    <div style={{flex: 0.8}}>
                        <Image className="bottomImage contactImage" src={require('../assets/ContactPge.png')}/>
                    </div>
                    <div className="contactText">
                        <div className="textSection">
                            <div className="contactHeader">Press enquiries</div>
                            <div className="contactValue"><a href="mailto:press@histreet-app.com">press@i24app.com</a></div>
                        </div>
                        <div className="textSection">
                            <div className="contactHeader">General enquiries</div>
                            <div className="contactValue"><a href="mailto:hello@histreet-app.com">hello@i24.com</a></div>
                        </div>
                        <div className="textSection">
                            <div className="contactHeader">Head Office</div>
                            <div className="contactValue">20 Bankside, Station Approach, Kidlington, Oxfordshire, England, OX5 1JE
                            </div>
                        </div>
                        <div className="textSection">
                            <div className="contactSmall">
                                If you would like to invest in <span className="contactHeader">i24</span> or want to know more about our business, please
                                contact us at <span className="contactHeader">
                                <a href="mailto:invest@i24app.com">invest@i24app.com
                                </a>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Ftr/>
            </div>
        )
    }

    /**
     */
    componentDidMount() {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Contact_Home)


