/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import './team.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {Row, Col, Image, Container, Table} from 'react-bootstrap'
import {navigate} from "@reach/router"

/**
 */
class Term_Home extends React.PureComponent {
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render() {
        return (
            <div>
                <Hdr/>
                <div style={{marginTop: 64}} className="headerText">TERMS AND CONDITIONS OF USE</div>
                <Container>
                    <div className="pText">
                        Introduction
                    </div>
                    <p className="paragraph">
                        These terms and conditions apply between you, the User of this Website (including any
                        sub-domains, unless expressly excluded by their own terms and conditions), andElmelo Ltd,the
                        owner and operator of this Website. Please read these terms and conditions carefully, as they
                        affect your legal rights. Your agreement to comply with and be bound by these terms and
                        conditions is deemed to occur upon your first use of the Website. If you do not agree to be
                        bound by these terms and conditions, you should stop using the Website immediately.In these
                        terms and conditions,UserorUsersmeans any third party that accesses the Website and is not
                        either (i) employed byElmelo Ltdand acting in the course of their employment or (ii) engaged as
                        a consultant or otherwise providing services toElmelo Ltdand accessing the Website in connection
                        with the provision of such services.You must be at least 18 years of age to use this Website. By
                        using the Website and agreeing to these terms and conditions, you represent and warrant that you
                        are at least 18 years of age.
                    </p>

                    <div className="pText">
                        Intellectual property and acceptable use
                    </div>
                    <p className="paragraph">
                        1. All Content included on the Website, unless uploaded by Users, is the property ofElmelo
                        Ltd,our affiliates or other relevant third parties. In these terms and conditions, Content means
                        any text, graphics, images, audio, video, software, data compilations, page layout, underlying
                        code and software and any other form of information capable of being stored in a computer that
                        appears on or forms part of this Website, including any such content uploaded by Users. By
                        continuing to use the Website you acknowledge that such Content is protected by copyright,
                        trademarks, database rights and other intellectual property rights. Nothing on this site shall
                        be construed as granting, by implication, estoppel, orotherwise, any license or right to use any
                        trademark, logo or service mark displayed on the site without the owner's prior written
                        permission
                    </p>
                    <p className="paragraph">
                        2.You may, for your own personal, non-commercial use only, do the following:</p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a. retrieve, display and view the Content on a computer screen;
                        <br/>b. download and store the Content in electronic form on a disk (but not on any server or
                        other storage device connected to a network);
                    </p>
                    <p className="paragraph">
                        3. You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any
                        Content without the written permission of Elmelo Ltd.
                    </p>


                    <div className="pText">
                        Prohibited use
                    </div>
                    <p className="paragraph">
                        4. You may not use the Website for any of the following purposes:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        a.in any way which causes, or may cause, damage to the Website or interferes with any other
                        person's use or enjoyment of the Website;
                        <br/>b.in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or
                        otherwise objectionable or in breach of any applicable law, regulation, governmental order;
                        <br/> c.making, transmitting or storing electronic copies of Content protected by copyright
                        without the permission of the owner.
                    </p>

                    <div className="pText">
                        Links to other websites
                    </div>
                    <p className="paragraph">
                        5. This Website may contain links to other sites. Unless expressly stated,
                        these sites are not under the control of Elmelo Lt dor that of our affiliates.
                    </p>
                    <p className="paragraph">
                        6. We assume no responsibility for the content of such Websites and disclaim liability for any
                        and all forms of loss
                        or damage arising out of the use of them.
                    </p>
                    <p className="paragraph">
                        7. The inclusion of a link to another site on this Website
                        does not imply any endorsement of the sites themselves or of those in control of them.
                    </p>

                    <div className="pText">
                        Privacy Policy
                    </div>
                    <p className="paragraph">
                        8. Use of the Website is also governed by our Privacy Policy, which is incorporated into these
                        terms and conditions by this reference. To view the Privacy Policy, please click on the
                        following: <a href="privacypolicy">www.histreet-app.com/privacypolicy</a>
                    </p>


                    <div className="pText">
                        Availability of the Website and disclaimers
                    </div>

                    <p className="paragraph">
                        9. Any online facilities, tools, services or information
                        that Elmelo Ltd makes available through the Website(theService) is provided "as is" and on an
                        "as
                        available" basis. We give no warranty that the Service will be free of defects and/or faults. To
                        the
                        maximum extent permitted by the law, we provide no warranties (express or implied) of fitness
                        for a
                        particular purpose, accuracy of information, compatibility and satisfactory quality.Elmelo Ltd
                        is
                        under no obligation to update information on the Website.
                    </p>

                    <p className="paragraph">
                        10. Whilst Elmelo Ltd uses reasonable
                        endeavours to ensure that the Website is secure and free of errors,viruses and other malware, we
                        give no warranty or guaranty in that regard and all Users take responsibility for their own
                        security, that of their personal details and their computers.
                    </p>

                    <p className="paragraph">
                        11. Elmelo Ltd accepts no liability for
                        any disruption or non-availability of the Website.
                    </p>


                    <p className="paragraph">
                        12. Elmelo Ltd reserves the right to alter, suspend
                        or discontinue any part (or the whole of) the Website including, but not limited to, any
                        products
                        and/or services available. These terms and conditions shall continue to apply to any modified
                        version of the Website unless it is expressly stated otherwise.
                    </p>


                    <div className="pText">
                        Limitation of liability
                    </div>
                    <p className="paragraph">
                        13. Nothing in these terms and conditions will:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        (a) limit or exclude our or
                        your liability for death or personal injury resulting from our or your negligence, as
                        applicable;
                        <br/> (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                        <br/> (c) limit
                        or exclude any of our or your liabilities in any way that is not permitted under applicable law.
                    </p>
                    <p className="paragraph">
                        14. We will not be liable to you in respect of any losses arising out of events beyond our
                        reasonable
                        control.

                    </p>

                    <p className="paragraph">
                        15. To the maximum extent permitted by law,Elmelo Ltdaccepts no liability for any of the
                        following:
                    </p>
                    <p className="paragraph" style={{marginLeft: 16}}>
                        (a) any business losses, such as loss of profits, income, revenue, anticipated savings,
                        business, contracts,goodwill or commercial opportunities;
                        <br/> (b) loss or corruption of any data, database or software;
                        <br/> (c) any special, indirect or consequential loss or damage
                    </p>


                    <div className="pText">
                        General
                    </div>
                    <p className="paragraph">
                        16. You may not transfer any of your rights under these terms and conditions to any
                        other person. We may transfer our rights under these terms and conditions where we reasonably
                        believe
                        your rights will not be affected.
                    </p>
                    <p className="paragraph">
                        17. These terms and conditions may be varied by us from time to
                        time. Such revised terms will apply to the Website from the date of publication. Users should
                        check
                        the terms and conditions regularly to ensure familiarity with the then current version.
                    </p>
                    <p className="paragraph">
                        18. These
                        terms and conditions together with the Privacy Policy contain the whole agreement between the
                        parties
                        relating to its subject matter and supersede all prior discussions, arrangements or agreements
                        that
                        might have taken place in relation to the terms and conditions.
                    </p>
                    <p className="paragraph">
                        19. TheContracts (Rights of Third
                        Parties) Act 1999shall not apply to these terms and conditions and no third party will have any
                        right to enforce or rely on any provision of these terms and conditions.
                    </p>
                    <p className="paragraph">
                        20. If any court or
                        competent authority finds that any provision of these terms and conditions (or part of any
                        provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the
                        extent required, be deemed to be deleted, and the validity and enforceability of the other
                        provisions of these terms and conditions will not be affected.
                    </p>
                    <p className="paragraph">
                        21. Unless otherwise agreed, no delay,
                        act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or
                        any
                        other, right or remedy.
                    </p>
                    <p className="paragraph">
                        22. This Agreement shall be governed by and interpreted according to the law
                        ofEngland and Wales and all disputes arising under the Agreement (including non-contractual
                        disputes
                        or claims) shall be subject to the exclusive jurisdiction of theEnglish and Welshcourts.
                    </p>


                    <div className="pText">
                        Elmelo Ltd Details
                    </div>

                    <p className="paragraph">
                        23. Elmelo Ltd is a company incorporated inEngland and Waleswith registered
                        number10257702 whose registered address is20 Bankside, Station Approach,Kidlington
                        ,Oxfordshire,OX5 1JEand itoperates the Website www.histreet-app.com.You can contact Elmelo Ltd
                        by
                        email on hello@histreet-app.com.
                    </p>

                    <div className="pText">
                        Attribution
                    </div>

                    <p className="paragraph">
                        24. This privacy policy was created using a document fromRocket
                        Lawyer(https://www.rocketlawyer.com/gb/en).03 May 2021
                    </p>
                </Container>
                <Ftr/>
            </div>
        )
    }

    /**
     */
    componentDidMount() {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Term_Home)


