/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'
import Hdr from '../_common/hdr'
import Ftr from '../_common/ftr'
import './team.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {Row, Col, Image} from 'react-bootstrap'
import { navigate } from "@reach/router"
/**
 */
class Team_Home extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div>
                <Hdr team={true}/>
                <div className="teamBackground">
                <div style={{margin: 30}}>
                    <div className="headerText" style={{marginBottom:40, fontWeight: 400}}> A Versatile Team</div>
                    <Row>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Asif1.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Asif Amin</div>
                                    <div className="teamValue">Co Founder, CEO/CTO</div>
                                    <div>
                                        <div className="experienceText">
                                            • Software Engineer with 10+ years of experience in
                                            Computer Vision and Cloud computing (AWS)
                                        </div>
                                        <div className="experienceText">
                                            • One of the early adapter of "Serverless" concept
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Aniel.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Neil Patti-Dhanjal</div>
                                    <div className="teamValue">Co Founder, COO/CFO</div>
                                    <div>
                                        <div className="experienceText">
                                            • PE - Cerberus Capital Management ($50bn AUM)</div>
                                        <div className="experienceText">
                                            • First Class Honours Economics - Warwick University
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Ryan.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Ryan Mansur</div>
                                    <div className="teamValue">Co Founder, Sr.Software Engineer</div>
                                    <div>
                                        <div className="experienceText">
                                            • Microsoft Imagine Cup 2015, National runner up
                                        </div>
                                        <div className="experienceText">
                                            • 6+ years of web dev experience
                                        </div>
                                        <div className="experienceText">
                                            • 3+ years of mobile app dev experience
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Jeevan.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Jeevan Dhanjal</div>
                                    <div className="teamValue">Head of Growth</div>
                                    <div>
                                        <div className="experienceText">
                                            • BSc Honours Economics - University
                                            College London
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Gregory.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Gregory Munday</div>
                                    <div className="teamValue">Product Lead</div>
                                    <div>
                                        <div className="experienceText">
                                            • MSc Physics, Honours in Physics &
                                            Astrophysics - University of Exeter
                                        </div>
                                        <div className="experienceText">
                                            • University of Oxford Thermofluids Institute in
                                            conjunction with Mitsubishi Heavy
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Koushik.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Koushik Saha</div>
                                    <div className="teamValue">Frontend Lead</div>
                                    <div>
                                        <div className="experienceText">
                                            • 6+ years of experience in web development
                                        </div>
                                        <div className="experienceText">
                                            • 3+ years of experience in mobile
                                            application development
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Sarwar.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Sarwar Hoshen</div>
                                    <div className="teamValue">Developer</div>
                                    <div>
                                        <div className="experienceText">
                                            • 5+ years of experience in web development
                                        </div>
                                        <div className="experienceText">
                                            • 3+ years of experience in mobile
                                            application development
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Asif2.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Asif Iqubal</div>
                                    <div className="teamValue">Developer</div>
                                    <div>
                                        <div className="experienceText">
                                            • 4+ years experience in mobile app development
                                        </div>
                                        <div className="experienceText">
                                            • 2+ years experience in web app development
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Arif.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Arif Hossain</div>
                                    <div className="teamValue">UX Designer</div>
                                    <div>
                                        <div className="experienceText">
                                            • 5+ years experience on envato marketplace
                                        </div>
                                        <div className="experienceText">
                                            • 2 years of experience as ux designer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Ashiqur.png')}/>
                                <div className="teamTexts">

                                    <div className="teamName">Ashiqur Rahman</div>
                                    <div className="teamValue">Web Designer & Developer</div>
                                    <div>
                                        <div className="experienceText">
                                            • 2+ years experience in web development
                                        </div>
                                        <div className="experienceText">
                                            • 2+ years of frontend development
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom:32}}  xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Ben.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Ben Jenkins</div>
                                    <div className="teamValue">Growth</div>
                                    <div>
                                        <div className="experienceText">
                                            • BSc Politics and International Relations - Oxford Brookes University
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom: 32}} xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Sebastian.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Sebastian de Vooght</div>
                                    <div className="teamValue">Growth</div>
                                    <div>
                                        <div className="experienceText">
                                            • BSc Hons German - University of Kent
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col style={{marginBottom: 32}} xs={12} sm={12} md={6} lg={6} xl={4}>
                            <div className="teamDiv">
                                <Image className="teamImage" src={require('../assets/team/Charlie.png')}/>
                                <div className="teamTexts">
                                    <div className="teamName">Charlie Scott-Kerr</div>
                                    <div className="teamValue">Growth</div>
                                    <div>
                                        <div className="experienceText">
                                            • Logistics - Weezy
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                    </div>
                </div>
                <Ftr/>
            </div>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Eye_Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Team_Home )


