/**
 *	@copyright	Elmelo Ltd
 */

import React from 'react';

import {
    Form,
    Button
} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck,faEye} from "@fortawesome/free-solid-svg-icons";
// import CountryCodeList from 'react-native-country-code-list'


/**
 */
class TextInput extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );

        this.state = {
            cnCode: '+44'
            ,	number: null
            ,	show_cn_code : false
        }
    }




    /**
     */
    render()
    {
        return (

            <Form.Control
                as={this.props.as}
                size={this.props.size}
                type={this.props.type}
                placeholder={this.props.placeholder}
                value={this.props.val}
                onChange={(val)=>this.props.onChange(val)}
                className={this.props.className}
                disabled={this.props.disabled ? this.props.disabled  : false }
            />
        )
    }
}	// class PhoneNumber

class Password_SignUp extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props );

        this.state = {
            bHide_PW: true
            ,	bMatch_PW: false
            ,	password: ''
            ,	password_repeat: ''
            ,	bAccept_8ch: false
            ,	bAccept_1Cap: false
            ,	bAccept_1Low: false
            ,	bAccept_1N: false
        };
    }

    /**
     */
    render()
    {
        return(
            <div>
                <Form.Group style={{flexDirection: "row", display: "flex"}}>
                    <div style={{flex: 1}}>
                        <div style={{display:"flex",flexDirection: 'row'}}>
                            <TextInput
                                placeholder={'Password'}
                                type= {!this.state.bHide_PW ? "text" : "password"}
                                val={this.state.password}
                                onChange={(val) => {
                                    val = val.target.value.trim()
                                    this.setState(
                                        { password: val
                                            , bAccept_8ch: val.length >= 8
                                            , bAccept_1Cap: /[A-Z]/.test(val)
                                            , bAccept_1Low: /[a-z]/.test(val)
                                            , bAccept_1N: /\d/.test(val)
                                        })
                                }}
                            />
                            <div style={{display:"flex",width: 50,justifyContent: "center",alignItems: "center",backgroundColor: "rgb(255, 255, 255)",border: 1,borderColor:"#ced4da"}}>
                                <div onClick={()=>this.setState({bHide_PW: !this.state.bHide_PW})}>
                                    <FontAwesomeIcon icon={faEye} size="lg" color={"#454545"}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </Form.Group>
                <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",flex:1}}>
                    <div style={{display:"flex",flexDirection: 'row',marginRight:8}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <FontAwesomeIcon icon={faCheck} size="sm" color={this.state.bAccept_8ch ? "#159957" : "#ddd"}/>
                            <p style={{fontSize:12,color:this.state.bAccept_8ch ? "green": "#aaa"}}>At least 8 characters</p>
                        </div>
                    </div>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{display:"flex",flexDirection:"row",marginRight:8}}>
                            <FontAwesomeIcon icon={faCheck} size="sm" color={this.state.bAccept_1Cap ? "#159957" : "#ddd"}/>
                            <p style={{fontSize:12,color:this.state.bAccept_1Cap ? "green": "#aaa"}}>At least one uppercase character</p>
                        </div>
                    </div>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{display:"flex",flexDirection:"row",marginRight:8}}>
                            <FontAwesomeIcon icon={faCheck} size="sm" color={this.state.bAccept_1Low ? "#159957" : "#ddd"}/>
                            <p style={{fontSize:12,color:this.state.bAccept_1Low ? "green": "#aaa"}}>At least one lowercase character</p>
                        </div>
                    </div>
                    <div style={{flexDirection: 'row'}}>
                        <div style={{display:"flex",flexDirection:"row",marginRight:8}}>
                            <FontAwesomeIcon icon={faCheck} size="sm" color={this.state.bAccept_1N ? "#159957" : "#ddd"}/>
                            <p style={{fontSize:12,color:this.state.bAccept_1N ? "green": "#aaa"}}>At least one number</p>
                        </div>
                    </div>
                </div>
                <Form.Group>
                    <TextInput placeholder={'Repeat Password'}
                               autoCorrect={false}
                               type={!this.state.bHide_PW ? "text" : "password"}
                               secureTextEntry={this.state.bHide_PW}
                               value={this.state.password_repeat}
                               onChange={(val) => {
                                   val = val.target.value.trim()
                                   this.setState( {password_repeat: val} )

                                   // if( this.state.password === this.state.password_repeat )
                                   // {
                                   // 	this.setState( {password_repeat: val, bMatch_PW: true} )
                                   // }
                                   // else
                                   // {
                                   // 	this.setState( {password_repeat: val, bMatch_PW: false} )
                                   // }
                                   this.OnChange( val )
                               } }

                        // onEndEditing={ this.OnChange }
                    />
                </Form.Group>
            </div>
        );
    }	// render
    /**
     */
    componentWillUnmount()
    {
        this.setState( {
            bHide_PW: true
            // ,	bMatch_PW: false
            ,	password: ''
            ,	password_repeat: ''
            ,	bAccept_8ch: false
            ,	bAccept_1Cap: false
            ,	bAccept_1Low: false
            ,	bAccept_1N: false
        } )
    }

    /**
     */
    OnChange = ( val ) =>
    {
        const pass_repeat = val ? val : this.state.password_repeat

        if( !this.state.bAccept_8ch || !this.state.bAccept_1Cap || !this.state.bAccept_1Low || !this.state.bAccept_1N )
        {
            /// @todo show error

            return ;
        }

        if( this.state.password !== pass_repeat )
        {
            /// @todo show error
            this.props.OnChange( "" )

            return ;
        }
        // console.log(this.state.password , val)

        this.props.OnChange( pass_repeat )
    }
}	// class Password_SignUp

/**
 */
export {TextInput,Password_SignUp}

